import { Flex, Input, Select, Switch } from "antd";
import { IoSearch } from "react-icons/io5";
import { RiMenu3Fill } from "react-icons/ri";
import { usePermissions } from "../../../hooks/permissions";
import { useGetAccounts } from "../../../hooks/apis/accounts.api";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { setAdminView, setSelectedAccount } from "../../../store/slices/admin.slice";



const boxStyle: React.CSSProperties = {
  width: "100%",
  borderRadius: 6,
  padding: "10px",
};
const inputSearchStyle: React.CSSProperties = {
  borderRadius: "25px",
  border: "none",
};
const settingIconStyle: React.CSSProperties = {
  height: "33px",
  width: "33px",
  cursor: "pointer",
};

const searchIconStyle: React.CSSProperties = {
  height: "20px",
  width: "20px",
  color:"#6b7280",
  cursor: "pointer",
};

const selectStyle: React.CSSProperties = {
  width: "100%",
  marginBottom: "10px",
}

const UserDidHeader = ({
  setSearchInput,
  searchInput,
  setSidebarOpen,
  sidebarOpen
}: {
  setSearchInput: Function;
  searchInput: string;
  setSidebarOpen?: Function;
  sidebarOpen?: boolean
}) => {
  const { hasPermission } = usePermissions();
  const dispatch: AppDispatch = useDispatch();
  const { accounts, selectedAccount, isAdminView } = useSelector((state: RootState) => state.admin);

  const { isLoading } = useGetAccounts();
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleAccountChange = (value: string) => {
    dispatch(setSelectedAccount(value))
  }
  return (
    <div>
      {
      hasPermission("sms", ["readUserDids:any", "readUserDids:account"]) &&
      <Switch   checkedChildren="Admin" unCheckedChildren="User" checked={isAdminView} style={{float:"right"}} onChange={() => dispatch(setAdminView(!isAdminView))} />
    }
    <Flex style={boxStyle} justify={"space-between"} align={"flex-start"}>
      <Input
        onChange={handleSearch}
        style={inputSearchStyle}
        size="middle"
        placeholder="Search"
        prefix={<IoSearch style={searchIconStyle} />}
      />
         <RiMenu3Fill onClick={() => setSidebarOpen && setSidebarOpen(!sidebarOpen)} style={settingIconStyle} />
    </Flex>
    {
          hasPermission("accounts", "read:any") && isAdminView && <Select
            showSearch
            loading={isLoading}
            style={selectStyle}
            placeholder="Select a Account"
            optionFilterProp="children"
            disabled={accounts.length == 0}
            value={isLoading ? "" : selectedAccount}
            onChange={handleAccountChange}
            filterOption={filterOption}
            options={accounts.map((account) => ({
              label: account.name,
              value: account.id,
            }))}
          />
        }
    </div>
  );
};

export default UserDidHeader;
