import { api } from "@data-phone/react-resource-server-auth"
import { useMutation, useQuery } from "react-query"
import { useDispatch } from "react-redux"
import { useMessageToast } from "../../components/Layout/DefaultLayout"
import { AppDispatch } from "../../store/store"
import { addRingtelOrganization, deleteRingtelOrganization, setRingotelOrganizations, updateRingtelOrganization } from "../../store/slices/ringotelOrg.slice"

export interface RingotelOrg {
    orgid: string
    account_id: string
}


export const getRingotelOrganizations = async () => {
    const response = await api.get(`${process.env.REACT_APP_SMS_API_URL}/ringotel/org`)
    return response
}

export const addRingtelOrganizationApi = async (data: RingotelOrg) => {
    const response = await api.post(`${process.env.REACT_APP_SMS_API_URL}/ringotel/org`, data)
    return response
}

export const updateRingotelOrganizationApi = async ({ data, orgid }: {
    data: RingotelOrg
    orgid: string
}) => {
    await api.patch(`${process.env.REACT_APP_SMS_API_URL}/ringotel/org/${orgid}`, data)
    return {
        orgid: orgid,
        data: data
    }
}

export const deleteRingtelOrganizationApi = async (orgid: string) => {
    await api.delete(`${process.env.REACT_APP_SMS_API_URL}/ringotel/org/${orgid}`)
    return orgid
}

export const useGetRingotelOrganizations = () => {
    const dispatch: AppDispatch = useDispatch()
    return useQuery("organizations", getRingotelOrganizations, {
        enabled: false,
        onSuccess(data) {
            dispatch(setRingotelOrganizations(data.data))
        },
    })
}


export const useAddRingtelOrganization = () => {
    const dispatch: AppDispatch = useDispatch()
    const { messageApi } = useMessageToast()
    return useMutation(addRingtelOrganizationApi, {
        onSuccess: (data) => {
            messageApi.success("Organization added successfully")
            dispatch(addRingtelOrganization(data.data))
        },
        onError: (error: any) => {
            if(error.response.data.errors) {
                messageApi.error(error.response.data.errors)
                return
            }
            messageApi.error("Something went wrong")
        }
    })
}

export const useUpdateRingtelOrganization = () => {
    const dispatch: AppDispatch = useDispatch()
    const { messageApi } = useMessageToast()
    return useMutation(updateRingotelOrganizationApi, {
        onSuccess: (data) => {
            messageApi.success("Organization updated successfully")
            dispatch(updateRingtelOrganization(data))
        }
    })
}

export const useDeleteRingtelOrganization = () => {
    const dispatch: AppDispatch = useDispatch()
    const { messageApi } = useMessageToast()
    return useMutation(deleteRingtelOrganizationApi, {
        onSuccess: (data) => {
            messageApi.success("Organization deleted successfully")
            dispatch(deleteRingtelOrganization(data))
        }
    })
}

