import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import { setAccounts } from "../../store/slices/admin.slice";
import { api } from "@data-phone/react-resource-server-auth";


export interface Account {
    id: string;
    accountcode: string;
    created_at: string;
    description: string;
    domain: string;
    enabled: boolean;
    parent_id: string;
    updated_at: string;
    name: string;
}
const fetchAccounts = async () => {
    const response: AxiosResponse = await api.get(
        `${process.env.REACT_APP_AUTH_API}/api/accounts`,
    );
    return response.data;
};

const fetchAccountById = async (id: string) => {
    const response: AxiosResponse = await api.get(
        `${process.env.REACT_APP_AUTH_API}/api/accounts/${id}`
    );
    return response.data.data as Account[];
};

export const useGetAccounts = () => {
    const dispatch: AppDispatch = useDispatch();
    return useQuery("accounts", () => fetchAccounts(), {
        enabled: false,
        onSuccess: (data) => {
            dispatch(setAccounts(data));
        }
    });
}

export const useGetAccountById = (id: string) => {
    const dispatch: AppDispatch = useDispatch()
    return useQuery(["account", id], () => fetchAccountById(id), {
        enabled: false,
        onSuccess(data) {
            dispatch(setAccounts(data))
        },
    });
}