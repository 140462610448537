import { Content } from "antd/es/layout/layout";
import UserDidHeader from "./Header.did";
import UserDidsList from "./did.list";
import { useState } from "react";

const contentStyle: React.CSSProperties = {
  height: "100%",
  borderRight: "1px solid rgb(223, 226, 232)",
  background: "#ffff",
  padding: "20px",
};

const UserDids = ({
  setIsNewConversation,
  sidebarOpen,
  setSidebarOpen,
  showCounter,
  bulk,
  isLoading
}: {
  setIsNewConversation?: Function;
  sidebarOpen?: boolean;
  setSidebarOpen?: Function
  showCounter: boolean
  bulk?:boolean
  isLoading?:boolean
}) => {
  const [searchInput, setSearchInput] = useState<string>("");
  return (
    <Content style={contentStyle}>
      <UserDidHeader
        setSidebarOpen={setSidebarOpen}
        sidebarOpen={sidebarOpen}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
      <UserDidsList  isLoading={isLoading} bulk={bulk} showCounter={showCounter} setIsNewConversation={setIsNewConversation} searchInput={searchInput} />
    </Content>
  );
};

export default UserDids;
