const DateSeparator = ({ date }: { date: string }) => {
  return (
    <div className="date-separator-container">
      <div className="date-separator-line"></div>
      <div className="date-separator-date-container">
        <span>{date}</span>
      </div>
    </div>
  );
};

const NewMessageSeparator = () => {
  return (
    <div className="new-message-separator-container">
      <div className="new-message-separator-line">
        <div className="new-message"></div>
        <div className="new-message-text">New</div>
      </div>
    </div>
  );
};

export { DateSeparator, NewMessageSeparator };
