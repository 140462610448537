import { CloseOutlined } from "@ant-design/icons";
import { IoTrashBinOutline } from "react-icons/io5";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Spin,
  Switch,
  TimePicker,
  Modal
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { AutoReplyRule } from "../../store/slices/auto_reply_rule";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import useMessageToast from "../../hooks/error/errorMessage";
import {
  useAddAutoReplyRule,
  useDeleteAutoReplyRule,
  useUpdateAutoReplyRule,
} from "../../hooks/apis/auto_reply_rules.api";
import { isValidRule } from "../../utils/ruleValidation";
import { ExclamationCircleFilled } from '@ant-design/icons';
const { confirm } = Modal;

const { RangePicker } = DatePicker;

const formItemStyle: React.CSSProperties = {
  margin: "0px",
};

const AutoReplyRuleForm = ({
  rule,
  index,
  removeRule,
}: {
  rule: AutoReplyRule;
  removeRule: (index: number) => void;
  index: number;
}) => {
  const [ruleState, setRuleState] = useState<AutoReplyRule>(rule);
  useEffect(() => {
    setRuleState(rule);
  },[rule])
  const { data: templates } = useSelector(
    (state: RootState) => state.templates
  );
  const [showError, setShowError] = useState(false);
  const { contextHolder, success, error } = useMessageToast();
  const {
    mutate: addRuleMutate,
    isLoading: addRuleLoading,
    isSuccess: addRuleSuccess,
  } = useAddAutoReplyRule();

  const {
    mutate: updateRuleMutate,
    isLoading: updateLoading,
    isSuccess: updateSuccess,
  } = useUpdateAutoReplyRule();
  const {
    mutate: deleteAutoReplyRule,
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
  } = useDeleteAutoReplyRule();

  


  useEffect(() => {
    if (addRuleSuccess) {
      success("Rule added successfully");
    }
  }, [addRuleSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      success("Rule updated successfully");
    }
  }, [updateSuccess]);
  useEffect(() => {
    if (isSuccessDelete) {
      success("Rule deleted successfully");
    }
  }, [isSuccessDelete]);
  useEffect(() => {
    try {
      if (isValidRule(ruleState)) {
        setShowError(false);
      }
    } catch (error) {
    }
  }, [ruleState]);
  const showDeleteConfirm = (id:string) => {
    confirm({
      title: 'Are you sure you want to delete this Rule?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteAutoReplyRule(id)
      },
      onCancel() {

      },
    });
  };

  const onChangeCondition = (value: string, index: number) => {
    setRuleState({
      ...ruleState,
      rule: {
        ...ruleState.rule,
        conditions: ruleState.rule.conditions.map((c, condIndex) => {
          if (condIndex === index) {
            return { values: [], name: value };
          }
          return c;
        }),
      },
    });
  };

  const handleTimeOfTheDay = (
    value: any,
    valIndex: number,
    conditionName: string,
    index: number
  ) => {
    const time_of_dayRule: AutoReplyRule = {
      ...ruleState,
      rule: {
        ...ruleState.rule,
        conditions: ruleState.rule.conditions.map((item,conditionIndex) => {
          if (item.name === conditionName && conditionIndex === index) {
            let values = item.values.map((val, indexValue) => {
              if (indexValue === valIndex) {
                return `${value[0]?.format("HH:mm")}-${value[1]?.format(
                  "HH:mm"
                )}`;
              } else {
                return val;
              }
            });
            return {
              ...item,
              values,
            };
          }
          return item;
        }),
      },
    };
    setRuleState(time_of_dayRule);
  };

  const handleWeek = (
    value: string,
    fieldIndex: number,
    valIndex: number,
    conditionName: string,
    index: number
  ) => {
    const handleWeekRule = {
      ...ruleState,
      rule: {
        ...ruleState.rule,
        conditions: ruleState.rule.conditions.map((item,conditionIndex) => {
          if (item.name === conditionName && conditionIndex === index) {
            let values = item.values.map((val, indexValue) => {
              if (indexValue === valIndex) {
                if (fieldIndex == 0) {
                  if (value == "") {
                    return "";
                  }
                  if (val == "") {
                    return value;
                  } else if (val.includes("-")) {
                    if (value == "") {
                      return "";
                    } else {
                      return `${value}-${val[2]}`;
                    }
                  }
                } else {
                  if (value == "") {
                    return val[0];
                  } else {
                    return `${val[0]}-${value}`;
                  }
                }

                return "";
              } else {
                return val;
              }
            });
            return {
              ...item,
              values,
            };
          }
          return item;
        }),
      },
    };
    setRuleState(handleWeekRule);
  };

  const handleDateTime = (
    value: any,
    valIndex: number,
    conditionName: string,
    index: number
  ) => {
    const dateTimeRule = {
      ...ruleState,
      rule: {
        ...ruleState.rule,
        conditions: ruleState.rule.conditions.map((item,conditionIndex) => {
          if (item.name === conditionName && conditionIndex === index) {
            let values = item.values.map((val, indexValue) => {
              if (indexValue === valIndex) {
                return `${dayjs(value[0]).format("YYYY-MM-DD HH:mm")}~${dayjs(value[1]).format("YYYY-MM-DD HH:mm")}`;
              } else {
                return val;
              }
            });
            return {
              ...item,
              values,
            };
          }
          return item;
        }),
      },
    };
    setRuleState(dateTimeRule);
  };

  const removeValue = (valIndex: number, conditionName: string,index:number) => {
    const removeRuleValue = {
      ...ruleState,
      rule: {
        ...ruleState.rule,
        conditions: ruleState.rule.conditions.map((item,conditionIndex) => {
          if (item.name === conditionName && conditionIndex === index) {
            let values = item.values.filter(
              (val, indexValue) => indexValue !== valIndex
            );
            return {
              ...item,
              values,
            };
          }
          return item;
        }),
      },
    };
    setRuleState(removeRuleValue);
  };

  const addValue = (conditionName: string, index: number) => {
    setRuleState({
      ...ruleState,
      rule: {
        ...ruleState.rule,
        conditions: ruleState.rule.conditions.map((item, indexValue) => {
          if (item.name === conditionName && indexValue === index) {
            return {
              ...item,
              values: [...item.values, ""],
            };
          }
          return item;
        }),
      },
    });
  };

  const addCondition = () => {
    setRuleState({
      ...ruleState,
      rule: {
        ...ruleState.rule,
        conditions: [
          ...ruleState.rule.conditions,
          {
            name: "",
            values: [],
          },
        ],
      },
    });
  };

  const addRule = (rule: AutoReplyRule) => {
    addRuleMutate({
      template: rule.template,
      enabled: rule.enabled,
      did: rule.did,
      position: rule.position,
      rule: rule.rule,
    });
  };

  const onSubmit = () => {
    try {
      const isValid = isValidRule(ruleState);
      if (!isValid) {
        return;
      }
      if (ruleState.id !== "") {
        updateRuleMutate(ruleState);
      } else {
        addRule(ruleState);
      }
    } catch (err: any) {
      setShowError(true);
      error(err.message as string);
    }
  };

  const removeCondition = (conditionIndex: number) => {
    setRuleState({
      ...ruleState,
      rule: {
        ...ruleState.rule,
        conditions: ruleState.rule.conditions.filter(
          (condition, index) => index !== conditionIndex
        ),
      },
    });
  };

  return (
    <Form name="basic" autoComplete="off">
      {contextHolder}
      <Card
        size="small"
        title={``}
        // key={field.key}
        extra={
          isLoadingDelete ? (
            <div>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : (
            <CloseOutlined
              onClick={() => {
                if (rule.id == "") {
                  removeRule(index);
                } else {
                  showDeleteConfirm(rule.id);
                }
              }}
            />
          )
        }
      >
        <Flex gap={"10px"}>
          <Form.Item
            
            label="Template"
            help={showError && ruleState.template == "" ? "Template is required" : ""}
            validateStatus={
              ruleState.template == "" && showError ? "error" : ""
            }
          >
            <Select
              options={[
                ...templates.map((template) => {
                  return {
                    value: template.id,
                    label: template.name,
                  };
                }),
                {
                  disabled: true,
                  value: "",
                  label: "Select template",
                },
              ]}
              value={ruleState.template}
              onChange={(value) => {
                setRuleState({ ...ruleState, template: value || "" });
              }}
              placeholder="select your template"
            ></Select>
          </Form.Item>
          <Form.Item style={{ marginLeft: "5px", marginRight: "5px" }}>
            <Switch
              checkedChildren="enabled"
              value={ruleState.enabled}
              onChange={(value) => {
                setRuleState({ ...ruleState, enabled: value });
              }}
              unCheckedChildren="disabled"
            />
          </Form.Item>
          <Form.Item label="Weight">
            <InputNumber onChange={(value) => setRuleState({ ...ruleState, position: value || 0 })} value={ruleState.position} />
          </Form.Item>
          <Form.Item>
            <Button
              loading={addRuleLoading || updateLoading}
              onClick={onSubmit}
              disabled={JSON.stringify(ruleState) == JSON.stringify(rule)}
            >
              Save
            </Button>
          </Form.Item>
        </Flex>
        <Form.Item
          validateStatus={
            ruleState.rule.label == "" && showError ? "error" : ""
          }
          help={showError && ruleState.rule.label == "" ? "Name is required" : ""}
        >
          <Input
            style={{ marginBottom: "5px" }}
            placeholder="Name"
            value={ruleState.rule.label}
            onChange={(e) =>
              setRuleState({
                ...ruleState,
                rule: {
                  ...ruleState.rule,
                  label: e.target.value,
                },
              })
            }
          />
        </Form.Item>
        {ruleState.rule.conditions.map((condition, index) => (
          <Flex
            justify="flex-start"
            align="flex-start"
            gap={"10px"}
            style={{ marginBottom: "5px" }}
          >
            <Form.Item
              validateStatus={condition.name == "" && showError ? "error" : ""}
              help={showError && condition.name == "" ? "Condition is required" : ""}
            >
              <Select
                placeholder="select your condition"
                style={{ marginRight: "5px" }}
                onChange={(e) => onChangeCondition(e, index)}
                value={condition.name}
                options={[
                  {
                    value: "",
                    label: "Select Condition",
                  },
                  {
                    disabled: condition.name === "date_time",
                    value: "date_time",
                    label: "Date and time",
                  },
                  {
                    disabled: condition.name === "wday",
                    value: "wday",
                    label: "Day of the week",
                  },
                  {
                    disabled: condition.name === "time_of_day",
                    value: "time_of_day",
                    label: "Time of day	",
                  },
                ]}
              ></Select>
            </Form.Item>
            <Flex vertical gap={"small"}>
              {condition.name == "time_of_day" &&
                condition.values.map((v, valIndex) => {
                  return (
                    <Flex gap={"small"}>
                      <Space key={valIndex}>
                        <Form.Item
                        help={showError && v == "" ? "Value is required" : ""}
                          style={formItemStyle}
                          validateStatus={v == "" && showError ? "error" : ""}
                        >
                          <TimePicker.RangePicker
                            format={"HH:mm"}
                            defaultValue={
                              v !== ""
                                ? [
                                    dayjs(v.split("-")[0], "HH:mm"),
                                    dayjs(v.split("-")[1], "HH:mm"),
                                  ]
                                : undefined
                            }
                            value={
                              v !== ""
                                ? [
                                    dayjs(v.split("-")[0], "HH:mm"),
                                    dayjs(v.split("-")[1], "HH:mm"),
                                  ]
                                : undefined
                            }
                            onOk={(value) => {
                              if (value[0] && value[1]) {
                                handleTimeOfTheDay(
                                  value,
                                  valIndex,
                                  condition.name,
                                  index
                                );
                              }
                            }}
                          />
                        </Form.Item>
                      </Space>
                      <Button
                        type="dashed"
                        danger
                        icon={
                          <IoTrashBinOutline style={{ marginTop: "5px" }} />
                        }
                        onClick={() => {
                          removeValue(valIndex, condition.name,index);
                        }}
                      />
                    </Flex>
                  );
                })}
            </Flex>
            <Flex vertical gap={"small"}>
              {condition.name == "wday" &&
                condition.values.map((v, valIndex) => {
                  return (
                    <Flex gap={"small"}>
                      <Space key={valIndex}>
                        {" "}
                        <Form.Item
                          help={showError && v == "" ? "Value is required" : ""}
                          style={formItemStyle}
                          validateStatus={v == "" && showError ? "error" : ""}
                        >
                          <Select
                            placeholder="Select Day"
                            onChange={(value) => {
                              if (value) {
                                handleWeek(value, 0, valIndex, condition.name,index);
                              }
                            }}
                            value={v.length > 1 ? v[0] : v}
                            style={{ flex: 1 }}
                            options={[
                              { value: "", label: "Select Day" },
                              { value: "1", label: "Sunday" },
                              { value: "2", label: "Monday" },
                              { value: "3", label: "Tuesday" },
                              { value: "4", label: "Wednesday" },
                              { value: "5", label: "Thursday" },
                              { value: "6", label: "Friday" },
                              { value: "7", label: "Saturday" },
                            ]}
                          />
                        </Form.Item>
                        <Form.Item style={formItemStyle}>
                          <Select
                            placeholder="Select Day"
                            disabled={v == ""}
                            value={v.includes("-") ? v[2] : ""}
                            onChange={(value) => {
                              if (value) {
                                handleWeek(value, 1, valIndex, condition.name,index);
                              }
                            }}
                            style={{ flex: 1 }}
                            options={[
                              { value: "", label: "Select Day" },
                              { value: "1", label: "Sunday" },
                              { value: "2", label: "Monday" },
                              { value: "3", label: "Tuesday" },
                              { value: "4", label: "Wednesday" },
                              { value: "5", label: "Thursday" },
                              { value: "6", label: "Friday" },
                              { value: "7", label: "Saturday" },
                            ]}
                          />
                        </Form.Item>
                      </Space>
                      <Button
                        type="dashed"
                        danger
                        icon={
                          <IoTrashBinOutline style={{ marginTop: "5px" }} />
                        }
                        onClick={() => {
                          removeValue(valIndex, condition.name,index);
                        }}
                      />
                    </Flex>
                  );
                })}
            </Flex>
            <Flex vertical gap={"small"}>
              {condition.name == "date_time" &&
                condition.values.map((v, valIndex) => {
                  return (
                    <Flex>
                      <Form.Item
                        style={formItemStyle}
                        help={showError && v == "" ? "Value is required" : ""}
                        validateStatus={
                          v == "" && showError ? "error" : "success"
                        }
                        noStyle
                        name={`conditions[${valIndex}]`}
                      >
                        <RangePicker
                          name={`conditions[${valIndex}]`}
                          multiple
                          showTime
                          defaultValue={
                            v !== ""
                              ? [dayjs(v.split("~")[0], "YYYY-MM-DD HH:mm"), dayjs(v.split("~")[1], "YYYY-MM-DD HH:mm")]
                              : undefined
                          }
                          value={
                            v !== ""
                              ? [dayjs(v.split("~")[0], "YYYY-MM-DD HH:mm"), dayjs(v.split("~")[1], "YYYY-MM-DD HH:mm")]
                              :
                               undefined
                          }
                          format={"YYYY-MM-DD HH:mm"}
                          onOk={(value) => {
                            if (value[0] && value[1]) {
                              handleDateTime(value, valIndex, condition.name,index);
                            }
                          }}

                        />
                      </Form.Item>
                      <Button
                        type="dashed"
                        danger
                        icon={
                          <IoTrashBinOutline style={{ marginTop: "5px" }} />
                        }
                        onClick={() => {
                          removeValue(valIndex, condition.name,index);
                        }}
                      />
                    </Flex>
                  );
                })}
            </Flex>
            <Button
              type="dashed"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                addValue(condition.name, index);
              }}
            >
              Add
            </Button>
            <CloseOutlined
              style={{ padding: "10px" }}
              onClick={() => {
                removeCondition(index);
              }}
            />
          </Flex>
        ))}
        <Button
          type="dashed"
          onClick={() => {
            addCondition();
          }}
          block
        >
          + Add Condition
        </Button>
      </Card>
    </Form>
  );
};

export default AutoReplyRuleForm;
