import { Dropdown, Flex, MenuProps } from "antd";
import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import wavesurferMock from "../../../assets/images/wavesurfermock.png";
import wavesurferinbound from "../../../assets/images/wavesurferinbound.png";
import { amrTomp3 } from "../../../utils/audioConversion";
import { MdOutlineFileDownload } from "react-icons/md";
import { HiDotsVertical } from "react-icons/hi";
import { downloadFile } from "../../../utils/audiodownload";

const dotsStyle: React.CSSProperties = {
  color: "gray",
  cursor: "pointer",
  margin: "auto",
};

const imageStyle: React.CSSProperties = {
  width: "200px",
  height: "30px",
};

const audioContainerStyle: React.CSSProperties = {
    margin: "auto 6px 2px 3px"
}

export default function Waveform({
  url,
  ffmpegLoaded,
  ffmpegRef,
  direction,
  content_type
}: {
  url: string;
  ffmpegLoaded: boolean;
  ffmpegRef: any;
  direction: string;
  content_type: string
}) {
  const waveformRef = useRef(null);
  const wavesurfer: any = useRef(null);
  const [playing, setPlay] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setPlay(false);
    const formWaveSurferOptions = (ref: any) => ({
      container: ref,
      waveColor: "blue",
      progressColor: "gray",
      cursorColor: "none",
      cursorWidth: 0,
      barWidth: 3,
      barRadius: 3,
      duration: 20,
      responsive: true,
      height: 30,
      autoPlay: true,
      normalize: true,
      partialRender: true,
    });
    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);
    wavesurfer.current.on("ready", function () {
      if (wavesurfer.current) {
        wavesurfer.current.playPause();
        setPlay(true);
      }
    });
    wavesurfer.current.on("finish", () => {
      if (wavesurfer.current) {
        wavesurfer.current.seekTo(0);
        setPlay(false);
      }
    });
    return () => wavesurfer.current.destroy();
  }, [url]);
  const loadAudio = async () => {
    if (!loaded) {
      if (content_type === "audio/amr") {
        let audioUrl = await amrTomp3(ffmpegRef, url);
        if (audioUrl) {
          wavesurfer.current.load(audioUrl);
        }
      } else {
        wavesurfer.current.load(url);
      }
      setLoaded(true);
    }
  };

  const handlePlayPause = () => {
    if (loaded) {
      setPlay(!playing);
      wavesurfer.current.playPause();
    } else {
      loadAudio();
    }
  };

  const audioMenu: MenuProps["items"] = [
    {
      key: "1",
      label: "Download",
      icon: <MdOutlineFileDownload />,
      onClick: () => {
        downloadFile(url);
      },
    },
  ];

  return (
    <Flex>
      <div
        className="controls"
        style={audioContainerStyle}
      >
        {playing ? (
          <FaPause onClick={handlePlayPause} />
        ) : (
          <FaPlay onClick={handlePlayPause} />
        )}
      </div>
      {!loaded && (
        <img
          src={direction == "inbound" ? wavesurferinbound : wavesurferMock}
          style={imageStyle}
        />
      )}
      <div
        style={{ width: loaded ? "200px" : "0px" }}
        id="waveform"
        ref={waveformRef}
      />
      <Dropdown menu={{ items: audioMenu }} trigger={["click"]}>
        <HiDotsVertical style={dotsStyle} />
      </Dropdown>
    </Flex>
  );
}
