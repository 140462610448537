import { Button, Flex, Modal, Space, Spin, Table, Tag, Typography } from 'antd';
import type { TableProps } from 'antd';
import { AccountUserDid, useDeleteUserDid } from '../../../hooks/apis/userDids';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import ModalWrapper from '../../generic/modal';
import { useEffect, useState } from 'react';
import AddUserDids from '../../Form/AddUserDids';
import { formatPhoneNumber } from '../../../utils/phoneLib';
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { LoadingOutlined } from "@ant-design/icons";
import { useMessageToast } from '../../Layout/DefaultLayout';
import { usePermissions } from '../../../hooks/permissions';


const { confirm } = Modal;





const UserDidsList = ({
    selectedAccount,
    selectedDid
}: {
    selectedAccount: string
    selectedDid: string
}) => {
    const { data } = useSelector((state: RootState) => state.user)
    const { messageApi } = useMessageToast();
    const { mutate: deleteUserDidMutate, isLoading: deleteUserDidLoading, isSuccess: deleteUserDidSuccess } = useDeleteUserDid()
    const [deleteUserDid, setDeleteUserDid] = useState<string | null>(null);
    const { userDids, users } = useSelector((state: RootState) => state.admin)
    const [addUserDid, setAddUserDid] = useState(false);
    const [editData, setEditData] = useState<AccountUserDid | null>(null);
    const { hasPermission } = usePermissions()

    useEffect(() => {
        if (deleteUserDidSuccess) {
            setDeleteUserDid(null)
            messageApi.success("User DID deleted successfully")
        }
    }, [deleteUserDidSuccess])

    const UserDidColumn: TableProps<AccountUserDid>['columns'] = [
        {
            title: 'Names',
            dataIndex: 'user_id',
            key: 'user_id',
            render: (text) => {
                const user = users.find((user) => user.id == text)
                return <a onClick={() => {
                    if (hasPermission("sms", "readUserDids:any")) {
                        navigator.clipboard.writeText(text)
                        messageApi.success("User Id copied")
                    }

                }}>{user?.first_name} {user?.last_name}</a>
            }
        },
        {
            title: 'SIP',
            dataIndex: 'sip_uri',
            key: 'sip_uri',
        },
        {
            title: 'Email',
            dataIndex: 'sms_to_email',
            key: 'sms_to_email',
        },
        {
            title: 'Email enabled',
            key: 'email_enabled',
            dataIndex: 'email_enabled',
            render: (_, { email_enabled }) => (
                <>
                    <Tag color={email_enabled ? "geekblue" : "red"} >
                        {email_enabled ? "Enabled" : "Disabled"}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: AccountUserDid) => (
                <Space size="middle">
                    {
                        hasPermission("sms", ["updateUserDids:account", "updateUserDids:any"]) &&
                        <CiEdit style={{ cursor: "pointer" }} onClick={() => {
                            setEditData(record)
                            setAddUserDid(true)
                        }}
                        />
                    }
                    {
                        deleteUserDidLoading && deleteUserDid == `${record.user_id}${record.did}` ?
                            <Spin
                                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                            /> :
                            hasPermission("sms", ["deleteUserDids:account", "deleteUserDids:any"]) &&
                            <MdDeleteOutline onClick={() => showDeleteConfirm(record)} style={{ cursor: "pointer" }} />}
                </Space>
            ),
        },
    ];

    const showDeleteConfirm = (record: AccountUserDid) => {
        confirm({
            title: 'Are you sure you want to delete this user did?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                setDeleteUserDid(`${record.user_id}${record.did}`)
                deleteUserDidMutate({ did: selectedDid, user_id: record.user_id })
            },
            onCancel() {

            },
        });
    };

    return <>
        {
            addUserDid &&
            <ModalWrapper
                isModalOpen={addUserDid}
                setIsModalOpen={setAddUserDid}
                title="Add User"
            >
                <AddUserDids selectedAccount={selectedAccount} editData={editData} selectedDid={selectedDid} setIsModal={setAddUserDid} />
            </ModalWrapper>

        }
        <Flex justify="space-between" align='center' style={{ padding: "10px" }}>
            <Typography.Title>{`Users`}</Typography.Title>{" "}
            {
               
                hasPermission("sms", ["createUserDids:account", "createUserDids:any"]) &&
                <Button type="primary" onClick={() => {
                    setAddUserDid(true)
                    setEditData(null)
                }}>Add User</Button>
            }

        </Flex>
        <Table columns={UserDidColumn} dataSource={userDids.filter((userDid) => userDid.account_id == selectedAccount && userDid.did == selectedDid)} />
    </>
};

export default UserDidsList;