import { Avatar, Flex, Input } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import {
  formatPhoneNumber,
  phoneNumberFormatE164,
  phoneNumberValidate,
} from "../../../utils/phoneLib";
import { generateNameSpaceColor } from "../../../utils/color";
import { HeadingText } from "../../generic/Typography";
import { message } from "antd";
import { ContactList } from "../../Contacts/contacts.list";


const boxStyle: React.CSSProperties = {
  borderBottom: "1px solid rgb(223, 226, 232)",
  height: "80px",
  padding: "20px",
  overflow: "auto",
  justifyContent: "space-between",
  width: "-webkit-fill-available"
};

const inputSearchStyle: React.CSSProperties = {
  border: "none",
  padding: "10px",
  borderRadius: "25px",
  width: "200px",
  flex: "0 0 auto"
};

const contactCardStyle: React.CSSProperties = {
  background: "white",
  padding: "5px",
  borderRadius: "25px",
  width: "190px",
};



const crossIconStyle: React.CSSProperties = {
  color: "#6b7280",
  cursor: "pointer",
};
const avatarStyle: React.CSSProperties = {
  color: "black",
};

const containerStyle: React.CSSProperties = {
  flex: "none",
};

const ChatHeaderParticipant = ({
  participants,
  setParticipants,
  isEnabled
}: {
  participants: string[];
  setParticipants: Function
  isEnabled: boolean
}) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [messageApi, contextHolder] = message.useMessage();

  const onError = (message: string) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };

  const onFinish = (value: string) => {
    if (phoneNumberValidate(value)) {
      const parsedNumber = phoneNumberFormatE164(value);
      if (participants.includes(parsedNumber as string))
        return onError("Number already added");
      setSearchInput("");
      setParticipants([...participants, parsedNumber as string]);
      return;
    } else {
      setSearchInput(value);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9+\s()-]+$/;
    if (!re.test(e.target.value) && e.target.value !== "") return;
    setSearchInput(e.target.value);
    onFinish(e.target.value);
  };

  const handleDelete = (participant: string) => {
    const newParticipants = participants.filter((p) => p !== participant);
    setParticipants(newParticipants);
  };

  return (
    <>
    <Flex style={boxStyle}>
      {contextHolder}
      <Flex >
        {participants.map((participant: string, index: number) => {
          return (
            <Content key={index} style={containerStyle}>
              <Flex
                align="center"
                justify="space-around"
                style={contactCardStyle}
              >
                <Avatar
                  style={{
                    ...avatarStyle,
                    background: generateNameSpaceColor(participant),
                  }}
                >
                  {participant[participant.length - 1]}
                </Avatar>
                <HeadingText text={formatPhoneNumber(participant) as string} />
                <RxCross2
                  onClick={() => handleDelete(participant)}
                  style={crossIconStyle}
                />
              </Flex>
            </Content>
          );
        })}
        <Input
          style={inputSearchStyle}
          disabled={!isEnabled}
          onChange={(e) => {
            handleChange(e);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (!phoneNumberValidate(searchInput)) {
                onError("Please enter a valid phone number");
              } else {
                onFinish(searchInput);
              }
            } else if (e.key === "Backspace") {
              if (searchInput.length === 0) {
                handleDelete(participants[participants.length - 1]);
              }
            }
          }}
          value={searchInput}
          placeholder="Add Number"
          variant="borderless"
        />
              {searchInput.length > 0 && <ContactList  handleSubmit={onFinish} searchInput={searchInput} onError={onError} />}

      </Flex>

    </Flex>
    </>
  );
};

export default ChatHeaderParticipant;
