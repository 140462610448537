import { BulkMessage, BulkMessageData, useGetBulkMessageDetails } from "../../hooks/apis/bulkmessages.api";
import Table, { ColumnsType } from "antd/es/table";
import { formatPhoneNumber } from "../../utils/phoneLib";
import { Avatar, Flex, Tag, Tooltip, Typography } from "antd";
import phoneImg from "../../assets/images/icon/Phone-call.svg";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";


const avatarStyle: React.CSSProperties = {
    height: "1.2rem",
    width: "1.2rem",
    marginTop: "2px",
};



const BulkMessageStatusTable = ({
    search,
}: {
    search: string
}) => {
    const { id } = useParams();

    const { data, isLoading } = useGetBulkMessageDetails(id as string);

    const columns: ColumnsType<BulkMessageData> = [
        {
            title: "Participant",
            dataIndex: "participant",
            key: "participant",
            align: "center",
            render: (_, { participant }) => <Flex justify="center" gap={10}><Avatar style={avatarStyle} src={phoneImg} /><Typography.Text style={{ fontWeight: "500" }}>{formatPhoneNumber(participant)}</Typography.Text></Flex>,
        },
        {
            title: "Status",
            dataIndex: "status",
            align: "center",
            key: "status",
            render: (_, { status }) => <Tag color={status === "failed" ? "red" : "green"} >{status}</Tag>,
        },
        {
            title: "Message",
            dataIndex: "body",
            align: "center",
            key: "body",
            render: (_, { body }) => <Tooltip title={body}> <Typography.Text style={{ fontWeight: "500", cursor: "pointer" }}>{body}</Typography.Text></Tooltip>,
        },
        {
            title: "Date and Time",
            dataIndex: "updated_at",
            align: "center",
            key: "updated_at",
            render: (_, { updated_at }) => <Typography.Text style={{ fontWeight: "500" }}>{dayjs(updated_at).format("MM/DD/YYYY hh:mm A")}</Typography.Text>,
        },

    ]


    return <div>
        <Table loading={isLoading} pagination={
            {
                total: data?.length,
                showTotal: (total) => `Total ${total} items`,
                defaultPageSize: 20
            }
        }
            columns={columns}
            dataSource={data?.filter((item) => {
                if (!search) return true

                if(item.body.toLowerCase().includes(search.toLowerCase()) || item.participant.toLowerCase().includes(search.toLowerCase()) || item.status.toLowerCase().includes(search.toLowerCase())) return true
                
            }).sort((a, b) => {
                const statusOrder = ["sent", "failed", "pending"];
                return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
            })} />
    </div>
}

export default BulkMessageStatusTable