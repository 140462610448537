import LogoIcon from "../assets/images/logo/Logomark Coloured.png";
import { incomingMessage } from "../interfaces/messages.type";

export const sendNotification = (message: incomingMessage) => {
  if (Notification.permission === "granted") {
    new Notification(message.participant, {
      body: message.body,
      icon: LogoIcon,
    });
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        new Notification(message.participant, {
          body: message.body,
          icon: LogoIcon,
        });
      }
    });
  }
};
