import { Flex, Input, Typography } from "antd"
import { IoSearch } from "react-icons/io5";
import BulkMessageStatusTable from "../../components/Tables/BulkMesagesStatus";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useState } from "react";


const tableContainerStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    overflow: "auto",
    padding: "10px",
    backgroundColor: "white",
    borderRadius: "10px",
}

const inputSearchStyle: React.CSSProperties = {
    borderRadius: "25px",
    width: "200px",
    border: "none",
};

const searchIconStyle: React.CSSProperties = {
    height: "20px",
    width: "20px",
    color: "#6b7280",
    cursor: "pointer",
};

const BulkMessageStatusList = () => {

    const [searchInput, setSearchInput] = useState<string>("")
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
    }

    return <div style={{ padding: "20px" }}>
        <IoMdArrowRoundBack onClick={() => window.history.back()} style={{ color: "#1890ff", fontSize: "20px", cursor: "pointer" }} />
        <Typography.Title>Bulk Message Status</Typography.Title>
        <div style={tableContainerStyle}>
            <Flex gap={10} style={{ marginBottom: "10px" }}  >
                <Input
                    onChange={handleSearch}
                    style={inputSearchStyle}
                    size="middle"
                    placeholder="Search"
                    prefix={<IoSearch style={searchIconStyle} />}
                />
            </Flex>
            <BulkMessageStatusTable search={searchInput} />
        </div>

    </div>
}

export default BulkMessageStatusList