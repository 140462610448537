import { Button, Descriptions, Flex, Modal, Typography } from 'antd';
import type { DescriptionsProps } from 'antd';
import { Did, useDeleteDid } from '../../../hooks/apis/dids.api';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useMessageToast } from '../../Layout/DefaultLayout';
import ModalWrapper from '../../generic/modal';
import DidForm from '../../Form/Dids';
import { usePermissions } from '../../../hooks/permissions';
const { confirm } = Modal;

const DidInformation = ({
  did,
  setSelectedDid,
  selectedAccount
}: {
  did: Did
  setSelectedDid: Function
  selectedAccount: string
}) => {
  const { data } = useSelector((state: RootState) => state.user)
  const [editData, setEditData] = useState<Did | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { accounts, carriers } = useSelector((state: RootState) => state.admin)
  const { mutate: deleteDidMutate, isSuccess: deleteDidSuccess } = useDeleteDid()
  const { messageApi } = useMessageToast();
  const { hasPermission } = usePermissions()
  useEffect(() => {
    if (deleteDidSuccess) {
      messageApi.success("Did Deleted")
      setSelectedDid(null)
    }
  }, [deleteDidSuccess])



  const showDeleteConfirm = (did: Did) => {
    confirm({
      title: 'Are you sure you want to delete this DID?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteDidMutate(did.did)
      },
      onCancel() {

      },
    });
  };


  let items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Label',
      span: 2,
      children: did.label ? did.label : '',
    },
    {
      key: '2',
      label: 'Number',
      span: 2,
      children: did.did,
    },
    {
      key: '3',
      label: 'Account',
      span: 2,
      children: accounts.find(account => account.id === did.account_id)?.name,
    },
    {
      key: '4',
      label: 'Bulk SMS',
      span: 2,
      children: did.allow_bulk ? 'Enabled' : 'Disabled',
    }
  ];

  if (hasPermission("sms", "readGateways:any")) {
    items = [...items, ...[
      {

        key: '5',
        label: 'Carrier',
        children: carriers.find(carrier => carrier.gateways.some(gateway => gateway.id === did.gateway_id))?.name,
        span: 2
      },
      {
        key: '6',
        label: 'Gateway',
        children: carriers.find(carrier => carrier.gateways.some(gateway => gateway.id === did.gateway_id))?.gateways.find(gateway => gateway.id === did.gateway_id)?.name,
        span: 4
      },
      {
        key: '7',
        label: 'Description',
        span: 4,
        children: (
          <>
            {did.description}
          </>
        ),
      },
    ]]
  }
  else {
    items = [...items, ...[
      {
        key: '8',
        label: 'Description',
        span: 4,
        children: (
          <>
            {did.description}
          </>
        ),
      },
    ]]
  }

  return <div
    style={{ padding: "10px" }}
  >
    <ModalWrapper
      title="Edit DID"
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <DidForm selectedAccount={selectedAccount} setSelectedDid={setSelectedDid} editData={editData} setIsModal={setIsModalOpen} />
    </ModalWrapper>
    <Flex justify="space-between">
      <Typography.Title>Information</Typography.Title>
      <Flex gap={10}>
        {
          hasPermission("sms", "updateDids:any") &&
          <Button type="primary"
            style={{ background: "rgb(101, 59, 250)" }} onClick={() => {
              setEditData(did); setIsModalOpen(true)
            }}>Edit</Button>
        }
        {
          hasPermission("sms", "deleteDids:any") &&
          <Button type="primary" danger onClick={() => {
            showDeleteConfirm(did)
          }}>Delete</Button>
        }

      </Flex>
    </Flex>
    <Descriptions layout="vertical" bordered items={items} />
  </div>

}


export default DidInformation;