import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import DidsHeader from "./DidsHeader";
import { Did, useGetDidsByAccountId } from "../../../hooks/apis/dids.api";
import DidsList from "./DidsList";
import { Flex } from "antd";
import Loader from "../../generic/Loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useGetUserDidsByAccount } from "../../../hooks/apis/userDids";
import { useGetCarriers } from "../../../hooks/apis/carriers.api";
import { usePermissions } from "../../../hooks/permissions";
import { setSelectedAccount } from "../../../store/slices/admin.slice";

const contentStyle: React.CSSProperties = {
    height: "100%",
    borderRight: "1px solid rgb(223, 226, 232)",
    background: "#ffff",
    padding: "20px",
};

const spinStyle: React.CSSProperties = {
    width: "100%",
    height: "calc(100% - 80px)",
};

const DidsContainer = ({
    selectedDid,
    setSelectedDid,
}: {
    selectedDid: Did | null
    setSelectedDid: Function
}) => {
    const [searchInput, setSearchInput] = useState<string>("");
    const { dids,selectedAccount } = useSelector((state: RootState) => state.admin)
    const { hasPermission } = usePermissions()

    const dispatch:AppDispatch = useDispatch()

    const onAccountChange = (value: string) => {
        dispatch(setSelectedAccount(value))
    }
    const { refetch: fetchCarriers } = useGetCarriers()
    const { refetch: fetchDids, isLoading } = useGetDidsByAccountId(selectedAccount)
    const { refetch: fetchUserDids } = useGetUserDidsByAccount(selectedAccount)
    useEffect(() => {
        if (selectedAccount) {
            fetchDids()
            fetchUserDids()
        }
    }, [selectedAccount])
    useEffect(() => {
        if (hasPermission("sms", "readGateways:any")) {
            fetchCarriers()
        }
    }, [])

    return (
        <Content style={contentStyle}>
            <DidsHeader
                setSelectedDid={setSelectedDid}
                setSearchInput={setSearchInput}
                onAccountChange={onAccountChange}
                selectedAccount={selectedAccount}
            />
            {dids.filter((did) => did.account_id == selectedAccount).length == 0 && isLoading ?
                <Flex style={spinStyle} justify="center" align="center">
                    <Loader />
                </Flex>
                :
                <DidsList selectedAccount={selectedAccount} setSelectedDid={setSelectedDid} selectedDid={selectedDid} dids={dids.filter((did) => did.account_id == selectedAccount && did.did.includes(searchInput))} setCurrentDid={setSelectedDid
                } />
            }
        </Content>
    );
};

export default DidsContainer;
