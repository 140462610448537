import papa from "papaparse";

export function parseCsv<T extends Record<string, any>>(csv: string, header: true): T[];
export function parseCsv(csv: string, header?: false): string[][];
export function parseCsv<T>(csv: string, header: boolean = false): T[] | string[][] | undefined {
    if (!csv || !csv.length) return;

    const csvData = atob(csv);
    const parsedCsv = papa.parse<T>(csvData, { header ,skipEmptyLines: true,transformHeader: (header) => header.trim().toLowerCase()});

    if (parsedCsv?.data && Array.isArray(parsedCsv.data) && parsedCsv.data.length) {
        return parsedCsv.data;
    }
}