import { useDispatch, useSelector } from "react-redux";
import { selectedConversaton } from "../../../store/slices/conversation.slice";
import { AppDispatch, RootState } from "../../../store/store";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BaseMessage } from "../../../interfaces/messages.type";
import useGetMessages from "../../../hooks/apis/messages.api";
import ChatCard from "./chat.card";
import { DateSeparator, NewMessageSeparator } from "../../generic/separators";
import { formatChatDate } from "../../../utils/dateFormat";
import { updateUserDidCounterByConversationUpdate } from "../../../store/slices/userDids.slice";
import UseScrollObserver from "../../../hooks/scroll-observer";

const ChatList = ({
  ffmpegLoaded,
  ffmpegRef,
  conversation,
  deleteMessage,
  updateConversation,
  sendMessage
}: {
  conversation: selectedConversaton;
  ffmpegLoaded: boolean;
  ffmpegRef: any;
  deleteMessage: Function;
  updateConversation: (conversation: {
    did: string;
    participant: string;
    last_read_timestamp?: string;
    assigned_user?: string;
    resolved?: boolean;
    unread?: boolean
  }) => void
  sendMessage: Function
}) => {
  const dispatch: AppDispatch = useDispatch();

  const { data } = useSelector((state: RootState) => state.messages);
  const { isAdminView, selectedAccount } = useSelector((state: RootState) => state.admin);
  const { data: conversations } = useSelector((state: RootState) => state.conversation);
  const { messages: offlineMessages } = useSelector((state: RootState) => state.offlineMessages);
  const [position, setPosition] = useState<number>(conversation.position ? Number(Math.floor(conversation.position / 20) * 20) : 0);
  const messagesRef = useRef<any>(null)
  const containerRef = useRef<HTMLDivElement>(null);
  const messageRefs = useRef<(HTMLDivElement | null)[]>([]); // To store references to message elements
  const [searchInput, setSearchInput] = useState<string | null>(conversation.search ? conversation.search : null);
  const [scrollDirection, setScrollDirection] = useState<"up" | "down" | null>(null);
  const [lastScrollTop, setLastScrollTop] = useState<number>(0); // Store the previous scroll position
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [visibleMessage, setVisibleMessage] = useState<number | null>(null);
  const { mutate, isLoading } = useGetMessages();


  const messages: BaseMessage[] = useMemo(() => {
    if (data.length > 0) {
      const messages = data.find(
        (message) =>
          message.did === conversation.did &&
          message.participant === conversation.participant
      );
      const participantMessages = offlineMessages.filter((message) => message.did === conversation.did && message.participant === conversation.participant)
      if (messages) {
        const messageConversation = conversations.find((conv) => conv.did === conversation.did && conv.participant === conversation.participant)

        if (!isAdminView && messageConversation && (messageConversation.total_unread > 0 || messageConversation.unread)) {

          updateConversation({
            did: conversation.did,
            participant: conversation.participant,
            last_read_timestamp: new Date().toISOString(),
            unread: false
          })

          dispatch(updateUserDidCounterByConversationUpdate({
            did: conversation.did,
            count: messageConversation.total_unread
          }))

        }
        return [...messages.messages, ...participantMessages];
      }
    }
    return [];
  }, [conversation, data, offlineMessages]);

  UseScrollObserver({
    cardRefs: messageRefs,
    cards: messages,
    setVisibleMessage,
  })  

  useEffect(() => {
    if (messagesRef) {
      messagesRef.current = messages
    }
  }, [messages]);

  useEffect(() => {
    if (searchInput) {
      const foundIndex = messages.find((message) =>
        message.body.toLowerCase().includes(searchInput.toLowerCase())
      )?.position || 1;

      if (foundIndex !== -1 && messageRefs.current[foundIndex]) {
        messageRefs.current[foundIndex]?.scrollIntoView({
          behavior: "smooth", 
          block: "start",
        });
        setSearchInput(null);
      }
    }
  }, [messages]);

  useEffect(() => {
    mutate({
      did: conversation.did,
      participant: conversation.participant,
      offset: position,
      limit: 20,
      account: isAdminView ? selectedAccount : null
    })
  }, [])

  useEffect(() => {
    if (messages.length > 0 && scrollDirection === "down") {
      const leastItem = messages.reduce((prev, current) => {
        return current?.position < prev.position ? current : prev;
      });
      if (leastItem && leastItem.position !== 0 && !isLoading && visibleMessage && visibleMessage > leastItem.position - 5) {
        mutate({
          did: conversation.did,
          participant: conversation.participant,
          offset: Number(leastItem.position) - 20,
          limit: 20,
          account: isAdminView ? selectedAccount : null
        }, {
          onSuccess: () => {
            messageRefs.current[leastItem?.position]?.scrollIntoView({ block: 'start' })
          }
        })

      }
    }
    else if (messages.length > 0 && scrollDirection === "up") {

      const mostItem = messages.reduce((prev, current) => {
        return current?.position > prev.position ? current : prev;
      });
  
      if (visibleMessage && visibleMessage > mostItem.position - 5 && !isLoading && hasMore) {
        mutate({
          did: conversation.did,
          participant: conversation.participant,
          offset: Math.ceil(Number(mostItem.position)/20) * 20,
          limit: 20,
          account: isAdminView ? selectedAccount : null
        }, {
          onSuccess: (data) => {
            if(data.messages.length === 0){
              setHasMore(false)
            }
          }
        })
      }
    }
  }, [scrollDirection, visibleMessage])


  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const currentScrollTop = containerRef.current.scrollTop;

      if (currentScrollTop > lastScrollTop) {
        setScrollDirection("down"); 
      } else if (currentScrollTop < lastScrollTop) {
        setScrollDirection("up"); 
      }

      setLastScrollTop(currentScrollTop);
    }
  }, [lastScrollTop]);


  return (
    <div
      ref={containerRef}
      style={{
        height: "calc(100% - 220px)",
        overflow: "auto",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column-reverse",
        padding: "5px",
      }}
      onScroll={handleScroll}

    >
      {messages
        .slice()
        .sort(
          (a, b) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        )
        .map((message, index, arr) => {

          const currentMessageDate = formatChatDate(message.timestamp);
          const previousMessageDate =
            index < arr.length - 1
              ? formatChatDate(arr[index + 1].timestamp)
              : null;
          return (
            <div
              style={{
                border: "none",
                paddingBottom:"10px",
                justifyContent:
                  message.direction === "outbound"
                    ? "flex-start"
                    : "flex-end",
              }}
              key={index}
            >
              {" "}
              {currentMessageDate !== previousMessageDate && (
                <DateSeparator date={formatChatDate(message.timestamp)} />
              )}

              {/* {lastIndex === index && <NewMessageSeparator />} */}
              <div data-index={message.position} // Add a data attribute to identify the index
                id={`message-${index}`}
                ref={(el) => (messageRefs.current[message.position ? message.position : 0] = el)}
              >
                <ChatCard
                  sendMessage={sendMessage}
                  deleteMessage={deleteMessage}
                  key={index}
                  ffmpegRef={ffmpegRef}
                  ffmpegLoaded={ffmpegLoaded}
                  message={message}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ChatList;
