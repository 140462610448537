import React, { useEffect } from "react";
import { registerNotificationToken } from "../apis/notifications.api";
import { getToken, onMessage } from "firebase/messaging";
import { usePushNotificationRegister } from "./config";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useGetContacts } from "../apis/contacts.api";



const usePushNotification = () => {
    const { messaging } = usePushNotificationRegister()

    const { data: contacts } = useSelector((state: RootState) => state.contacts);

    const contactLastDateRef = React.useRef<any>(null);

    const { refetch } = useGetContacts({ updated_at: contactLastDateRef.current })


    useEffect(() => {
        if (contacts.length > 0) {
            const sortedContacts = [...contacts].sort((a: any, b: any) => b?.updated_at - a.updated_at)
            contactLastDateRef.current = sortedContacts[0].updated_at
        }
    }, [contacts])

    useEffect(() => {
        if (!messaging) return
        onMessage(messaging, (payload) => {
            if (contactLastDateRef.current) {
                refetch()
            }
        });
    }, [messaging]);

};

export default usePushNotification;

