import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseMessage } from "../../interfaces/messages.type";

export interface OfflineMessages {
    messages: BaseMessage[];
}

const initialState: OfflineMessages = {
    messages: [],
};

export const offlineMessagesSlice = createSlice({
    name: "offlineMessages",
    initialState,
    reducers: {
        setOfflineMessage: (state, action: PayloadAction<BaseMessage>) => {
            if(!state.messages.some((message) => message.clientId === action.payload.clientId)){
                state.messages = [...state.messages, action.payload];
            }
        },
        deleteOfflineMessage: (state, action: PayloadAction<string>) => {
            state.messages = state.messages.filter((message) => message.clientId !== action.payload);  
        },
        clearPast24HoursOfflineMessages: (state) => {
            const now = Date.now();
            const cutoffTime = now - 24 * 60 * 60 * 1000; // 24 hours in milliseconds

            state.messages = state.messages.filter((message) => {
                const messageTime = new Date(message.timestamp).getTime();
                return messageTime >= cutoffTime;
            });
        },
        clearOfflineMessages: (state) => {
            state.messages = [];
        },
    },
})

export const {
    setOfflineMessage,
    clearOfflineMessages,
    deleteOfflineMessage,
    clearPast24HoursOfflineMessages
} = offlineMessagesSlice.actions

export default offlineMessagesSlice.reducer