import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface TemplateType {
  id: string;
  name: string;
  account: string;
  description: string;
  body: string;
}

export interface TemplateState {
  data: TemplateType[];
}

export const initialState: TemplateState = {
  data: [],
};

const templateSlice = createSlice({
  name: "autoReplyRules",
  initialState,
  reducers: {
    setTemplates: (state, action: PayloadAction<TemplateType[]>) => {
      action.payload.forEach((template) => {
        if (state.data.some((item) => item.id === template.id)) return
        state.data.push(template)
      })
    },
    addTemplateSlice: (state, action: PayloadAction<TemplateType>) => {
      state.data.push(action.payload);
    },

    updateTemplateSlice: (state, action: PayloadAction<TemplateType>) => {
      const index = state.data.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        state.data[index] = action.payload;
      }
    },
  },
});

export const { setTemplates, updateTemplateSlice, addTemplateSlice } = templateSlice.actions;

export default templateSlice.reducer;
