import { Avatar, Flex } from "antd";
import { selectedConversaton } from "../../../store/slices/conversation.slice";
import { generateNameSpaceColor } from "../../../utils/color";
import { HeadingText, TextWrapper } from "../../generic/Typography";
import { formatPhoneNumber } from "../../../utils/phoneLib";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
const boxStyle: React.CSSProperties = {
  borderBottom: "1px solid rgb(223, 226, 232)",
  height: "80px",
  padding: "20px",
};
const avatarStyle: React.CSSProperties = {
  verticalAlign: "middle",
  color: "black",
  fontWeight: "bold",
  marginRight: "10px"
};



const ChatHeaderConversation = ({
  selectedConversation,
}: {
  selectedConversation: selectedConversaton;
}) => {
  const color = generateNameSpaceColor(selectedConversation.participant);

  const { data: contacts } = useSelector((state: RootState) => state.contacts);

  const contactData = contacts.find((contact) => contact.phones.some((phone) => {
    return phone.phone_number === selectedConversation.participant
  })) || null
  return (
    <Flex style={boxStyle}>
      <Avatar style={{ ...avatarStyle, background: color }} size="large">
        {selectedConversation.participant[
          selectedConversation.participant.length - 1
        ]
          ? selectedConversation.participant[
          selectedConversation.participant.length - 1
          ]
          : 0}
      </Avatar>
      <Flex vertical={true}>
        <Flex >
          <HeadingText
            text={
              contactData ? `${contactData.first_name || ""} ${contactData.middle_name || ""} ${contactData.last_name || ""}` :
                formatPhoneNumber(selectedConversation.participant) as string
            }
          />

        </Flex>
        <TextWrapper
          text={formatPhoneNumber(selectedConversation.participant) as string}
        />
      </Flex>
    </Flex>
  );
};

export default ChatHeaderConversation;
