import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RingotelUser } from "../../hooks/apis/ringotelUsers.api";


export interface RingotelUserState {
    data: RingotelUser[];
}


const initialState: RingotelUserState = {
    data: [],
};

export const ringotelUserSlice = createSlice({
    name: "ringotelUser",
    initialState,
    reducers: {
        setRingotelUsers: (state, action: PayloadAction<RingotelUser[]>) => {
            state.data = [...state.data, ...action.payload.filter((user) => !state.data.some((u) => u.user_id === user.user_id))];
        },
        updateRingtelUser: (state, action: PayloadAction<{
            user_id: string
            data: RingotelUser
        }>) => {
            state.data = state.data.map((user) => {
                if (user.user_id === action.payload.user_id) {
                    return action.payload.data;
                }
                return user;
            });
        },
        addRingtelUser: (state, action: PayloadAction<RingotelUser>) => {
            state.data = [...state.data, action.payload];
        },
        deleteRingtelUser: (state, action: PayloadAction<string>) => {
            state.data = state.data.filter((user) => user.user_id !== action.payload);
        },
    },
}); 

export const { setRingotelUsers, updateRingtelUser, addRingtelUser, deleteRingtelUser } = ringotelUserSlice.actions;

export default ringotelUserSlice.reducer;