import { api } from "@data-phone/react-resource-server-auth"
import { AxiosResponse } from "axios";

export interface PushNotificationConfig {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
    vapidKey: string
  }
  

export const registerNotificationToken = async (token:string) => {
    const response = await api.post(
        `${process.env.REACT_APP_CONTACT_API_URL}/firebase/`,{
            token
        }
    )
    return response.data
}

export const getPushNotificationConfig = async () => {
    const response: AxiosResponse = await api.get(
      `${process.env.REACT_APP_CONTACT_API_URL}/firebase/config`,
    );
    return response.data as PushNotificationConfig;
  };
  

