import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BulkMessage } from "../../hooks/apis/bulkmessages.api";

interface BulkMessagesState {
    data: BulkMessage[];
}

const initialState: BulkMessagesState = {
    data: [],
};

export const bulkMessages = createSlice({
    name: "bulkMessages",
    initialState,
    reducers: {
        setBulkMessages: (state, action: PayloadAction<BulkMessage[]>) => {
            action.payload.forEach((message) => {
                if (state.data.some((item) => item.id === message.id)) return
                state.data.push(message)
            })
        },
    },
});

export const { setBulkMessages } = bulkMessages.actions;

export default bulkMessages.reducer;