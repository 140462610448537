import { useDispatch } from "react-redux";
import {
  AutoReplyRule,
  addAutoReplyRuleSlice,
  deleteAutoReplyRuleSlice,
  setAutoReplyRules,
  updateAutoReplyRules,
} from "../../store/slices/auto_reply_rule";
import { AppDispatch } from "../../store/store";
import axios, { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { api } from "@data-phone/react-resource-server-auth";

const fetchAutoReplyRuleByDid = async (
  did: string,
  dispatch: AppDispatch
): Promise<AutoReplyRule[]> => {
  const response: AxiosResponse<AutoReplyRule[]> = await api.get(
    `${process.env.REACT_APP_SMS_API_URL}/autoReplyRule/did/${did}`,
  );
  if (response.data) {
    dispatch(setAutoReplyRules(response.data));
  }
  return response.data;
};
const addAutoReplyRule = async (data: any, dispatch: AppDispatch): Promise<AutoReplyRule> => {
  const response: AxiosResponse<AutoReplyRule> = await api.post(
    `${process.env.REACT_APP_SMS_API_URL}/autoReplyRule`,
    data,
  );
  dispatch(addAutoReplyRuleSlice(response.data));
  return response.data;
};
const updateAutoReplyRuleById = async (data: any, dispatch: AppDispatch): Promise<any> => {
  const response: AxiosResponse<AutoReplyRule> =
    await api.put(
      `${process.env.REACT_APP_SMS_API_URL}/autoReplyRule/${data.id}`,
      data,
    );
  dispatch(updateAutoReplyRules(response.data));
  return response.data;
};
const deleteAutoReplyRule = async (id: string, dispatch: AppDispatch): Promise<any> => {
  const response = await api.delete(
    `${process.env.REACT_APP_SMS_API_URL}/autoReplyRule/${id}`,
  );
  if (response.deleted) {
    dispatch(deleteAutoReplyRuleSlice(id));
  }
  return response;
};


export const useGetAutoReplyByDid = (did: string) => {
  const dispatch: AppDispatch = useDispatch();
  return useQuery<AutoReplyRule[], Error>(
    ["autoReplyRules", did],
    () => {
      if (did) {
        return fetchAutoReplyRuleByDid(did, dispatch)
      }
      return Promise.resolve([]);
    },
    {
      onError: (error) => {
        console.log("failed", error);
      },
    }
  );
};

export const useUpdateAutoReplyRule = () => {
  const dispatch: AppDispatch = useDispatch();
  return useMutation((data: any) => updateAutoReplyRuleById(data, dispatch), {
    onSuccess: () => {
      console.log("success");
    },
    onError: () => {
      console.log("failed");
    },
  });
};

export const useDeleteAutoReplyRule = () => {
  const dispatch: AppDispatch = useDispatch();
  return useMutation((id: string) => deleteAutoReplyRule(id, dispatch), {
    onSuccess: () => {
      console.log("success");
    },
    onError: () => {
      console.log("failed");
    },
  });
};

export const useAddAutoReplyRule = () => {
  const dispatch: AppDispatch = useDispatch();
  return useMutation((data: any) => addAutoReplyRule(data, dispatch), {
    onSuccess: () => {
      console.log("success");
    },
    onError: () => {
      console.log("failed");
    },
  });
};
