import { Button, Form, FormProps, Input, Select, Switch } from "antd"
import { AccountUserDid, useAddUserDid, useUpdateUserDid } from "../../hooks/apis/userDids"
import { useEffect, useState } from "react";
import { useMessageToast } from "../Layout/DefaultLayout";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const AddUserDids = ({
    setIsModal,
    editData,
    selectedAccount,
    selectedDid
}: {
    setIsModal: React.Dispatch<React.SetStateAction<boolean>>
    editData: AccountUserDid | null
    selectedAccount: string
    selectedDid: string
}) => {
    const { messageApi } = useMessageToast();
    const { mutate: addUserDidMutate, isLoading, isSuccess } = useAddUserDid()
    const { mutate: updateUserDidMutate, isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate } = useUpdateUserDid()
    const [emailEnabled, setEmailEnabled] = useState<boolean>(editData ? editData.email_enabled : false)
    const { users ,userDids} = useSelector((state: RootState) => state.admin)
    const [form] = Form.useForm();
    useEffect(() => {
        form.resetFields()
    }, [])
    useEffect(() => {
        if (editData) {
            form.setFieldsValue(editData)
        }
    }, [editData])

    useEffect(() => {
        if (isSuccess) {
            messageApi.success("User DID added successfully")
            setIsModal(false)
        }
    }, [isSuccess])

    useEffect(() => {
        if (isSuccessUpdate) {
            messageApi.success("User DID updated successfully")
            setIsModal(false)
        }
    },[isSuccessUpdate])


    const onFinish: FormProps<AccountUserDid>["onFinish"] = (values) => {
        const userDid: AccountUserDid = {
            ...values,
            email_enabled: emailEnabled,
            account_id: selectedAccount,
            did: selectedDid
        }
        if (editData) {
            updateUserDidMutate({
                id: editData.user_id as string,
                data: userDid
            })
        }
        else{
      
            addUserDidMutate(userDid)
        }
    };


    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    return <Form
        style={{ padding: "10px" }}
        name="basic"
        form={form}
        labelCol={{ span: 4 }}
        onFinish={onFinish}
    >
        <Form.Item<AccountUserDid>
            label="User Email"
            name="user_id"
            rules={[{ required: true, message: "Please input template name!" }]}
        >
            <Select
                showSearch
                placeholder="Select User"
                optionFilterProp="children"
                filterOption={filterOption}
                options={users.filter((usr) => usr.account_id === selectedAccount).filter((usr) => !userDids.find((userDid) => userDid.user_id === usr.id && userDid.did === selectedDid)).map((user) => ({ label: `${user.first_name} ${user.last_name}`, value: user.id }))}
            />

        </Form.Item>
        <Form.Item<AccountUserDid>
            label="Email"
            name="sms_to_email"
            rules={[{ type: "email" }]}
        >
            <Input placeholder="Email" />
        </Form.Item>
        <Form.Item<AccountUserDid>
            label="SIP URI"
            name="sip_uri"
        >
            <Input placeholder="SIP uri" />
        </Form.Item>
        <Form.Item
            label={`Email Enabled:::: `}
            name="email_enabled"
        >
            :<Switch style={{marginLeft:"5px"}} checked={emailEnabled} onChange={(value) => setEmailEnabled(value)} />
        </Form.Item>

        <Form.Item>
            <Button
                style={{ width: "100%" }}
                type="primary"
                disabled={isLoading || isLoadingUpdate}
                loading={isLoading || isLoadingUpdate}
                htmlType="submit"
            >
                Save
            </Button>
        </Form.Item>
    </Form>


}

export default AddUserDids