import { Button, DatePicker, Flex, Form, Input, Select, Tooltip, Typography } from "antd";
import { useMessageToast } from "../Layout/DefaultLayout";
import { ScheduledMessage, ScheduleMessage, useScheduleMessage, useUpdateScheduledMessage } from "../../hooks/apis/scheduled_messages.api";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { formatPhoneNumber, phoneNumberFormatE164, phoneNumberValidate } from "../../utils/phoneLib";
import { didlabelStyle } from "../../pages/BulkMessages";
import { parsePhoneNumber } from "libphonenumber-js";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { addScheduledMessageSlice, updateScheduledMessageSlice } from "../../store/slices/scheduled_messages.slice";

export const ScheduleForm = ({
    setIsModalOpen,
    editData,
    did,
    participant
}: {
    setIsModalOpen: Function
    editData?: ScheduledMessage
    did?: string
    participant?: string
}) => {
    const [form] = Form.useForm();
    const dispatch: AppDispatch = useDispatch()

    const { messageApi } = useMessageToast();
    const { mutate: scheduleMessage, isLoading: isLoadingScheduleMessage } = useScheduleMessage()
    const { mutate: updateScheduledMessage, isLoading: isLoadingUpdate } = useUpdateScheduledMessage()
    const { data: userDids } = useSelector((state: RootState) => state.userDids)
    const [number, setNumber] = useState("")
    const [flagIcon, setFlagIcon] = useState<string>("US")

    useEffect(() => {
        if (editData) {
            form.setFieldsValue({
                did: formatPhoneNumber(editData.did),
                participant: formatPhoneNumber(editData.participant),
                body: editData.body,
                scheduled_at: dayjs(new Date(editData.scheduled_at)),
            })
            setNumber(editData.participant)
        }
    }, [editData])

    useEffect(() => {
        if (did && participant) {
            form.setFieldsValue({
                did: did,
                participant: formatPhoneNumber(participant),
            })
            setNumber(participant)
        }
    }, [])


    const handleSubmit = (values: ScheduledMessage) => {
        const message: ScheduleMessage = {
            from: phoneNumberFormatE164(values.did),
            to: phoneNumberFormatE164(number),
            text: values.body,
            scheduled_at: new Date(values.scheduled_at).toISOString(),
            media: []
        }

        if (new Date(values.scheduled_at) < new Date()) {
            messageApi.error("Scheduled time should be greater than current time")
            form.setFieldValue("scheduled_at", null)
            return
        }

        if (editData && editData.id) {

            updateScheduledMessage({
                data: {
                    did: message.from,
                    participant: message.to,
                    body: message.text,
                    scheduled_at: new Date(message.scheduled_at).toISOString(),
                    media: [],
                }, id: editData.id
            }, {
                onSuccess: (data) => {
                    messageApi.success("Message updated successfully")
                    dispatch(updateScheduledMessageSlice(data))
                    setIsModalOpen(false)
                }
            })
        }
        else {
            scheduleMessage(message, {
                onSuccess: (data) => {
                    messageApi.success("Message scheduled successfully")
                    dispatch(addScheduledMessageSlice(data))
                    setIsModalOpen(false)
                }
            })
        }
    }

    const disabledTime = (current: Dayjs | null) => {
        if (!current) return {};

        const now = dayjs();
        const isToday = current.isSame(now, 'day');

        if (isToday) {
            return {
                disabledHours: () => Array.from({ length: now.hour() }, (_, i) => i),
                disabledMinutes: () =>
                    current.hour() === now.hour() ? Array.from({ length: now.minute() + 1 }, (_, i) => i) : [],
                disabledSeconds: () =>
                    current.hour() === now.hour() && current.minute() === now.minute()
                        ? Array.from({ length: now.second() }, (_, i) => i)
                        : [],
            };
        }

        return {};
    };



    return <Form
        form={form}
        name="basic"
        style={{ padding: "10px" }}
        labelCol={{ span: 4 }}
        onFinish={handleSubmit}
    >
        <Form.Item<ScheduledMessage>
            label="From"
            name="did"
        >
            <Select
                showSearch
                placeholder="Select a number"
                optionFilterProp="children"
                style={{
                    height: "fit-content"
                }}
                options={userDids.map((did) => {
                    return {
                        label: <Flex vertical >
                            <Typography.Text >{formatPhoneNumber(did.did)}</Typography.Text>
                            <Tooltip title={did.label}><Typography.Text style={didlabelStyle}>{did.label}</Typography.Text></Tooltip>
                        </Flex>,
                        value: did.did
                    }
                })}
            >
            </Select>

        </Form.Item>
        <Form.Item<ScheduledMessage>
            label="To"
            name="participant"
            rules={[{
                required: true, validateTrigger: "onFinish", validator: () => {
                    return parsePhoneNumber(number, 'US')?.isValid() ? Promise.resolve() : Promise.reject("Invalid phone number")
                }
            }]}
        >
            <Input placeholder="Enter phone number"
                value={formatPhoneNumber(number.replace(/ /g, ''))}
                onChange={(event) => {
                    try {
                        const phoneNumber = parsePhoneNumber(event.target.value, 'US');
                        if (phoneNumber?.country) {
                            setFlagIcon(phoneNumber.country)
                        }
                        else {
                            setFlagIcon("US")
                        }
                        if (phoneNumber?.isValid()) {
                            setNumber(phoneNumber.number)
                            form.setFieldValue("participant", phoneNumber.formatNational())
                        }
                        else {
                            setNumber(event.target.value)
                        }
                    } catch (error) {
                        setNumber(event.target.value)
                    }

                }} addonBefore={<img style={{ height: "20px", width: "30px" }} src={`https://catamphetamine.gitlab.io/country-flag-icons/3x2/${flagIcon}.svg`}></img>} />
        </Form.Item>
        <Form.Item
            label="Date"
            name="scheduled_at"

            rules={[{ required: true, message: "Please input scheduled date" }]}
        >
            <DatePicker
                className="ant-date-picker-schedule-message"
                showTime
                placeholder="Date and time"
                suffixIcon={null}
                format={"DD/MM/YYYY hh:mm A"}
                style={{ padding: "5px", cursor: "pointer !important", caretColor: "transparent", width: "100%" }}
                disabledDate={(current) => current < dayjs().startOf("day")}
                disabledTime={disabledTime}
            />
        </Form.Item>

        <Form.Item
            label="Message"
            name="body"
            rules={[{ required: true, message: "Please input message" }]}
        >
            <TextArea
                rows={4}
                placeholder="Enter Message"
            />
        </Form.Item>

        <Form.Item
        >
            <Button
                loading={isLoadingScheduleMessage || isLoadingUpdate}
                style={{ width: "100%" }} type="primary" htmlType="submit">
                {editData ? "Update schedule" : "Schedule"}
            </Button>
        </Form.Item>
    </Form>

}