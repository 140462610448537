import { api } from "@data-phone/react-resource-server-auth";
import { Media, outgoingMessage } from "../../interfaces/messages.type";
import { useMutation, useQuery } from "react-query";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import { setScheduledMessages } from "../../store/slices/scheduled_messages.slice";

export interface ScheduledMessage {
    id?: string;
    did: string;
    participant: string;
    body: string;
    first_name?: string;
    last_name?: string;
    scheduled_at: string;
    media: Media[]
}

export interface ScheduledMessageQuery {
    limit: number;
    offset: number;
    did?: string;
    account?: string
}

const getScheduledMessagesApi = async (query: ScheduledMessageQuery) => {
    const response = await api.get(
        `${process.env.REACT_APP_SMS_API_URL}/schedule?limit=${query.limit}&offset=${query.offset}${query.account ? `&account=${query.account}` : ""}${query.did ? `&did=${query.did}` : ""}`
    )

    return response.data
}

const deleteScheduledMessageApi = async (id: string) => {
    const response = await api.delete(
        `${process.env.REACT_APP_SMS_API_URL}/schedule/${id}`
    )
    return response.data
}

const updateScheduledMessageApi = async ({
    id,
    data
}: {
    id: string
    data: ScheduledMessage
}) => {
    const response = await api.put(
        `${process.env.REACT_APP_SMS_API_URL}/schedule/${id}`,
        data
    )
    return response.data
}

export type ScheduleMessage = Omit<outgoingMessage, "clientId"> & {
    scheduled_at: string;
};

const scheduleMessage = async (data: ScheduleMessage) => {
    const response = await api.post(
        `${process.env.REACT_APP_SMS_API_URL}/schedule/schedule`,
        data
    );
    return response.data;

}

export const useScheduleMessage = () => {
    return useMutation((data: ScheduleMessage) => scheduleMessage(data))
}



export const useGetScheduledMessages = (query: ScheduledMessageQuery) => {
    const dispatch:AppDispatch = useDispatch()
    return useQuery("scheduledMessages", () => getScheduledMessagesApi(query),{
        onSuccess: (data) => {
            dispatch(setScheduledMessages(data))
        }
    })
}



export const useDeleteScheduledMessage = () => {
    return useMutation((id: string) => deleteScheduledMessageApi(id))
}


export const useUpdateScheduledMessage = () => {
    return useMutation((message: {
        id: string
        data: ScheduledMessage
    }) => updateScheduledMessageApi({
        id: message.id,
        data: message.data
    }))
}