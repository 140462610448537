import { Alert, Button, Col, Flex, Input, List, Popover, Row, Select, Tooltip, Typography, Upload, UploadFile, UploadProps } from "antd";
import { useEffect, useRef, useState } from "react";
import { useGetUserDids } from "../../hooks/apis/userDids";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useMessageToast } from "../../components/Layout/DefaultLayout";
import { RcFile } from "antd/es/upload";
import { InboxOutlined } from '@ant-design/icons';
import Dragger from "antd/es/upload/Dragger";
import TextArea from "antd/es/input/TextArea";
import { Media } from "../../interfaces/messages.type";
import { formatPhoneNumber, phoneNumberFormatE164, phoneNumberValidate } from "../../utils/phoneLib";
import { useSendBulkMessage } from "../../hooks/apis/bulkmessages.api";
import { getBase64 } from "../../utils/base64";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { parseCsv } from "../../utils/csv";
import { findTemplatePlaceHolders, replacePlaceholders } from "../../utils/templatePlaceHolders";
import { RiMenu2Fill } from "react-icons/ri";
import { useGetTemplatesByAccount } from "../../hooks/apis/templates.api";
import { setSelectedAccount } from "../../store/slices/admin.slice";
import { setSelectedDid } from "../../store/slices/userDids.slice";



const rowStyle: React.CSSProperties = {
    height: "100%",
};

const iconStyle: React.CSSProperties = {
    height: "1rem",
    width: "1rem",
    background: "none",
};

const iconButtonStyle: React.CSSProperties = {
    padding: "10px",
    border: "none",
    cursor: "pointer",
    width: "fit-content",
    background: "none",
};


export const didlabelStyle: React.CSSProperties = {
    color: "rgb(119, 117, 135)",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100px"
}

const BulkMessagePage = () => {
    const { messageApi } = useMessageToast()
    const dispatch: AppDispatch = useDispatch();

    const [csvFile, setCsvFile] = useState<UploadFile[]>([]);
    const [NumbersText, setNumbersText] = useState<string>("");
    const [inputText, setInputText] = useState<string>("");
    const [isSent, setIsSent] = useState<boolean>(false);
    const [invalidNumbers, setInvalidNumbers] = useState<string[]>([]);
    const [isTemplatesOpen, setIsTemplatesOpen] = useState<boolean>(false);
    const [selectedDid, setSelectedDid] = useState<string | null>(null);
    const [label, setLabel] = useState<string>("");


    const sentTimeOutRef = useRef<NodeJS.Timeout | null>(null);

    const { selectedAccount, isAdminView } = useSelector((state: RootState) => state.admin);
    const { data: userDids } = useSelector((state: RootState) => state.userDids);
    const { data: templates } = useSelector((state: RootState) => state.templates);
    const { data: userData } = useSelector((state: RootState) => state.user);


    const navigate = useNavigate();

    const {
        mutate: sendBulkMessage,
        isSuccess: isSuccessBulk,
        isLoading: bulkMessageLoading,
        data: bulkData,
    } = useSendBulkMessage();

    const { refetch: fetchAccountTemplates } = useGetTemplatesByAccount(selectedAccount as string);
    const { refetch } = useGetUserDids(isAdminView ? selectedAccount : null);


    useEffect(() => {
        if ((isAdminView && selectedAccount == "") || !isAdminView) {
            dispatch(setSelectedAccount(userData?.accountID as string))
        }
        refetch();
    }, [isAdminView, selectedAccount]);

    useEffect(() => {
        setIsSent(false);
        setInvalidNumbers([]);
    }, [selectedDid])

    useEffect(() => {
        fetchAccountTemplates();
    }, [selectedAccount])


    useEffect(() => {
        if (isSuccessBulk) {
            setIsSent(true);
            setCsvFile([]);
            setNumbersText("");
            setInputText("");
            setLabel("");
        }
        return () => {
            if (sentTimeOutRef.current) {
                clearTimeout(sentTimeOutRef.current)
            }
        }
    }, [isSuccessBulk]);


    const TemplatesComponent = () => {
        return <List
            bordered
            dataSource={templates}
            style={{
                maxHeight: "180px",
                overflowY: "auto",
            }}
            renderItem={(item) => (
                <List.Item
                    onClick={() => {
                        setInputText(item.body)
                        setIsTemplatesOpen(false)
                    }}
                >
                    <List.Item.Meta
                        title={item.name}
                        style={{ cursor: "pointer" }}
                    />

                </List.Item >
            )}
        />

    }

    const handleChangeDid = (did: string) => {
        setSelectedDid(did);
    }


    const props: UploadProps | React.CSSProperties = {
        name: 'file',
        multiple: false,
        style: {
            display: "block",
            marginTop: "10px"
        },

        maxCount: 1,
        beforeUpload(file) {
            if (file.type != "text/csv") {
                return messageApi.error("Only CSV files are allowed");
            }
            return false;
        },
        accept: "text/csv",



        onChange(info) {
            const { status } = info.file;
            setCsvFile(info.fileList);
            if (status !== 'uploading') {
                if (info.file.type != "text/csv") {
                    return messageApi.error("Only CSV files are allowed");
                }
            }
            if (status === 'done') {
                setCsvFile(info.fileList);
            } else if (status === 'error') {
                console.log("error", info.fileList);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };



    const handleSubmit = async () => {
        try {
            let messagesData: {
                to: string;
                text: string
            }[] = [];

            if (!selectedDid) {
                return messageApi.error("Please select a number from the list")
            }

            if(!label || label == "") {
                return messageApi.error("Please enter a label for the bulk message")
            }

            if (csvFile.length > 0) {
                const base64: string = await getBase64(csvFile[0].originFileObj as RcFile)
                const parsedData: { number: string, [key: string]: string }[] = parseCsv(base64.split(",")[1], true)

                if (!parsedData) {
                    return messageApi.error("Invalid CSV file")
                }

                if (parsedData.length == 0) {
                    return messageApi.error("CSV file is empty")
                }



                const fields = Object.keys(parsedData[0]);
                const placeHolders: string[] = findTemplatePlaceHolders(inputText);

                if (!fields.includes('number')) {
                    return messageApi.error("Missing required field 'number'");
                }

                const missingFields = placeHolders.filter(placeholder => !fields.includes(placeholder));
                if (missingFields.length > 0) {
                    throw new Error(`CSV is missing required fields: ${missingFields.join(', ')}`);
                }


                messagesData = await Promise.all(parsedData.map(async (data) => {
                    if (data.number) {
                        const text = await replacePlaceholders(inputText, data);
                        return { to: data.number, text };
                    }

                    return null;
                })) as { to: string, text: string }[];

            } else if (NumbersText.length > 0) {
                messagesData = NumbersText.split("\n").map((num) => num.trim()).map((num) => {
                    return {
                        to: num,
                        text: inputText
                    }
                })
            } else {
                return messageApi.error("Please enter numbers or upload csv file")
            }

            let invalidNumbers: string[] = []

            messagesData = messagesData.filter((data) => {
                const e164 = phoneNumberFormatE164(data.to)
                if (e164) {
                    return true
                }
                invalidNumbers.push(data.to)
                return false
            }).map((data) => {
                return {
                    ...data,
                    to: phoneNumberFormatE164(data.to)
                }
            })

            if (invalidNumbers.length > 0) {
                return messageApi.error("Invalid numbers found in csv file")
            }

            sendBulkMessage({
                messages: messagesData,
                from: selectedDid as string,
                label
            });
        } catch (error: any) {
            messageApi.error(error.message)
        }

    }
    return <div style={rowStyle}>

        <div style={{ padding: "20px", height: "100%", overflowY: "auto", width: "100%" }} >
            <IoMdArrowRoundBack onClick={() => navigate("/bulk/1")} style={{ color: "#1890ff", fontSize: "20px", cursor: "pointer" }} />
            <Typography.Title level={3} >Bulk SMS</Typography.Title>
            <Select
                placeholder="Select Number"
                style={{
                    height: "fit-content",
                }}
                optionFilterProp="children"
                value={selectedDid}
                onChange={handleChangeDid}
                options={userDids.filter(userDid => userDid.allow_bulk).map((userDid) => {
                    return {
                        label: <Flex vertical >
                            <Typography.Text >{formatPhoneNumber(userDid.did)}</Typography.Text>
                            <Tooltip title={userDid.label}><Typography.Text style={didlabelStyle}>{userDid.label}</Typography.Text></Tooltip>
                        </Flex>,
                        value: userDid.did
                    }
                })}
            />
            <Input placeholder="Label"  value={label} onChange={(e) => setLabel(e.target.value)} style={{ display: "block", marginTop: "10px",width:"9%" }}  ></Input>
            <Typography.Text style={{ display: "block", marginTop: "10px" }}>Upload a CSV file with your contacts</Typography.Text>
            <Typography.Text type="secondary" style={{ display: "block", marginTop: "10px" }}>Make sure the phone numbers are formatted with country codes, e.g., 5551234567 or +15551234567</Typography.Text>
            <div style={{ width: "40%", marginTop: "10px" }}>
                <Dragger fileList={csvFile} disabled={NumbersText.length > 0} previewFile={getBase64 as any} height={160} style={{ width: "40%", marginTop: "10px", display: "block", height: "160px" }}  {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single CSV file upload.
                    </p>
                </Dragger>
            </div>
            <Typography.Text style={{ display: "block", marginBottom: "10px" }}>Or</Typography.Text>
            <Typography.Text type="secondary" style={{ display: "block", marginTop: "10px" }}>Enter phone numbers manually, one per line. e.g., 5551234567</Typography.Text>
            <Flex gap={10}>

                <TextArea
                    disabled={csvFile.length > 0}
                    onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^(\+|\d|\n|\s)*$/;

                        if (value === '' || regex.test(value)) {
                            setNumbersText(value);
                        }
                    }
                    } style={{ width: "40%", background: "#e7edf3", display: "block", height: "120px", marginBottom: "10px" }} value={NumbersText} placeholder="5551234567&#10;5551234568" allowClear />

                <Flex vertical gap={"10px"} >
                    {
                        isSent && bulkData?.sent && <Alert style={{ height: "40px" }} message={`SMS sent to ${bulkData?.sent} ${bulkData?.sent == 1 ? "number" : "numbers"}`} type="success" showIcon />
                    }
                    {
                        isSent && invalidNumbers && invalidNumbers?.length > 0 && <Alert style={{ height: "40px" }} message={`${invalidNumbers.length} invalid numbers`} type="error" showIcon />
                    }
                </Flex>
            </Flex>
            <Typography.Text type="secondary" style={{ display: "block", marginTop: "10px" }}>{`Enter your message add dynamic fields e.g. {{name}}`}</Typography.Text>
            <Flex vertical style={{ background: "#e7edf3", border: "1px solid #e7edf3", width: "40%" }}>
                <TextArea variant="borderless" onChange={(e) => setInputText(e.target.value)} value={inputText} style={{ background: "#e7edf3", height: "120px" }} placeholder="Your message here {{name}} {{email}}" allowClear />
                <Popover
                    overlayStyle={{ width: "20%" }}
                    content={TemplatesComponent}
                    onOpenChange={setIsTemplatesOpen}
                    title={"Templates"}
                    trigger="click"
                    open={isTemplatesOpen}
                    className="templates-popover"
                    style={{ width: "35%" }}
                    placement="topLeft"
                >
                    <Button style={iconButtonStyle}>
                        <RiMenu2Fill style={iconStyle} />
                    </Button>
                </Popover>

            </Flex>
            {selectedDid &&
                <Typography.Text strong type="secondary" style={{ display: "block", marginTop: "10px" }}>Send message from <Typography.Text strong> {formatPhoneNumber(selectedDid as string)}</Typography.Text></Typography.Text>
            }
            <Button loading={bulkMessageLoading} disabled={(NumbersText.length == 0 && csvFile.length == 0) || inputText.length == 0} onClick={handleSubmit} block type="primary" style={{ marginTop: "15px", width: "40%", display: "block" }}>Send SMS</Button>
        </div>
    </div>
}

export default BulkMessagePage
