import { Typography } from "antd";

const { Text } = Typography;
const HeadingTextStyle: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  minWidth:"60px",
  textAlign:"center",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace:"nowrap",
};

const TextWrapperStyle: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: "rgb(119, 117, 135)",
};

const DateTextWrapperStyle: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: "rgb(119, 117, 135)",
};

const ChatTextStyle: React.CSSProperties = {
  fontSize: "12px",
  fontWeight: "500",
  letterSpacing: "0em",
  textAlign: "left",
  maxWidth: "300px",
  wordWrap: "break-word",
};

const deleteTextStyle: React.CSSProperties = {
  color: "#6b7280",
  fontSize: "10px",
};

const dateTextStyle: React.CSSProperties = {
  color: "#6b7280",
  fontSize: "11px",
};

const FileTextStyle: React.CSSProperties = {
  color: "black",
  fontSize: "14px",
  fontWeight: "500",
  maxWidth: "200px",
  wordWrap: "break-word",
  marginRight: "10px",
};

const NameTextChatStyle: React.CSSProperties = {
  color: "#6b7280",
  fontSize: "11px",
};

const HeadingText = ({ text }: { text: string }) => {
  return <Text style={HeadingTextStyle}>{text}</Text>;
};

const TextWrapper = ({ text }: { text: string }) => {
  return <Text style={TextWrapperStyle}>{text}</Text>;
};

const DateTextWrapper = ({ text }: { text: string }) => {
  return <Text style={DateTextWrapperStyle}>{text}</Text>;
};

const FileText = ({ text }: { text: string }) => {
  return <Text style={FileTextStyle}>{text}</Text>;
};

const ChatText = ({ text }: { text: string }) => {
  return <Text style={ChatTextStyle}>{text}</Text>;
};

const DeleteText = () => {
  return <Text style={deleteTextStyle}>deleted</Text>;
};

const DateText = ({
  text,
  style,
}: {
  text: string;
  style?: React.CSSProperties;
}) => {
  return <Text style={{ ...dateTextStyle, ...style }}>{text}</Text>;
};

const NameTextChat = ({ text }: { text: string }) => {
  return <Text style={NameTextChatStyle}>{text}</Text>;
};

export {
  HeadingText,
  TextWrapper,
  DateTextWrapper,
  ChatText,
  DeleteText,
  DateText,
  FileText,
  NameTextChat,
};
