import {
  Button,
  Checkbox,
  Dropdown,
  DropdownProps,
  Flex,
  Input,
  MenuProps,
} from "antd";
import { IoSearch } from "react-icons/io5";
import { LuSettings2 } from "react-icons/lu";
import { FaPlus } from "react-icons/fa";
import { AppDispatch } from "../../../store/store";
import { useDispatch } from "react-redux";
import { setSelectedConversation } from "../../../store/slices/conversation.slice";
import { ChangeEvent, useState } from "react";
import { debounce } from "../../../utils/debounce";
import ModalWrapper from "../../generic/modal";
import { SearchConversationList } from "./SearchConversations.list";

export const ConversationHeader = ({
  searchInput,
  setIsNewConversation,
  setSearchInput,
  setConversationsFilter,
  convsersationsFilter,
}: {
  searchInput: string;
  setSearchInput: Function;
  setIsNewConversation: Function;
  setConversationsFilter: Function;
  convsersationsFilter: {
    unread: boolean;
    assigned: boolean;
    unresolved: boolean;
  };
}) => {
  const searchIconStyle: React.CSSProperties = {
    height: "20px",
    width: "20px",
    color: "#6b7280",
    cursor: "pointer",
  };

  const settingIconStyle: React.CSSProperties = {
    height: "33px",
    width: "33px",
    marginTop: "7px",
    cursor: "pointer",
  };

  const inputSearchStyle: React.CSSProperties = {
    borderRadius: "25px",
    border: "none",
    background: "#f5f6f7",
    padding: "10px",
  };

  const plusButtonStyle: React.CSSProperties = {
    background: "rgb(101, 59, 250)",
  };

  const plusIconStyle: React.CSSProperties = {
    height: "20px",
    width: "20px",
    marginTop: "2px",
    color: "white",
    cursor: "pointer",
  };

  const boxStyle: React.CSSProperties = {
    borderBottom: "1px solid rgb(223, 226, 232)",
    height: "80px",
    padding: "20px",
  };

  const dispatch: AppDispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [searchModal, setSearchModal] = useState(false);

  const handleOpenChange: DropdownProps["onOpenChange"] = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen(nextOpen);
    }
  };

  const handleSearchChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  }, 300);

  
  const items: MenuProps["items"] = [
    {
      label: (
        <Checkbox
          onChange={(e) => {
            setConversationsFilter({
              ...convsersationsFilter,
              unread: e.target.checked,
            });
          }}
          value={convsersationsFilter.unread}
          checked={convsersationsFilter.unread}
        >
          Unread
        </Checkbox>
      ),
      key: "1",
    },
    {
      label: (
        <Checkbox
          onChange={(e) => {
            setConversationsFilter({
              ...convsersationsFilter,
              assigned: e.target.checked,
            });
          }}
          value={convsersationsFilter.assigned}
          checked={convsersationsFilter.assigned}
        >
          My conversations
        </Checkbox>
      ),
      key: "3",
    },
    {
      label: (
        <Checkbox
          onChange={(e) =>
            setConversationsFilter({
              ...convsersationsFilter,
              unresolved: e.target.checked,
            })
          }
          value={convsersationsFilter.unresolved}
          checked={convsersationsFilter.unresolved}
        >
          Unresolved
        </Checkbox>
      ),
      key: "4",
    },
  ];

  const handleNewConversation = () => {
    setIsNewConversation(true);
    dispatch(setSelectedConversation(null));
  };

  return (
    <Flex style={boxStyle} justify={"space-between"} align={"flex-start"}>
      {
        searchModal &&
        <ModalWrapper
          title="Search Messages"
          setIsModalOpen={setSearchModal}
          isModalOpen={searchModal}
        >
          <SearchConversationList setIsmodal={setSearchModal} />
        </ModalWrapper>
      }
      <Flex gap={6}>
        <Button
          type="primary"
          onClick={handleNewConversation}
          style={plusButtonStyle}
          size="large"
          shape="circle"
          icon={<FaPlus style={plusIconStyle} />}
        />
      </Flex>

      <Flex>
        <div onClick={() => setSearchModal(true)}>
          <Input
            className={
              "search-conversation-input"
            }
            style={{
              ...inputSearchStyle,
            }}
            onChange={handleSearchChange}
            size="middle"
            placeholder="Search"
            prefix={<IoSearch style={searchIconStyle} />}
          />
        </div>

        <Dropdown
          menu={{ items }}
          onOpenChange={handleOpenChange}
          open={open}
          trigger={["click"]}
        >
          <LuSettings2 style={settingIconStyle} strokeWidth={1} />
        </Dropdown>
      </Flex>
    </Flex>
  );
};
