import { Header } from "antd/es/layout/layout";
import HeaderLogo from "../../assets/images/logo/sms-header-logo.svg";
import dotMenu from "../../assets/images/icon/dotsmenu.svg";
import LiveCalls from "../../assets/images/icon/Live-calls.svg";
import CallCenter from "../../assets/images/icon/Call-Center.svg";
import SMS from "../../assets/images/icon/SMS.svg";
import Contacts from "../../assets/images/icon/Contacts.svg";
import PBX from "../../assets/images/icon/PBX-Portal.svg";
import Reports from "../../assets/images/icon/Reports.svg";
import { Avatar, Flex, Popover, Typography } from "antd";
import { generateNameSpaceColor } from "../../utils/color";
import { Dropdown, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useEffect, useState } from "react";
import { usePermissions } from "../../hooks/permissions";

const { Text } = Typography;



const LayoutHeader = ({ name, logout }: { name: string, logout: () => void }) => {
  const color = generateNameSpaceColor(name);
  const { data } = useSelector((state: RootState) => state.user)
  const { hasPermission } = usePermissions()
  const navigate = useNavigate()

  const items = [
    {
      label: "Logout",
      key: "0",
      onClick: () => {
        localStorage.clear();
        logout();
      },
    },
    {
      label:"Scheduled Messages",
      key: "7",
      onClick: () => {
        navigate("/scheduled-messages")
      },
    },
    {
      label: "Bulk SMS",
      key: "3",
      onClick: () => {
        navigate("/bulk/1")
      },
    },
  ];
  const [navigationItems, setNavigationItems] = useState<{
    label: string;
    key: string;
    onClick: () => void;
  }[]>(items);


  const avatarStyle: React.CSSProperties = {
    verticalAlign: "middle",
    color: "black",
    fontWeight: "bold",
    cursor: "pointer",
    textTransform: "capitalize"
  };

  const dotMenuStyle: React.CSSProperties = {
    border: "1px solid white",
    borderRadius: "25px",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    margin: "auto",
    padding: "5px",
  };

  const TextStyle: React.CSSProperties = {
    color: "rgb(119, 117, 135)",
  };
  const HeaderStyle: React.CSSProperties = {
    background: "#130d38",
    padding: "15px",
  };
  const HeaderContentStyle: React.CSSProperties = { height: "100%" };
  const HeaderMenu: React.CSSProperties = { gap: "20px" };
  const HeaderNameStyle: React.CSSProperties = {
    color: "white",
    margin: "auto",
    textTransform: "capitalize",
  };

  const headerLogo: React.CSSProperties = {
    cursor: "pointer"
  }

  useEffect(() => {
    if (!navigationItems.some((item) => item?.label && item.label == "Dids") &&
      hasPermission("sms", ["readDids:any", "readDids:account"])) {
      setNavigationItems((prev) => [
        ...prev,
        {
          label: "Dids",
          key: "1",
          onClick: () => {
            navigate("/dids")
          },
        },
        
      ])
    }
  }, [data])

  useEffect(() => {
    if (!navigationItems.some((item) => item?.label && item.label == "Ringotel Users") &&
      hasPermission("users", ["read:any"])) {
      setNavigationItems((prev) => [
        ...prev,
        {
          label: "Ringotel Users",
          key: "2",
          onClick: () => {
            navigate("/ringotel-users")
          },
        },
        
      ])
    }
  }, [data])


  const dotMenuContent = (
    <Flex vertical={true}>
      <Flex>
        <Flex vertical={true} align="center" className="dot-menu-icons"
        >
          <img src={LiveCalls} alt="LiveCalls" />
          <Text style={TextStyle}

          >Live Calls</Text>
        </Flex>
        <Flex vertical={true} align="center" className="dot-menu-icons">
          <img src={CallCenter} alt="CallCenter" />
          <Text style={TextStyle}>Call center</Text>
        </Flex>
        <Flex vertical={true} align="center" className="dot-menu-icons">
          <img src={SMS} alt="SMS" />
          <Text style={TextStyle}>SMS</Text>
        </Flex>
      </Flex>
      <Flex>
        <Flex vertical={true} align="center" className="dot-menu-icons">
          <img src={Contacts} alt="Contacts" />
          <Text style={TextStyle}>Contacts</Text>
        </Flex>{" "}
        <Flex vertical={true} align="center" className="dot-menu-icons">
          <img src={PBX} alt="PBX" />
          <Text style={TextStyle}>PBX Portal</Text>
        </Flex>{" "}
        <Flex vertical={true} align="center" className="dot-menu-icons">
          <img src={Reports} alt="Reports" />
          <Text style={TextStyle}>Reports</Text>
        </Flex>
      </Flex>
    </Flex>
  );

  return (
    <Header style={HeaderStyle}>
      <Flex align="center" justify="space-between" style={HeaderContentStyle}>
        <img style={headerLogo} src={HeaderLogo} alt="header logo" onClick={() => navigate("/")} />

        <Flex style={HeaderMenu}>
          <Popover placement="bottom" content={dotMenuContent}>
            <img style={dotMenuStyle} src={dotMenu} alt="dot menu" />
          </Popover>

          <Text style={HeaderNameStyle}>{name}</Text>
          <Dropdown menu={{ items: navigationItems }} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <Avatar
                style={{ ...avatarStyle, background: color }}
                size="large"
              >
                {name[0]}
              </Avatar>
            </a>
          </Dropdown>
        </Flex>
      </Flex>
    </Header>
  );
};

export default LayoutHeader;
