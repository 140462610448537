var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useState, useEffect, useRef } from 'react';
import Cookies from "js-cookie";
import { jwtDecode } from 'jwt-decode';
import { loginUser, logoutUser, refresh, sessionData } from '../../Apis';
import debugModule from "debug";
var debug = debugModule('dataphone:auth');
var randomRefreshTimeWindow = 20000;
export var useAuthModule = function () {
    var _a = useState({ isAuthenticated: false, expirationTime: null, sessionData: null }), data = _a[0], setData = _a[1];
    var timeoutIdRef = useRef(null);
    useEffect(function () {
        if (data.isAuthenticated) {
            debug("user is authenticated fetching session data");
            getSession();
        }
    }, [data.isAuthenticated]);
    useEffect(function () {
        debug("setting timeout for next refresh");
        if (data.expirationTime) {
            if (timeoutIdRef.current)
                clearTimeout(timeoutIdRef.current);
            var timeoutInMilliseconds = data.expirationTime - Date.now() - (Math.random() * randomRefreshTimeWindow);
            debug("timeout set for next refresh in %d", timeoutInMilliseconds);
            if (timeoutInMilliseconds <= 0)
                return;
            timeoutIdRef.current = setTimeout(function () {
                refreshToken();
            }, timeoutInMilliseconds);
        }
        return function () {
            debug("clearing timeout");
            if (timeoutIdRef.current)
                clearTimeout(timeoutIdRef.current);
        };
    }, [data.expirationTime]);
    var onLoad = function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, trustedDevice, payload_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    debug("onLoad setting up session");
                    token = Cookies.get('sid');
                    trustedDevice = localStorage.getItem('trustedDevice');
                    if (!!token) return [3 /*break*/, 1];
                    debug("token not found clearing session");
                    clearSession();
                    return [2 /*return*/];
                case 1:
                    payload_1 = jwtDecode(token);
                    debug("checking trusted device");
                    if (!trustedDevice || trustedDevice == 'false') {
                        if (payload_1.exp && payload_1.exp < (Date.now() - 60 * 60 * 1000) / 1000) {
                            debug("token expired for more than an hour on untrusted device clearing session");
                            clearSession();
                            return [2 /*return*/];
                        }
                    }
                    if (!(payload_1.exp && payload_1.exp <= Date.now() / 1000)) return [3 /*break*/, 3];
                    debug("refreshing expired token");
                    return [4 /*yield*/, refreshToken()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    if (payload_1.exp && payload_1.exp > Date.now() / 1000) {
                        debug("token is still valid setting session and next refresh time");
                        setData(function (prev) { return (__assign(__assign({}, prev), { isAuthenticated: true, expirationTime: payload_1.exp ? payload_1.exp * 1000 : null })); });
                    }
                    else {
                        debug("token is invalid clearing session");
                        setData({ sessionData: null, isAuthenticated: false, expirationTime: null });
                    }
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        onLoad();
    }, []);
    var refreshToken = function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, payload_2, response, newToken, newPayload_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    debug("refreshing token");
                    token = Cookies.get('sid');
                    if (!token) {
                        clearSession();
                        return [2 /*return*/];
                    }
                    payload_2 = jwtDecode(token);
                    debug("checking if token is still valid %d", payload_2.exp);
                    if (payload_2.exp && payload_2.exp * 1000 > Date.now() + randomRefreshTimeWindow) {
                        debug("Updating the next refresh time");
                        setData(function (prev) { return (__assign(__assign({}, prev), { isAuthenticated: true, expirationTime: payload_2.exp ? payload_2.exp * 1000 : null })); });
                        return [2 /*return*/];
                    }
                    debug("getting new token from server");
                    return [4 /*yield*/, refresh()];
                case 1:
                    response = _a.sent();
                    if (response.code == '200') {
                        debug("refreshed token successfully");
                        newToken = Cookies.get('sid');
                        newPayload_1 = jwtDecode(newToken);
                        if (newPayload_1.exp) {
                            debug("updating the session data");
                            setData(function (prev) { return (__assign(__assign({}, prev), { isAuthenticated: true, expirationTime: newPayload_1.exp ? newPayload_1.exp * 1000 : null })); });
                        }
                    }
                    return [2 /*return*/, response];
                case 2:
                    error_1 = _a.sent();
                    debug("error in refreshing token", error_1);
                    console.log('error', error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getSession = function () { return __awaiter(void 0, void 0, void 0, function () {
        var session;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    debug("fetching session data");
                    return [4 /*yield*/, sessionData()];
                case 1:
                    session = _a.sent();
                    if (session.data) {
                        if (session.data.trustedDevice) {
                            localStorage.setItem('trustedDevice', 'true');
                        }
                        setData(function (prev) { return (__assign(__assign({}, prev), { sessionData: session.data })); });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var clearSession = function () {
        debug("clearing session");
        Cookies.remove('sid', { domain: 'dataphone.cloud' });
        setData(function (prev) { return ({ isAuthenticated: false, expirationTime: null, sessionData: null }); });
        window.localStorage.clear();
        if (window.location.origin !== "".concat(process.env.REACT_APP_LOGIN_PORTAL)) {
            window.location.href = "".concat(process.env.REACT_APP_LOGIN_PORTAL, "/login?redirectUrl=").concat(window.location.href);
        }
    };
    var logout = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    debug("logging out user");
                    return [4 /*yield*/, logoutUser()];
                case 1:
                    response = _a.sent();
                    clearSession();
                    return [2 /*return*/, response];
                case 2:
                    error_2 = _a.sent();
                    clearSession();
                    debug("error in logging out user", error_2);
                    console.log("error", error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var login = function (credentials) { return __awaiter(void 0, void 0, void 0, function () {
        var response, token, payload_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    debug("logging in user ", credentials.email);
                    return [4 /*yield*/, loginUser(credentials)];
                case 1:
                    response = _a.sent();
                    if (response.code == 200) {
                        token = Cookies.get('sid');
                        payload_3 = jwtDecode(token);
                        if (payload_3.exp) {
                            setData(function (prev) { return (__assign(__assign({}, prev), { isAuthenticated: true, expirationTime: payload_3.exp ? payload_3.exp * 1000 : null })); });
                        }
                    }
                    return [2 /*return*/, response];
            }
        });
    }); };
    return { data: data, login: login, logout: logout };
};
export default useAuthModule;
