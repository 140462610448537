import { Flex, Typography } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
const { Text } = Typography;
const WelcomeMessageContainer: React.CSSProperties = {
  height: "100%",
  textAlign: "center",
};

const WelcomeMessageHeading: React.CSSProperties = {
  fontSize: "32px",
  fontWeight: "500",
  lineHeight: "40px",
  color: "#121215",
};

const WelcomeMessageText: React.CSSProperties = {
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "24px",
  color: "#777587",
};

const WelcomeTextContainer = () => {
  const user = useSelector((state: RootState) => state.user.data);

  return (
    <Flex style={WelcomeMessageContainer} align="center" justify="center">
      <Flex vertical={true} justify="center">
        <Text style={WelcomeMessageHeading}>Welcome {user?.firstName}</Text>
        <Text style={WelcomeMessageText}>
          Please start or select a conversation
        </Text>
      </Flex>
    </Flex>
  );
};

export default WelcomeTextContainer;
