import { Button, Modal, Result } from 'antd';

const ConnectionWarningModal = ({
    handleTryAgain,
    open,
    setOpen
}: {
    handleTryAgain: Function
    open: boolean
    setOpen: Function
}) => (
    <Modal open={open} footer={null} onCancel={() => setOpen(false)}>
        <Result
            status="warning"
            title="Looks Like we are unable to connect you to the session. Pleate try again"
            extra={
                <Button onClick={() => handleTryAgain()} type="primary" key="console">
                    Try Again
                </Button>
            }
        />
    </Modal>
);

export default ConnectionWarningModal;