import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import ChatHeaderParticipant from "./chat.header.participant";
import WelcomeTextContainer from "./chat.welcomeText";
import ChatList from "./chat.list";
import { Content } from "antd/es/layout/layout";
import ChatTextArea from "./chat.textarea";
import ChatHeaderConversation from "./chat.header.conversation";
import ChatSideBar from "./chat.sidebar";
import { useState } from "react";

const rowStyle: React.CSSProperties = {
  height: "100%",
  background: "rgb(245, 246, 247)",
  position: "relative",
};

const MessageContainerStyle: React.CSSProperties = {
  //   background: "white",
  height: "100%",
};

const MessageSidebarSyle: React.CSSProperties = {
  width: "100px",
  background: "white",
};


const ChatContainer = ({
  updateConversation,
  ffmpegLoaded,
  ffmpegRef,
  isNewConversation,
  sendMessage,
  setIsNewConversation,
  deleteMessage,
  isSocketConnected
}: {
  updateConversation: (conversation: {
    did: string;
    participant: string;
    last_read_timestamp?: string;
    assigned_user?: string;
    resolved?: boolean;
  }) => void
  isNewConversation: boolean;
  sendMessage: Function;
  setIsNewConversation: Function
  ffmpegLoaded: boolean;
  ffmpegRef: any;
  deleteMessage: Function,
  isSocketConnected: boolean
}) => {
  const { selectedConversation } = useSelector(
    (state: RootState) => state.conversation
  );

  const [participants, setParticipants] = useState<string[]>([]);


  return (
    <Content style={{ height: "100%" }}>
      <Row style={rowStyle}>
        <Col flex="auto" style={MessageContainerStyle}>
          {(!selectedConversation &&  isNewConversation) && (
            <ChatHeaderParticipant isEnabled={true} participants={participants} setParticipants={setParticipants} />
          )}
          {selectedConversation && !isNewConversation && (
            <ChatHeaderConversation selectedConversation={selectedConversation} />
          )}
          {!selectedConversation && !isNewConversation && (
            <WelcomeTextContainer />
          )}
          {selectedConversation && !isNewConversation && (
            <ChatList key={`chat-${selectedConversation?.did}${selectedConversation?.participant}`} sendMessage={sendMessage} updateConversation={updateConversation} deleteMessage={deleteMessage} ffmpegLoaded={ffmpegLoaded} ffmpegRef={ffmpegRef} conversation={selectedConversation} />
          )}
          {(selectedConversation || isNewConversation) && <ChatTextArea isSocketConnected={isSocketConnected} setIsNewConversation={setIsNewConversation} participants={participants} setParticipants={setParticipants} sendMessage={sendMessage} />}
        </Col>
        {selectedConversation && (
          <Col flex="100px" style={MessageSidebarSyle}>
            <ChatSideBar updateConversation={updateConversation}  selectedConversation={selectedConversation}/>
          </Col>
        )}
      </Row>
    </Content>
  );
};

export default ChatContainer;
