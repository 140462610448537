import {  Flex, List } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { BaseUserDid } from "../../../interfaces/userDids.type";
import UserDidItem from "./Did.card";
import Loader from "../../generic/Loader";

const spinStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
};

/**
 * UserDidsList component - displays a list of user DIDs
 *
 * @param {RootState} state - Redux state
 * @returns {JSX.Element} - Rendered UserDidsList component
 */
const UserDidsList = ({
  searchInput,
  setIsNewConversation,
  showCounter,
  bulk,
  isLoading
}: {
  searchInput: string;
  setIsNewConversation?: Function;
  showCounter: boolean
  bulk?: boolean
  isLoading?:boolean
}): JSX.Element => {
  const { data, selectedDid } = useSelector(
    (state: RootState) => state.userDids
  );
  const filteredData = data?.filter((userDid: BaseUserDid) => {
    if (searchInput === "") return true;
    return userDid.did.toLowerCase().includes(searchInput.toLowerCase());
  });



  if (isLoading) {
    return (
      <Flex style={spinStyle} justify="center" align="center">
        <Loader />
      </Flex>
    );
  }
  return (
    <List
      itemLayout="horizontal"
      dataSource={bulk ? filteredData.filter(did => did.allow_bulk == true) : filteredData}
      renderItem={(userDid: BaseUserDid, index: number) => (
        <UserDidItem
          showCounter={showCounter}
          userDid={userDid}
          isSelected={userDid.did === selectedDid}
          setIsNewConversation={setIsNewConversation}
        />
      )}
    />
  );
};

export default UserDidsList;
