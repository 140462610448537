import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface user {
  id: string;
  first_name: string;
  last_name: string;
}

export interface usersState {
  data: user[];
}

export const initialState: usersState = {
  data: [],
};

export const didUsers = createSlice({
  name: "didUsers",
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<user[]>) => {
      state.data = action.payload;
    },
  },
});

export const { setUsers } = didUsers.actions;

export default didUsers.reducer;
