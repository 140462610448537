import { Button, Flex, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import type { TableColumnsType } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useGetTemplatesByAccount } from "../../../hooks/apis/templates.api";
import ModalWrapper from "../../generic/modal";
import TemplateForm from "../../Form/Template";
import { setSelectedView } from "../../../store/slices/admin.slice";
import { usePermissions } from "../../../hooks/permissions";
interface DataType {
    id: string;
    key: React.Key;
    name: string;
    description: string;
    body: string;
}


const Templates = ({
    account,
}: {
    account: string,
}) => {
    const dispatch: AppDispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(setSelectedView(""))
        }
    }, [])
    const columns: TableColumnsType<DataType> = [
        { title: "Name", dataIndex: "name", key: "name" },
        { title: "description", dataIndex: "description", key: "description" },
        {
            title: "Action",
            dataIndex: "",
            render: (data) => <a onClick={() => {
                setEditData(data)
                setAddTemplate(true)
            }}>Edit</a>,
        },
    ];
    const [addTemplate, setAddTemplate] = useState(false);

    const [editData, setEditData] = useState(null);
    const { data: templates } = useSelector(
        (state: RootState) => state.templates
    );
    const { refetch, isLoading } = useGetTemplatesByAccount(account || "");
    const { hasPermission } = usePermissions();
    useEffect(() => {
        if (account){
           refetch();
        }
    }, [account]);

    return (
        <div >
            <ModalWrapper
                isModalOpen={addTemplate}
                setIsModalOpen={setAddTemplate}
                title="Add Template"
            >
                <TemplateForm account={account} editData={editData} setIsModalOpen={setAddTemplate} />
            </ModalWrapper>
            <Flex justify="space-between" align="center" style={{ padding: "10px" }}>
                <Typography.Title>Templates</Typography.Title>{" "}
                {
                    hasPermission("sms", ["createTemplates:any", "createTemplates:account"]) &&
                    <Button type="primary" onClick={() => {
                        setAddTemplate(true)
                        setEditData(null)
                    }}>Create Template</Button>
                }

            </Flex>
            <Table
                columns={columns}
                loading={isLoading}
                expandable={{
                    expandedRowRender: (record) => (
                        <p key={record.id} style={{ margin: 0 }}>
                            {record.body}
                        </p>
                    ),
                    rowExpandable: (record) => record.name !== "Not Expandable",
                }}
                dataSource={templates.map((item) => ({ ...item, key: item.id })).filter((item) => item.account === account)}
            />
        </div>
    );
};

export default Templates;
