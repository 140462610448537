import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';

export const formatPhoneNumber = (number: string) => {
  if (isValidPhoneNumber(number)) {
    const phoneNumber = parsePhoneNumber(number);
    return phoneNumber?.formatNational();
  } else {
    return number;
  }
};

export const phoneNumberValidate = (number: string) => {
  const phoneNumber = parsePhoneNumber(number, 'US');
  return phoneNumber?.isValid()

};

export const phoneNumberFormatE164 = (number: string) => {
  const phoneNumber:any = parsePhoneNumber(number,'US');
  if (phoneNumber?.isValid()) {
    return phoneNumber.number;
  } else {
    return null;
  }
};
