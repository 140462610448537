import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { setCarriers } from "../../store/slices/admin.slice";
import { api } from "@data-phone/react-resource-server-auth";

export interface Gateway {
    id: string;
    name: string;
}

export interface Carrier {
    name: string;
    enabled: boolean;
    description: string;
    gateways: Gateway[];
}

const fetchCarriers = async () => {
    const response = await api.get(
        `${process.env.REACT_APP_SMS_API_URL}/carrier`,
    );
    return response.data;
};


export const useGetCarriers = () => {
    const dispatch: AppDispatch = useDispatch();
    return useQuery("carriers", fetchCarriers, {
        enabled: false,
        onSuccess: (data) => {
            dispatch(setCarriers(data));
        }
    });
}

