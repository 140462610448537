import React from "react";
import ModalWrapper from "../generic/modal";
import AddContactForm from "../Form/AddContact";

const AddContactModal = ({
  setIsModalOpen,
  isModalOpen,
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
}) => {
  return (
    <ModalWrapper
      title="Add Contact"
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <AddContactForm setIsModalOpen={setIsModalOpen} />
    </ModalWrapper>
  );
};

export default AddContactModal;
