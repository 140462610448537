import { Avatar, Dropdown, Flex, List, MenuProps, Modal, Spin, Tooltip } from "antd"
import { HeadingText } from "../../generic/Typography"
import { formatPhoneNumber } from "../../../utils/phoneLib"
import { Did, useDeleteDid } from "../../../hooks/apis/dids.api";
import phoneImg from "../../../assets/images/icon/Phone-call.svg";
import { useEffect, useState } from "react";
import ModalWrapper from "../../generic/modal";
import DidForm from "../../Form/Dids";
import { useMessageToast } from "../../Layout/DefaultLayout";
import { FaUsersCog } from "react-icons/fa";
import { FcRules } from "react-icons/fc";
import { FcInfo } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { setSelectedView } from "../../../store/slices/admin.slice";
import { FaInfoCircle } from "react-icons/fa";
import { usePermissions } from "../../../hooks/permissions";





const cardStyle = {
    border: "none",
    padding: "10px",
    borderRadius: "6px",
    cursor: "pointer",
};

const didCardStyle: React.CSSProperties = {
    width: "100%",
    borderRadius: 6,
};


const avatarStyle: React.CSSProperties = {
    height: "1.2rem",
    width: "1.2rem",
    marginTop: "2px",
    marginRight: "10px"
};


const DidItem = ({ did, onClick, selectedDid, setSelectedDid, selectedAccount }: {
    did: Did,
    onClick: Function
    selectedDid: Did | null
    setSelectedDid: Function
    selectedAccount: string
}) => {

    const dispatch: AppDispatch = useDispatch()
    const { data } = useSelector((state: RootState) => state.user)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [editData, setEditData] = useState<Did | null>(null);
    const { hasPermission } = usePermissions()

    const handleView = (view: string) => {
        dispatch(setSelectedView(view))
    }




    return (
        <>
            <ModalWrapper
                title="Edit DID"
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            >
                <DidForm selectedAccount={selectedAccount} setSelectedDid={setSelectedDid} editData={editData} setIsModal={setIsModalOpen} />
            </ModalWrapper>
            <List.Item
                style={{
                    ...cardStyle,
                    background: selectedDid && selectedDid.did === did.did ? "#eff4fb" : "#fff",
                }}
                onClick={() => onClick(did)}
            >
                <Flex justify="space-between" style={didCardStyle}>
                    <Flex>
                        <Avatar style={avatarStyle} src={phoneImg} />

                        <HeadingText
                            text={formatPhoneNumber(did.did) as string}
                        ></HeadingText>
                    </Flex>
                    <Flex gap={"10px"}>
                        {
                            hasPermission("sms", ["readUserDids:any", "readUserDids:account"])
                            && <Tooltip title="Assigned Users" color="blue" >
                                <FaUsersCog color="rgb(101, 59, 250)" onClick={() => handleView("userDids")} />
                            </Tooltip>
                        }
                        {
                            hasPermission("sms", ["readAutoReplyRules:any", "readAutoReplyRules:account"]) &&
                            <Tooltip title="Auto Reply Rules" color="blue" >
                                <FcRules color="rgb(101, 59, 250)" onClick={() => handleView("rules")} />
                            </Tooltip>
                        }

                        {
                            hasPermission("sms", ["readDids:any", "readDids:account"]) &&
                            <Tooltip title="Info" color="blue" >
                                <FaInfoCircle color="rgb(101, 59, 250)" onClick={() => handleView("info")} />
                            </Tooltip>
                        }


                    </Flex>
                </Flex>
            </List.Item>
        </>
    )
}

export default DidItem