import { Layout } from "antd";
import { Outlet, useOutletContext } from "react-router-dom";
import { ConfigProvider } from "antd";
import LayoutHeader from "./Header.layout";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { message } from "antd";
import { MessageInstance } from "antd/es/message/interface";



const DefaultLayout = ({
  logout
}:{
  logout: () => void
}) => {
  const layoutStyle: React.CSSProperties = {
    height: "100vh",
    background: "#ffffff !important",
  };
  const user = useSelector((state: RootState) => state.user.data);
  const [messageApi, contextHolder]  = message.useMessage();


  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Rethink Sans",
        },
      }}
    >
      <Layout style={layoutStyle}>
        {contextHolder}
        <LayoutHeader  logout={logout} name={`${user?.firstName} ${user?.lastName}`} />
        <Layout>
          <Outlet context={{messageApi} satisfies MessageInstance | any } />
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default DefaultLayout;

export function useMessageToast() {
  return useOutletContext<any>();
}
