import { useSelector } from "react-redux"
import { Button, Form, FormProps, Input, Select } from "antd"
import { RingotelOrg, useAddRingtelOrganization, useUpdateRingtelOrganization } from "../../hooks/apis/ringotel.api"
import { useEffect, useState } from "react"
import { useMessageToast } from "../Layout/DefaultLayout"
import { RootState } from "../../store/store"

const RingotelOrgForm = (
    {
        setIsModal,
        editData
    }: {
        setIsModal: React.Dispatch<React.SetStateAction<boolean>>
        editData: RingotelOrg | null
    }
) => {
    const [form] = Form.useForm();
    const { accounts } = useSelector((state: RootState) => state.admin)
    const { data: ringotelOrg } = useSelector((state: RootState) => state.ringotelOrgs)
    const { mutate: addRingtelOrganization, isLoading: isLoadingAddOrganization, isSuccess: isSuccessAddOrganization } = useAddRingtelOrganization()
    const { mutate: updateRingtelOrg, isLoading: isLoadingUpdateOrganization, isSuccess: isSuccessUpdateOrganization } = useUpdateRingtelOrganization()
    const [ringotelOrgData, setRingotelOrgData] = useState<RingotelOrg>(editData || {
        orgid: "",
        account_id: ""
    })
    useEffect(() => {
        form.resetFields()
    }, [])

    useEffect(() => {
        if (editData) {
            form.setFieldsValue(editData)
        } else {
            form.resetFields()
        }
    }, [editData])


    useEffect(() => {
        if (isSuccessAddOrganization) {
            setIsModal(false)
        }
    }, [isSuccessAddOrganization])


    useEffect(() => {
        if (isSuccessUpdateOrganization) {
            setIsModal(false)
        }
    }, [isSuccessUpdateOrganization])
    const onFinish: FormProps<{
        role_id: string
    }>["onFinish"] = (values: any) => {
        if (!editData) {
            addRingtelOrganization({
                orgid: values.orgid,
                account_id: values.account_id
            })
            return
        }

        const updateData: {
            orgid: string
            data: RingotelOrg
        } = {
            orgid: editData.orgid,
            data: values
        }
        updateRingtelOrg(updateData)
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    return <Form
        style={{ padding: "10px" }}
        name="basic"
        form={form}
        labelCol={{ span: 4 }}
        onFinish={onFinish}
    >
        <Form.Item<RingotelOrg>
            name="orgid"
            label="Org ID"

            rules={[{
                required: true, validator(rule, value) {
                    if (!value) {
                        return Promise.reject("Please input organization ID")
                    }
                    if (!editData && ringotelOrg.find(org => org.orgid === value)) {
                        return Promise.reject("Organization already exists with this ID")
                    }

                    return Promise.resolve();
                }
            }]}
        >
            <Input
                width={"80%"}
                onChange={(e) => {
                    setRingotelOrgData({
                        ...ringotelOrgData,
                        orgid: e.target.value
                    })
                }}
                placeholder="Organization ID"
            />
        </Form.Item>
        <Form.Item<RingotelOrg>
            label="Account"
            name="account_id"
            rules={[{ required: true, message: "Please Select Account" }]}
        >
            <Select
                showSearch
                placeholder="Select Account"
                optionFilterProp="children"
                onChange={(value) => {
                    setRingotelOrgData({
                        ...ringotelOrgData,
                        account_id: value
                    })
                }}
                filterOption={filterOption}
                options={accounts.filter(account => editData ? true : !ringotelOrg.some(org => org.account_id === account.id)).map(account => {
                    return {
                        label: account.name,
                        value: account.id
                    }
                })}
            />
        </Form.Item>
        <Form.Item<RingotelOrg>>
            <Button
                style={{ width: "100%" }}
                type="primary"
                disabled={isLoadingAddOrganization || isLoadingUpdateOrganization || ((editData && ringotelOrgData.orgid == editData.orgid && ringotelOrgData.account_id == editData.account_id) ? true : false)}
                loading={isLoadingAddOrganization || isLoadingUpdateOrganization}
                htmlType="submit"
            >
                {editData ? "Update Organization" : "Add Organization"}
            </Button>
        </Form.Item>
    </Form>

}

export default RingotelOrgForm