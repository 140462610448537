import { Flex, Input, List, Typography } from "antd";
import { useState } from "react";
import { IoSearch } from "react-icons/io5";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { UserCard } from "./User.card";

const TextStyleHeading: React.CSSProperties = {
  fontSize: "18px",
  lineHeight: "30px",
  color: "gray",
  padding: "5px",
};
const ItemContentStyle: React.CSSProperties = {
  width: "100%",
  padding: "5px",
  borderRadius: "10px",
};
export const UsersList = ({
    setIsModalOpen,
    isModalOpen,
    updateConversation,
    conversation
}:{
    setIsModalOpen: Function
    isModalOpen: boolean
    updateConversation: Function
    conversation: {
        did: string
        participant: string
    }
}) => {
  const [searchInput, setSearchInput] = useState("");
  const { data: users } = useSelector((state: RootState) => state.didUsers);
  return (
    <div
      style={{
        background: "#F5F6F7",
        padding: "10px",
        height: "max-content",
      }}
    >
      <Input
        style={{
          border: "1px solid #777587",
          borderRadius: "25px",
          background: "#F5F6F7",
        }}
        size="middle"
        onChange={(e) => setSearchInput(e.target.value)}
        value={searchInput}
        placeholder="Search "
        prefix={<IoSearch />}
      />
      <Typography.Text style={TextStyleHeading}>All Users</Typography.Text>
      <Flex
        align="flex-start"
        justify="space-evenly"
        style={{
          ...ItemContentStyle,
        }}
        wrap="wrap"
        gap="small"
      >
        <List
          itemLayout="horizontal"
          style={{
            height: "200px",
            overflow: "auto",
            width: "100%",
          }}
          dataSource={users}
        >
          {users
            .filter((user) => {
              if (searchInput === "") return true;
              else {
                return `${user.first_name} ${user.last_name}`
                  .toLowerCase()
                  .includes(searchInput.toLowerCase());
              }
            })
            .map((user) => {
              return <UserCard conversation={conversation} updateConversation={updateConversation} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} user={user} />;
            })}
        </List>
      </Flex>
    </div>
  );
};
