import { useState } from "react";
import { phoneNumberValidate } from "../utils/phoneLib";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { useAddContact } from "../hooks/apis/contacts.api";
import { useMessageToast } from "../components/Layout/DefaultLayout";

interface InitialContactData {
  isPrivate?: boolean;
  isGoogleContact?: boolean;
  phoneNumbers?: string[];
  emailAddresses?: string[];
}

type SuccessCallback = (message: string) => void;
type ErrorCallback = (error: string) => void;

interface UseAddContactFormHandlerReturn {
  isPrivateContact: boolean;
  setIsPrivateContact: React.Dispatch<React.SetStateAction<boolean>>;
  isGoogleContact: boolean;
  setIsGoogleContact: React.Dispatch<React.SetStateAction<boolean>>;
  numbers: string[];
  emails: string[];
  //   isLoading: boolean;
  handleAddNumber: () => void;
  handleAddEmail: () => void;
  handleEmailChange: (index: number, value: string) => void;
  handleRemoveEmail: (index: number) => void;
  handleNumberChange: (index: number, value: string) => void;
  handleRemoveNumber: (index: number) => void;
}

const useAddContactFormHandler = (
  form: any
): UseAddContactFormHandlerReturn => {
  const {selectedConversation} = useSelector((state: RootState) => state.conversation)


  const dispatch: AppDispatch = useDispatch();

  const initialContactData: InitialContactData = {
    isPrivate: false,
    isGoogleContact: false,
    phoneNumbers: [selectedConversation?selectedConversation.participant:""],
    emailAddresses: [""],
  };
  const [isPrivateContact, setIsPrivateContact] = useState(
    initialContactData.isPrivate || false
  );
  const [numbers, setNumbers] = useState(
    initialContactData.phoneNumbers || [""]
  );
  const [emails, setEmails] = useState(
    initialContactData.emailAddresses || [""]
  );
  const [isGoogleContact, setIsGoogleContact] = useState(
    initialContactData.isGoogleContact || false
  );

  const handleAddNumber = () => {
    setNumbers([...numbers, ""]);
  };

  const handleAddEmail = () => {
    setEmails([...emails, ""]);
  };

  const handleEmailChange = (index: number, value: string) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = value;
    setEmails(updatedEmails);
  };

  const handleRemoveEmail = (index: number) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };

  const handleNumberChange = (index: number, value: string) => {
    const updatedNumbers = [...numbers];
    updatedNumbers[index] = value;
    setNumbers(updatedNumbers);
  };

  const handleRemoveNumber = (index: number) => {
    const updatedNumbers = [...numbers];
    updatedNumbers.splice(index, 1);
    setNumbers(updatedNumbers);
  };

 

  return {
    isPrivateContact,
    isGoogleContact,
    setIsGoogleContact,
    setIsPrivateContact,
    numbers,
    emails,
    // isLoading,
    handleAddNumber,
    handleAddEmail,
    handleEmailChange,
    handleRemoveEmail,
    handleNumberChange,
    handleRemoveNumber
  };
};

export default useAddContactFormHandler;
