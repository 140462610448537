import { Button, Form, FormProps, Input, Select } from "antd"
import { useEffect, useState } from "react";
import { RingotelUser, useAddRingotelUser, useUpdateRingotelUser } from "../../hooks/apis/ringotelUsers.api";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const RingotelUserForm = (
    {
        setIsModal,
        editData,
        account_id
    }: {
        setIsModal: React.Dispatch<React.SetStateAction<boolean>>
        editData: RingotelUser | null
        account_id: string
    }
) => {
    const [form] = Form.useForm();
    const { users } = useSelector((state: RootState) => state.admin)
    const { data: ringotelOrgs } = useSelector((state: RootState) => state.ringotelOrgs)
    const { mutate: addRingotelUser, isLoading: isLoadingAddUser, isSuccess: isSuccessAddUser } = useAddRingotelUser()
    const { mutate: updateRingotelUser, isLoading: isLoadingUpdateUser, isSuccess: isSuccessUpdateUser } = useUpdateRingotelUser()
    const {data: ringotelUsers} = useSelector((state: RootState) => state.ringotelUsers)

    useEffect(() => {
        form.resetFields()
    }, [])

    useEffect(() => {
        if (editData) {
            form.setFieldsValue(editData)
        } else {
            form.resetFields()
        }
    }, [editData])


    useEffect(() => {
        if (isSuccessAddUser) {
            setIsModal(false)
        }
    }, [isSuccessAddUser])


    useEffect(() => {
        if (isSuccessUpdateUser) {
            setIsModal(false)
        }
    }, [isSuccessUpdateUser])

    const onFinish: FormProps<{
        role_id: string
    }>["onFinish"] = (values: any) => {
        if (!editData) {
            addRingotelUser({
                user_id: values.user_id,
                user_ext: values.user_ext,
                account_id: account_id,
                orgid: ringotelOrgs.find((org) => org.account_id === account_id)?.orgid ||""
            })
            return
        }
        const updateData: {
            user_id: string
            data: RingotelUser
        } = {
            user_id: editData.user_id,
            data: {
                user_id: values.user_id,
                user_ext: values.user_ext,
                account_id: account_id,
                orgid: ringotelOrgs.find((org) => org.account_id === account_id)?.orgid ||""
            }
        }
        updateRingotelUser(updateData)
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    return <Form
        style={{ padding: "10px" }}
        name="basic"
        form={form}
        labelCol={{ span: 4 }}
        onFinish={onFinish}
    >
        <Form.Item<RingotelUser>
            label="User"
            name="user_id"
            rules={[{ required: true ,validator(rule, value) {
                if (!value) {
                    return Promise.reject("Please Select User")
                }
                if(!editData && ringotelUsers.some(user => user.user_id === value)) {
                    return Promise.reject("User already exists")
                }
                return Promise.resolve();
            },}]}
        >
            <Select
                showSearch
                placeholder="Select User"
                optionFilterProp="children"
                filterOption={filterOption}
                options={users.filter(user => user.account_id === account_id).map(user => {
                    return {
                        label: `${user.first_name} ${user.last_name}`,
                        value: user.id
                    }
                })}
            />
        </Form.Item>
        <Form.Item<RingotelUser>
            name="user_ext"
            label="Extension"
            rules={[{ required: true, message: "Please input extension" }]}
        >
            <Input
                placeholder="User extension"
            />
        </Form.Item>
  
        <Form.Item>
            <Button
                style={{ width: "100%" }}
                type="primary"
                disabled={isLoadingAddUser || isLoadingUpdateUser}
                loading={isLoadingAddUser || isLoadingUpdateUser}
                htmlType="submit"
            >
                {editData ? "Update Ringotel User" : "Add Ringotel User"}
            </Button>
        </Form.Item>
    </Form>
}
export default RingotelUserForm