import { initializeApp } from 'firebase/app';
import { useEffect, useRef, useState } from "react"
import { getPushNotificationConfig, PushNotificationConfig, registerNotificationToken } from "../apis/notifications.api";
import { getMessaging, getToken, Messaging } from "firebase/messaging";


export const usePushNotificationRegister = () => {
    const [pushNotificationConfig, setPushNotificationConfig] = useState<PushNotificationConfig | null>(null);
    const [messaging, setMessaging] = useState<Messaging | null>(null);

    const firebaseApp = useRef({
        initializeApp:initializeApp
    });

    const registerToken = (messaging:Messaging) =>{
        getToken(messaging, { vapidKey: pushNotificationConfig?.vapidKey }).then((currentToken) => {
            if (currentToken) {
                registerNotificationToken(currentToken)
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
    }


    const registerServiceWorker = (pushNotificationConfig: Omit<PushNotificationConfig, "vapidKey">) => {

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/firebase-messaging-sw.js')
                .then(async () => {
                    try {
                        const registrationReady = await navigator.serviceWorker.ready;
                        if (registrationReady.active) {
                            if (navigator.serviceWorker.controller) {
                                navigator.serviceWorker.controller.postMessage({
                                    type: 'SET_CONFIG',
                                    config: pushNotificationConfig
                                });
                            }

                        }

                    } catch (error) {
                        console.error('Error waiting for service worker:', error);
                    }
                }).catch((error) => {
                    console.error('Service Worker registration failed:', error);
                });


        }
    }

    useEffect(() => {
        getPushNotificationConfig().then((response) => {
            if (firebaseApp.current) {
                const app = firebaseApp.current.initializeApp(response);
                setMessaging(getMessaging(app));
            }
            setPushNotificationConfig(response);
        })
    }, [])



    useEffect(() => {
        if (messaging) {
            registerToken(messaging);
        }
    }, [messaging])

  
    useEffect(() => {
        if (pushNotificationConfig) {
            registerServiceWorker(pushNotificationConfig);
        }
    }, [pushNotificationConfig])



    return { messaging, pushNotificationConfig }

}
