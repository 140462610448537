import { useMutation, useQuery } from "react-query";
import axios, { AxiosResponse } from "axios";
import { BaseUserDid } from "../../interfaces/userDids.type";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import { setSelectedDid, setUserDids } from "../../store/slices/userDids.slice";
import { addUserDid as addUserDidSlice, updateUserDid as updateUserDidSlice, setUserDidsByAccount, deleteUserDid as deleteUserDidSlice } from "../../store/slices/admin.slice";
import { api } from "@data-phone/react-resource-server-auth";

export interface AccountUserDid {
  did: string;
  account_id: string;
  user_id: string;
  email_enabled: boolean;
  sms_to_email: string | null
  sip_uri: string | null;
}

const fetchData = async (account: string | null | undefined, dispatch: AppDispatch): Promise<BaseUserDid[]> => {
  const response: AxiosResponse<BaseUserDid[]> = await api.get(
    `${process.env.REACT_APP_SMS_API_URL}/sms/dids${account ? `?account_id=${account}` : ''}`,
  );
  if (response.data) {
    dispatch(setUserDids(response.data));
    dispatch(setSelectedDid(response.data[0]?.did));
  }
  return response.data;

};


const addUserDid = async (data: AccountUserDid) => {
  const response: AxiosResponse = await api.post(
    `${process.env.REACT_APP_SMS_API_URL}/did/user`,
    data
  );
  return response.data;
}

const updateUserDid = async ( data: {
  id: string
  data: AccountUserDid
  }) => {
  const response: AxiosResponse = await api.put(
    `${process.env.REACT_APP_SMS_API_URL}/did/user/${data.data.did}/${data.id}`,
    data.data
  );
  return response.data;
}

const deleteUserDid = async (did: string, user_id: string, dispatch: AppDispatch) => {
  const response: AxiosResponse = await api.delete(
    `${process.env.REACT_APP_SMS_API_URL}/did/user/${did}/${user_id}`,
  );
  dispatch(deleteUserDidSlice({
    did,
    user_id
  }))
  return response.data;
}

const fetchUserDidsByAccount = async (accountId: string) => {
  const response: AxiosResponse = await api.get(
    `${process.env.REACT_APP_SMS_API_URL}/did/users/${accountId}`,
  );
  return response.data;
};


export const useGetUserDidsByAccount = (accountId: string | null | undefined) => {
  const dispatch: AppDispatch = useDispatch();
  return useQuery("userDids", () => fetchUserDidsByAccount(accountId as string), {
    enabled: false,
    onSuccess: (data) => {
      dispatch(setUserDidsByAccount(data))
    }
  })
}

export const useGetUserDids = (account: string | null | undefined) => {
  const dispatch: AppDispatch = useDispatch();
  return useQuery<BaseUserDid[], Error>("userDids", () => fetchData(account,dispatch),{
    enabled: false
  });
};


export const useAddUserDid = () => {
  const dispatch: AppDispatch = useDispatch();
  return useMutation((data: AccountUserDid) => addUserDid(data), {
    onSuccess: (data) => {
      dispatch(addUserDidSlice(data))
    }
  })
}

export const useUpdateUserDid = () => {
  const dispatch: AppDispatch = useDispatch();
  return useMutation((data:{
    id: string
    data: AccountUserDid
  }) => updateUserDid(data), {
    onSuccess: (data) => {
      dispatch(updateUserDidSlice(data))
    }
  })
}

export const useDeleteUserDid = () => {
  const dispatch: AppDispatch = useDispatch();
  return useMutation((data: { did: string, user_id: string }) => deleteUserDid(data.did, data.user_id, dispatch))
}