import React, { useEffect } from "react";
import { Button, Checkbox, Form, type FormProps, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  useAddTemplate,
  useUpdateTemplate,
} from "../../hooks/apis/templates.api";
import useMessageToast from "../../hooks/error/errorMessage";
import {
  addTemplateSlice,
  updateTemplateSlice,
} from "../../store/slices/templates.slice";

type FieldType = {
  name: string;
  body: string;
  description?: string;
};

const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const TemplateForm = ({
  setIsModalOpen,
  editData,
  account
}: {
  setIsModalOpen: Function;
  editData?: any;
  account: string
}) => {
  const [form] = Form.useForm();
  form.resetFields();
  useEffect(() => {
    if (editData) {
      form.setFieldsValue(editData);
    }
  }, [editData]);

  const {
    mutate: addTemplate,
    isLoading,
    isSuccess,
    data: addData,
  } = useAddTemplate();
  const {
    mutate: updateTemplate,
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
    data: dataUpdate,
  } = useUpdateTemplate(editData?.id);
  const { contextHolder, success, error } = useMessageToast();
  const dispatch: AppDispatch = useDispatch();
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    const { name, body, description } = values;
    if (editData) {
      updateTemplate({
        name,
        body,
        description,
        account: account,
        id: editData?.id,
      });
      return;
    }
    addTemplate({ name, body, description, account: account });
    form.resetFields();
    console.log("Success:", values);
  };

  useEffect(() => {
    if (isSuccess) {
      success("Template created successfully");
      form.resetFields();
      setIsModalOpen(false);
      console.log("success");
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isSuccessUpdate) {
      dispatch(updateTemplateSlice(dataUpdate));
      success("Template updated successfully");
      form.resetFields();
      setIsModalOpen(false);
      console.log("success");
    }
  }, [isSuccessUpdate]);
  return (
    <Form
      style={{ padding: "10px" }}
      name="basic"
      form={form}
      labelCol={{ span: 4 }}
      //   wrapperCol={{ span: 16 }}
      //   style={{ maxWidth: 600 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      {contextHolder}
      <Form.Item<FieldType>
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input template name!" }]}
      >
        <Input placeholder="Template Name" />
      </Form.Item>
      <Form.Item<FieldType> label="Description" name="description">
        <Input.TextArea placeholder="Template Description" />
      </Form.Item>
      <Form.Item<FieldType>
        name="body"
        label="Body"
        rules={[{ required: true, message: "Please input your text!" }]}
      >
        <Input.TextArea placeholder="Template Body" />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          disabled={isLoading || isLoadingUpdate}
          loading={isLoading || isLoadingUpdate}
          htmlType="submit"
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TemplateForm;
