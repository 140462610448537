import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AutoReplyRulesType {
  label: string;
  conditions: conditionTypes[];
}

export interface conditionTypes {
  name: string;
  values: string[];
}
export interface AutoReplyRule {
  id: string;
  did: string;
  rule: AutoReplyRulesType;
  position: number;
  template: string;
  enabled: boolean;
}

export interface autoReplyRuleState {
  data: AutoReplyRule[];
}

export const initialState: autoReplyRuleState = {
  data: [],
};

const autoReplyRuleSlice = createSlice({
  name: "autoReplyRules",
  initialState,
  reducers: {
    setAutoReplyRules: (state, action: PayloadAction<AutoReplyRule[]>) => {
      action.payload.forEach((rule) => {
        const index = state.data.findIndex((item) => item.id === rule.id);
        if (index !== -1) {
          state.data[index] = rule;
        } else {
          state.data.push(rule);
        }
      });
    },

    updateAutoReplyRules: (state, action: PayloadAction<AutoReplyRule>) => {
      const index = state.data.findIndex(
        (rule) => rule.id === action.payload.id
      );
      if (index !== -1) {
        state.data[index] = action.payload;
      }
    },
    addAutoReplyRuleSlice: (state, action: PayloadAction<AutoReplyRule>) => {
      state.data.push(action.payload);
    },
    deleteAutoReplyRuleSlice: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((rule) => rule.id !== action.payload);
    }
  },
});

export const { setAutoReplyRules, updateAutoReplyRules,  deleteAutoReplyRuleSlice ,addAutoReplyRuleSlice} =
  autoReplyRuleSlice.actions;

export default autoReplyRuleSlice.reducer;
