export function formatMessageDate(date: string | Date): string {
    const inputDate = new Date(date);

    const dayDifference = dayDiff(new Date(), inputDate);

    switch (dayDifference) {
        case 0:
            return formatTime(inputDate, '12');
        case 1:
            return 'Yesterday';
        default:
            if (dayDifference < 7) {
                return inputDate.toLocaleDateString(undefined, { weekday: 'long' });
            } else {
                return inputDate.toLocaleDateString();
            }
    }
}

export function dayDiff(date1:Date, date2:Date): number {
    const msDiff = Math.abs(date1.getTime() - date2.getTime());
    return Math.floor(msDiff / (1000 * 60 * 60 * 24));
}

export function formatTime(date: Date, format: '12' | '24'): string {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const minutesStr = minutes.toString().padStart(2, '0');

    if (format === '12') {
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        return `${hours}:${minutesStr} ${ampm}`;
    }

    return `${hours.toString().padStart(2, '0')}:${minutesStr}`;
}
