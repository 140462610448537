import React from "react";
import RingotelUserForm from "../Form/RingotelUser";
import ModalWrapper from "../generic/modal";
const AddRingotelUser = ({
  setIsModalOpen,
  isModalOpen,
  account_id
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  account_id: string
}) => {
  return (
    <ModalWrapper
      title="Add Ringotel User"
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <RingotelUserForm account_id={account_id} editData={null} setIsModal={setIsModalOpen} />
    </ModalWrapper>
  );
};

export default AddRingotelUser;
