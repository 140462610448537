import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BaseMessage,
} from "../../interfaces/messages.type";

export interface MessageData {
  did: string;
  participant: string;
  messages: BaseMessage[];
}

export interface MessagesState {
  data: MessageData[];
}

const initialState: MessagesState = {
  data: [],
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<BaseMessage[]>) => {
      action.payload.forEach((message) => {
        const index = state.data.findIndex(
          (item) =>
            item.did === message.did && item.participant === message.participant
        );
        if (index !== -1) {
          if (state.data[index].messages.some((m) => m.id === message.id))
            return;
          state.data[index].messages = [
            ...state.data[index].messages.filter((m) => m.id !== undefined),
            message,
          ];
        } else {
          state.data.push({
            did: message.did,
            participant: message.participant,
            messages: [message],
          });
        }
      });
    },
    updateDeliveryStatusSlice: (state, action: PayloadAction<{
      error: string | null
      id: string
      status: string
    }>) => {
      const index = state.data.findIndex(item => {
        return item.messages.some((m) => m.id === action.payload.id)
      })

      const messageIndex = state.data[index].messages.findIndex((m) => m.id === action.payload.id)

      if (index !== -1) {
        state.data[index].messages[messageIndex] = { ...state.data[index].messages[messageIndex], status: action.payload.status, failed_reason: action.payload.error }
      }

    },
    onMessage: (state, action: PayloadAction<BaseMessage>) => {
      const index = state.data.findIndex(
        (item) =>
          item.did === action.payload.did &&
          item.participant === action.payload.participant
      );
      if (index !== -1) {
        if (action.payload.direction === "outbound") {

          if (action.payload.clientId && state.data[index].messages.some((m) => m.clientId === action.payload.clientId)) {
            state.data[index].messages = state.data[index].messages.map((m) => m.clientId === action.payload.clientId ? action.payload : m)
          }

          else {
            state.data[index].messages = [
              ...state.data[index].messages.map((item) => { return { ...item, position: item.position + 1 } }),
              action.payload,
            ];
          }
        }
        else if (action.payload.direction === "inbound") {
          state.data[index].messages = [
            ...state.data[index].messages.map((item) => { return { ...item, position: item.position + 1 } }),
            action.payload,
          ];
        }
      } else {
        state.data.push({
          did: action.payload.did,
          participant: action.payload.participant,
          messages: [action.payload],
        });
      }
    },

    deleteMessage: (state, action: PayloadAction<string>) => {
      const index = state.data.findIndex(item => {
        return item.messages.some((m) => m.id === action.payload)
      })
      const messageIndex = state.data[index].messages.findIndex((m) => m.id === action.payload)

      if (index !== -1) {
        state.data[index].messages[messageIndex] = { ...state.data[index].messages[messageIndex], deleted: true }
      }
    },
  },
});

export const {
  setMessages,
  onMessage,
  deleteMessage,
  updateDeliveryStatusSlice
} = messagesSlice.actions;

export default messagesSlice.reducer;
