import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ScheduledMessage } from "../../hooks/apis/scheduled_messages.api"

export interface ScheduledMessageState {
    data: ScheduledMessage[]
}

export const initialState: ScheduledMessageState = {
    data: [],
}

const scheduledMessagesSlice = createSlice({
    name: "scheduledMessages",
    initialState,
    reducers: {
        setScheduledMessages: (state, action: PayloadAction<ScheduledMessage[]>) => {
            action.payload.forEach((message) => {
                if (state.data.some((item) => item.id === message.id)) return
                state.data.push(message)
            })
        },
        addScheduledMessageSlice: (state, action: PayloadAction<ScheduledMessage>) => {
            state.data.push(action.payload);
        },

        updateScheduledMessageSlice: (state, action: PayloadAction<ScheduledMessage>) => {
            const index = state.data.findIndex(
                (item) => item.id === action.payload.id
            );
            if (index !== -1) {
                state.data[index] = action.payload;
            }
        },
        deleteScheduledMessageSlice: (state, action: PayloadAction<string>) => {
            const index = state.data.findIndex(
                (item) => item.id === action.payload
            );
            if (index !== -1) {
                state.data.splice(index, 1);
            }
        },
        clearPastScheduledMessages: (state) => {
            state.data = state.data.filter((item) => new Date() < new Date(item.scheduled_at))
        }
    }
})

export const { setScheduledMessages, addScheduledMessageSlice, updateScheduledMessageSlice, deleteScheduledMessageSlice, clearPastScheduledMessages } = scheduledMessagesSlice.actions

export default scheduledMessagesSlice.reducer