import { Content } from "antd/es/layout/layout";
import { Avatar, Flex, List } from "antd";
import { useSelector } from "react-redux";
import { user } from "../../../store/slices/didUsers.slice";
import { generateNameSpaceColor } from "../../../utils/color";
import { HeadingText } from "../../generic/Typography";
import { useMessageToast } from "../../Layout/DefaultLayout";
import { RootState } from "../../../store/store";

const boxStyle: React.CSSProperties = {
  padding: "5px",
  border: "none",
  position: "relative",
  cursor: "pointer",
};

const ItemContentStyle: React.CSSProperties = {
  width: "100%",
  padding: "5px",
  borderRadius: "10px",
};

const avatarStyle: React.CSSProperties = {
  verticalAlign: "middle",
  color: "black",
  fontWeight: "bold",
};

export const UserCard = ({
  user,
  isModalOpen,
  setIsModalOpen,
  updateConversation,
  conversation,
}: {
  user: user;
  isModalOpen: boolean;
  setIsModalOpen: Function;
  updateConversation: Function;
  conversation: {
    did: string;
    participant: string;
  };
}) => {
  const color = generateNameSpaceColor(user.first_name);
  const { data: conversations } = useSelector(
    (state: RootState) => state.conversation
  );
  const { messageApi } = useMessageToast();

  return (
    <Content key={user.id}>
      <List.Item
        onClick={() => {
          const conv = conversations.find((conv) => {
            return (
              conversation.participant === conv.participant &&
              conversation.did === conv?.did
            );
          });
          if (conv) {
            updateConversation({
              did: conv.did,
              participant: conv.participant,
              assigned_user: user.id,
            });
          }
          messageApi.open({
            type: "loading",
            content: `Assigning to ${user.first_name} ${user.last_name}...`,
            duration: 0.5,
          });
          setTimeout(() => {
            setIsModalOpen(false);
          }, 1500);
        }}
        style={boxStyle}
      >
        <Flex
          align="center"
          justify="flex-start"
          style={{
            ...ItemContentStyle,
          }}
          wrap="wrap"
          gap="small"
        >
          <Avatar style={{ ...avatarStyle, background: color }} size="large">
            {user.first_name[0].toUpperCase()}
          </Avatar>
          <Flex vertical={true}>
            <HeadingText text={user.first_name + " " + user.last_name} />
          </Flex>
        </Flex>
      </List.Item>
    </Content>
  );
};
