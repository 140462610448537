export function formatDateToTime(dateString: Date) {
    const date = new Date(dateString);
    const options:any = {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    };
  
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }
  
  export const formatChatDate = (timestamp: string) => {
    return new Date(timestamp).toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

