import { AutoReplyRule } from "../store/slices/auto_reply_rule";

export const isValidRule = (rule: AutoReplyRule) => {
    if (rule.template == "") {
        throw new Error("Please select template");
      }
    if (rule.rule.label == "") {
        throw new Error("Please enter name");
      }
    if (rule.rule.conditions.length == 0) {
      throw new Error("Please add at least one condition");
    }
    if (rule.rule.conditions.some((condition) => condition.name == "")) {
      throw new Error("Please select condition");
    }
    if (
        rule.rule.conditions.some((condition) => {
          return condition.values.length  == 0
        })
      ) {
        throw new Error("Please Add at least one value");
      }
    if (
      rule.rule.conditions.some((condition) => {
        return condition.values.some((value) => value == "") 
      })
    ) {
      throw new Error("Please enter value");
    }
    return true;
  };