import Table, { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import { Modal, Space, Tag } from "antd";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { useEffect, useState } from "react";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { RootState } from "../../../store/store";
import { RingotelUser, useDeleteRingotelUser, useGetRingotelUsersByAccount } from "../../../hooks/apis/ringotelUsers.api";
import EditRingtelUserModal from "../../Modal/EditRingoteUserModal";
const { confirm } = Modal;


const RingotelUsersTable = ({
    account_id
}: {
    account_id: string
}) => {
    const { users } = useSelector((state: RootState) => state.admin)
    const { accounts } = useSelector((state: RootState) => state.admin)
    const { data: ringotelUsers } = useSelector((state: RootState) => state.ringotelUsers)
    const { refetch: refetchRingotelUsers, isFetching: isLoadingRingotelUsers } = useGetRingotelUsersByAccount(account_id)
    const { mutate: deleteRingotelUserMutate } = useDeleteRingotelUser()
    const [editRingotelUserModal, setEditRingotelUserModal] = useState(false)
    const [editData, setEditData] = useState<RingotelUser | null>(null)


    const showDeleteConfirm = (record: RingotelUser) => {
        confirm({
            title: 'Are you sure you want to delete this account?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteRingotelUserMutate(record.user_id)
            },
        });
    };
    useEffect(() => {
        refetchRingotelUsers()
    }, [account_id])

    const columns: ColumnsType<RingotelUser> = [
        {
            title: 'User Name',
            dataIndex: 'user_id',
            key: 'user_id',
            render: (_, { user_id }) => <a target="_blank" href={`${process.env.REACT_APP_ACCOUNT_URL}/user/${user_id}`}>{`${users?.find((u) => u.id === user_id)?.first_name} ${users?.find((u) => u.id === user_id)?.last_name}`}</a>,
        },
        {
            title: 'Organization',
            dataIndex: 'orgid',
            key: 'orgid',
            render: (_, { orgid }) => <Tag>{orgid}</Tag>,
        },
        {
            title: 'Account',
            dataIndex: 'account_id',
            key: 'account_id',
            render: (_, { account_id }) => <a href={`${process.env.REACT_APP_ACCOUNT_URL}/account/${account_id}`}
                target="_blank"
            >{accounts?.find((a) => a.id === account_id)?.name}
            </a>,
        },
        {
            title: 'Extension',
            dataIndex: 'ext',
            key: 'ext',
            render: (_, { user_ext }) => <Tag>{user_ext}</Tag>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {
                        <CiEdit style={{ cursor: "pointer" }} onClick={() => {
                            setEditData(record)
                            setEditRingotelUserModal(true)
                        }}
                        />
                    }
                    {

                        <MdDeleteOutline onClick={() => {
                            showDeleteConfirm(record)
                        }} style={{ cursor: "pointer" }} />
                    }
                </Space>
            ),
        },
    ];

    return <div>
        {
            editRingotelUserModal && account_id && <EditRingtelUserModal account_id={account_id} setIsModalOpen={setEditRingotelUserModal} isModalOpen={editRingotelUserModal} editData={editData || null} />
        }
        <Table loading={isLoadingRingotelUsers && ringotelUsers.filter(ringotelUser => ringotelUser.account_id === account_id).length === 0} scroll={{ y: 450 }} style={{ width: "100%", height: "500px" }} columns={columns} pagination={ringotelUsers.filter(ringotelUser => ringotelUser.account_id === account_id).length > 10 && { position: ["bottomCenter"] }} dataSource={ringotelUsers.filter(ringotelUser => ringotelUser.account_id === account_id)} />
    </div>
}

export default RingotelUsersTable