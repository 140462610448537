import { Button, Flex, Input, Typography } from "antd"
import { IoSearch } from "react-icons/io5"
import { ScheduledMessagesTable } from "../../components/Tables/ScheduledMessages";
import ModalWrapper from "../../components/generic/modal";
import { useState } from "react";
import { ScheduleForm } from "../../components/Form/ScheduleMessage";

const tableContainerStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    overflow: "auto",
    padding: "10px",
    backgroundColor: "white",
    borderRadius: "10px",
}

const inputSearchStyle: React.CSSProperties = {
    borderRadius: "25px",
    width: "200px",
    border: "none",
};

const searchIconStyle: React.CSSProperties = {
    height: "20px",
    width: "20px",
    color: "#6b7280",
    cursor: "pointer",
};




const ScheduledMessagesList = () => {

    const [addScheduleModal, setAddScheduleModal] = useState(false);

    return <div style={{ padding: "20px" }}>
        {
            addScheduleModal && <ModalWrapper
                title="Schedule Message"
                isModalOpen={addScheduleModal}
                setIsModalOpen={setAddScheduleModal}
            >
                <ScheduleForm setIsModalOpen={setAddScheduleModal} />
            </ModalWrapper>
        }

        <Flex align="center" justify="space-between" >
            <Typography.Title>Scheduled Messages</Typography.Title>
            <Button type="primary" onClick={() => {
                setAddScheduleModal(true)
            }} >Schedule Message</Button>
        </Flex>
        <div style={tableContainerStyle}>
            <Flex gap={10} style={{ marginBottom: "10px" }}  >
                <Input
                    style={inputSearchStyle}
                    size="middle"
                    placeholder="Search"
                    prefix={<IoSearch style={searchIconStyle} />}
                />
            </Flex>
            <ScheduledMessagesTable />
        </div>

    </div>
}

export default ScheduledMessagesList