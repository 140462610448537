import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { BaseUserDid } from "../../interfaces/userDids.type";
import { incomingMessage } from "../../interfaces/messages.type";

export type SelectedDid = string | null;
export interface UserDidsState {
  data: BaseUserDid[];
  selectedDid: SelectedDid;
}

const initialState: UserDidsState = {
  data: [],
  selectedDid: null,
};

export const userDidsSlice = createSlice({
  name: "userDids",
  initialState,
  reducers: {
    /**
     * Reducer function to set the user DIDs list in the store.
     *
     * @param {UserDidsState} state - Current user DIDs state in the store
     * @param {PayloadAction<BaseUserDid[]>} action - Action containing the new user DIDs list
     *
     * @returns {void}
     */
    setUserDids(state, action: PayloadAction<BaseUserDid[]>): void {
      // Map the new user DIDs list to a new array of objects with the "total" and "unread"
      // fields cast to numbers (as they are received as strings from the backend)
      state.data = action.payload.map((userDid) => {
        return {
          ...userDid,
          total: Number(userDid.total),
          unread_messages: Number(userDid.unread_messages),
        };
      });

      // If there is at least one DID, set the first DID as the selected one
      // if (action.payload.length > 0) {
      //   state.selectedDid = action.payload[0].did;
      // }
    },

    updateUserDidCounterByIncomingMessage(state, action: PayloadAction<incomingMessage>): void {
      const index = state.data.findIndex(
        (userDid) => userDid.did === action.payload.did
      );
      if (index !== -1) {
        state.data[index].unread_messages =
          action.payload.direction == "inbound"
            ? state.data[index].unread_messages + 1
            : state.data[index].unread_messages;
      }
    },

    updateUserDidConversationCounter(state, action: PayloadAction<string>): void {
      const index = state.data.findIndex(
        (userDid) => userDid.did === action.payload
      );
      if (index !== -1) {
        state.data[index].unread_conversations = state.data[index].unread_conversations + 1;
      }
    },

    updateUserDidCounterByConversationUpdate(state, action: PayloadAction<{
      did: string;
      count: number;
    }>): void {
      const index = state.data.findIndex(
        (userDid) => userDid.did === action.payload.did
      );
      if (index !== -1) {
        state.data[index].unread_messages = state.data[index].unread_messages - action.payload.count;
        state.data[index].unread_conversations = state.data[index].unread_conversations - 1;
      }
    },

    /**
     * Reducer function to set the selected DID in the store.
     *
     * @param {UserDidsState} state - Current user DIDs state in the store
     * @param {PayloadAction<string | null>} action - Action containing the new selected DID
     *
     * @returns {void}
     */
    setSelectedDid(state, action: PayloadAction<string | null>): void {
      /**
       * Set the new selected DID in the store.
       */
      state.selectedDid = action.payload;
    },
  },
});

export const {
  setUserDids,
  setSelectedDid,
  updateUserDidCounterByIncomingMessage,
  updateUserDidCounterByConversationUpdate,
  updateUserDidConversationCounter
} = userDidsSlice.actions;

export default userDidsSlice.reducer;
