import axios, { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { setDids, addDid as addDidSlice, updateDid as updateDidSlice, deleteDid as deleteDidSlice } from "../../store/slices/admin.slice";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import { useMessageToast } from "../../components/Layout/DefaultLayout";
import { api } from "@data-phone/react-resource-server-auth";



export interface Did {
    did: string;
    account_id: string;
    gateway_id: string;
    description: string | null;
    label: string | null;
    allow_bulk?:boolean
}


export const getDidsByAccountId = async (accountId: string) => {
    const response: AxiosResponse = await api.get(
        `${process.env.REACT_APP_SMS_API_URL}/did/account/${accountId}`,
    );
    return response.data;
}

export const addDid = async (data: Did) => {
    const response: AxiosResponse = await api.post(
        `${process.env.REACT_APP_SMS_API_URL}/did`,
        data,
    );
    return response.data;
}

export const deleteDid = async (did: string, dispatch: AppDispatch) => {
    const response: AxiosResponse = await api.delete(
        `${process.env.REACT_APP_SMS_API_URL}/did/${did}`,
    );
    dispatch(deleteDidSlice(did));
    return response.data;
}

export const editDid = async (did: string, data: Did,dispatch: AppDispatch) => {
    const response: AxiosResponse = await api.put(
        `${process.env.REACT_APP_SMS_API_URL}/did/${did}`,
        data,
    );
    dispatch(updateDidSlice({did: did, data: data}))
    return response.data;
}


export const useGetDidsByAccountId = (accountId: string | null | undefined) => {
    const dispatch: AppDispatch = useDispatch();
    return useQuery(["dids", accountId], () => getDidsByAccountId(accountId as string), {
        enabled: false,
        onSuccess: (data) => {
            dispatch(setDids(data))
        }
    })
}

export const useAddDid = () => {
    const dispatch: AppDispatch = useDispatch();
    const {messageApi} = useMessageToast()
    return useMutation((data: Did) => addDid(data), {
        onSuccess: (data) => {
            dispatch(addDidSlice(data))
        },
        onError:(err:any)=>{
            messageApi.error(err.response.data.error)
        }
    })
}

export const useEditDid = () => {
    const dispatch: AppDispatch = useDispatch();
    return useMutation((data: {
        did: string,
        data: Did
    }) => editDid(data.did, data.data,dispatch), )
}


export const useDeleteDid = () => {
    const dispatch: AppDispatch = useDispatch();
    return useMutation((did: string) => deleteDid(did, dispatch))
}