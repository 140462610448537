import { Button, Flex, Input, Select } from "antd";
import { IoSearch } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useState } from "react";
import ModalWrapper from "../../generic/modal";
import AddDids from "../../Form/Dids";
import { Did } from "../../../hooks/apis/dids.api";
import { usePermissions } from "../../../hooks/permissions";
import { setSelectedView } from "../../../store/slices/admin.slice";
import { useGetAccounts } from "../../../hooks/apis/accounts.api";

const inputSearchStyle: React.CSSProperties = {
    borderRadius: "25px",
    border: "none",
};

const searchIconStyle: React.CSSProperties = {
    height: "20px",
    width: "20px",
    color: "#6b7280",
    cursor: "pointer",
};

const selectStyle: React.CSSProperties = {
    width: "100%",
    marginBottom: "10px",
}

const DidsHeader = ({
    setSearchInput,
    onAccountChange,
    selectedAccount,
    setSelectedDid,
}: {
    setSearchInput: Function;
    onAccountChange: (value: string) => void
    selectedAccount: string
    setSelectedDid: Function
}) => {
    const { data } = useSelector((state: RootState) => state.user);
    const { accounts, dids, selectedView } = useSelector((state: RootState) => state.admin);
    const { isLoading } = useGetAccounts();

    const [addDid, setAddDid] = useState<boolean>(false);
    const [editData, setEditData] = useState<Did | null>(null);
    const { hasPermission } = usePermissions();
    const dispatch: AppDispatch = useDispatch();
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
    };


    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <div >
            {
                addDid && <ModalWrapper
                    setIsModalOpen={setAddDid}
                    isModalOpen={addDid}
                    title={editData ? "Edit Number" : "Add Number"}
                >
                    <AddDids selectedAccount={selectedAccount} editData={editData} setIsModal={setAddDid} />
                </ModalWrapper>
            }
            {hasPermission("sms", "createDids:any") && <Button style={{ width: "100%", marginBottom: "10px", background: "rgb(101, 59, 250)" }} type="primary" onClick={() => {
                setEditData(null)
                setAddDid(true)
            }}>Add Number</Button>
            }
            {hasPermission("sms", ["readTemplates:any", "readTemplates:account"]) && <Button style={{ width: "100%", marginBottom: "10px", background: "rgb(101, 59, 250)" }} type="primary" onClick={() => {
                dispatch(setSelectedView("templates"))
                setSelectedDid(null)
            }}>Templates</Button>}
            {
                hasPermission("accounts", "read:any") && <Select
                    showSearch
                    loading={isLoading}
                    style={selectStyle}
                    placeholder="Select a Account"
                    optionFilterProp="children"
                    disabled={accounts.length == 0}
                    value={isLoading ? "" : selectedAccount}
                    onChange={onAccountChange}
                    filterOption={filterOption}
                    options={accounts.map((account) => ({
                        label: account.name,
                        value: account.id,
                    }))}
                />
            }

            {dids.filter((did) => did.account_id == selectedAccount).length > 1 &&
                <Input
                    onChange={handleSearch}
                    style={inputSearchStyle}
                    size="middle"
                    placeholder="Search"
                    prefix={<IoSearch style={searchIconStyle} />}
                />}
        </div>
    );
};

export default DidsHeader;
