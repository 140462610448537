import React from "react";
import RingotelOrgForm from "../Form/RingotelOrgs";
import ModalWrapper from "../generic/modal";
const AddRingtelOrganizationModal = ({
  setIsModalOpen,
  isModalOpen,
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
}) => {
  return (
    <ModalWrapper
      title="Add Organization"
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <RingotelOrgForm editData={null} setIsModal={setIsModalOpen} />
    </ModalWrapper>
  );
};

export default AddRingtelOrganizationModal;
