import { Button, Flex, Input, Select, Typography } from "antd"
import { IoSearch } from "react-icons/io5"
import BulkMessagesTable from "../../components/Tables/BulkMessages"
import { useNavigate } from "react-router-dom";

const tableContainerStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    overflow: "auto",
    padding: "10px",
    backgroundColor: "white",
    borderRadius: "10px",
}

const inputSearchStyle: React.CSSProperties = {
    borderRadius: "25px",
    width: "200px",
    border: "none",
};

const searchIconStyle: React.CSSProperties = {
    height: "20px",
    width: "20px",
    color: "#6b7280",
    cursor: "pointer",
};



const BulkMessagesList = () => {
    const navigate = useNavigate();
 
    return <div style={{ padding: "20px" }}>
        <Flex align="center" justify="space-between" >
        <Typography.Title>Bulk Messages</Typography.Title>
        <Button type="primary" onClick={() => {
            navigate("/send-bulk")
         }} >Create Bulk Message</Button>
        </Flex>
        <div style={tableContainerStyle}>
            <Flex gap={10} style={{ marginBottom: "10px" }}  >
                <Input
                    style={inputSearchStyle}
                    size="middle"
                    placeholder="Search"
                    prefix={<IoSearch style={searchIconStyle} />}
                />
            </Flex>
            <BulkMessagesTable />
        </div>

    </div>
}

export default BulkMessagesList