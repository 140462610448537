import { Button, Flex, Typography } from "antd";
import { useEffect, useState } from "react";
import AddRingtelOrganizationModal from "../../components/Modal/AddOrganizationModal";
import RingtelOrgs from "../../components/Admin/Tables/RingtelOrgs";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useGetAccounts } from "../../hooks/apis/accounts.api";
import { useGetRingotelOrganizations } from "../../hooks/apis/ringotel.api";

const RingotelOrgs = () => {
    const { accounts} = useSelector((state: RootState) => state.admin)
    const { data: ringotelOrgs} = useSelector((state: RootState) => state.ringotelOrgs)

    const [addRingtelOrgModal, setAddRingtelOrgModal] = useState(false)
    const navigate: NavigateFunction = useNavigate()

    const { refetch: fetchAccounts } = useGetAccounts();
    const { refetch: refetchRingotelOrganization } = useGetRingotelOrganizations()

    useEffect(() => {
        if (accounts.length == 0) {
            fetchAccounts()
        }
        if(ringotelOrgs.length == 0){
            refetchRingotelOrganization()
        }
    }, [])

    return <div style={{ padding: "20px" }}>
        {
            addRingtelOrgModal && <AddRingtelOrganizationModal setIsModalOpen={setAddRingtelOrgModal} isModalOpen={addRingtelOrgModal} />
        }
        <Flex justify="space-between" align='center' style={{ padding: "10px" }}>
            <Typography.Title>{`Ringtel Organizations`}</Typography.Title>{" "}
            {
                <Flex gap={10}>
                <Button type="primary" onClick={() => {
                    setAddRingtelOrgModal(true)
                }}>Add Organization</Button>
                     <Button type="primary" onClick={() => {
                    navigate("/ringotel-users")
                }}>Ringotel Users</Button>
                </Flex>
            }
        </Flex>
        <RingtelOrgs searchInput="" />
    </div>;
};
export default RingotelOrgs