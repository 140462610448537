import { Modal, Space, Spin, Table, Tag } from 'antd';
import type { TableProps } from 'antd';
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { LoadingOutlined } from "@ant-design/icons";
import {  useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RingotelOrg, useDeleteRingtelOrganization, useGetRingotelOrganizations } from '../../../hooks/apis/ringotel.api';
import { RootState } from '../../../store/store';
import EditRingtelOrgModal from '../../Modal/EditRingtelOrgModal';
const { confirm } = Modal;



type ColumnsType<T extends object> = TableProps<T>['columns'];




const RingtelOrgs = ({
    searchInput
}: {
    searchInput: string
}) => {
    const [editData, setEditData] = useState<RingotelOrg | null>(null)
    const {data:ringtelOrgs} = useSelector((state: RootState) => state.ringotelOrgs)
    const {accounts} = useSelector((state: RootState) => state.admin)
    const { isLoading: isLoadingGetRingotelOrganizations } = useGetRingotelOrganizations()
    const { mutate: deleteRingotelOrgMutate} = useDeleteRingtelOrganization()
    const [editRingotelOrgModal, setEditRingotelOrgModal] = useState(false)


    

    const showDeleteConfirm = (record: RingotelOrg) => {
        confirm({
            title: 'Are you sure you want to delete this account?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
               deleteRingotelOrgMutate(record.orgid)
            },
           
        });
    };
    const columns: ColumnsType<RingotelOrg> = [
        {
            title: 'Org Id',
            dataIndex: 'orgid',
            key: 'orgid',
            render: (_, { orgid}) => <Tag>{orgid}</Tag>,
        },
        {
            title: 'Account',
            dataIndex: 'account_id',
            key: 'account_id',
            render: (_, { account_id }) => <a href={`${process.env.REACT_APP_ACCOUNT_URL}/account/${account_id}`} target="_blank" >{accounts?.find(account => account.id === account_id)?.name}</a>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {
                        <CiEdit style={{ cursor: "pointer" }} onClick={() => {
                            setEditData(record)
                            setEditRingotelOrgModal(true)
                        }}
                        />
                    }
                    {
                        isLoadingGetRingotelOrganizations ?
                            <Spin
                                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                            />
                            :
                            <MdDeleteOutline onClick={() => {
                                showDeleteConfirm(record)
                             }} style={{ cursor: "pointer" }}/>
                    }
                </Space>
            ),
        },
    ];

    return (
        <div>
            {
                editRingotelOrgModal && <EditRingtelOrgModal setIsModalOpen={setEditRingotelOrgModal} isModalOpen={editRingotelOrgModal} editData={editData} />
            }
            <Table loading={isLoadingGetRingotelOrganizations}  scroll={{ y: 450 }} style={{ width: "100%", height: "500px" }} columns={columns} pagination={{ position: ["bottomCenter"] }} dataSource={ringtelOrgs}/>
        </div>
    );
};

export default RingtelOrgs;