import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const Loader: React.FC = () => (
  <Spin
    style={{ width: "100%" ,margin:"auto"}}
    indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
  />
);

export default Loader;
