import { fetchFile } from "@ffmpeg/util";
import { Media } from "../interfaces/messages.type";
export const amrTomp3 = async (ffmpegRef: any, url:string) => {
  try {
    const ffmpeg = ffmpegRef.current;
    await ffmpeg.writeFile("input.amr", await fetchFile(url));
    await ffmpeg.exec(["-i", "input.amr", "output.mp3"]);
    const data: any = await ffmpeg.readFile("output.mp3");
    if (data.buffer) {
      return URL.createObjectURL(
        new Blob([data.buffer], { type: "audio/mp3" })
      );
    }
  } catch (error) {
    throw error;
  }
};
