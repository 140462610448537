import { useDispatch, useSelector } from "react-redux";
import { BaseMessage } from "../../interfaces/messages.type";
import { AppDispatch, RootState } from "../../store/store";
import { MessageData, setMessages } from "../../store/slices/messages.slice";
import {  useMutation } from "react-query";
import { api } from "@data-phone/react-resource-server-auth";
interface messagesQuery {
  did: string;
  participant: string;
  limit: number;
  offset: number;
  account?: string | null;
}


const fetchData = async (
  query: messagesQuery,
  messages: MessageData[],
  dispatch: AppDispatch
): Promise<{ messages: BaseMessage[], offset: number }> => {
  if (query.offset == 0) {
    const response = await api.get(
      `${process.env.REACT_APP_SMS_API_URL}/sms/messages?did=${encodeURIComponent(query.did)
      }&participant=${encodeURIComponent(query.participant)}&limit=${query.limit
      }&offset=${query.offset}${query.account ? `&account=${query.account}` : ""}`,
    );
    if (response.data) {
      dispatch(setMessages(response.data.map((msg: BaseMessage, index: number) => {
        return {
          ...msg,
          position:query.offset + index
        }
      })));
    }
    return {
      messages: response.data,
      offset: query.offset
    };
  } else {
    const data = messages.find(
      (msg) =>
        msg.did === query.did && msg.participant === query.participant
    );
  
      const response = await api.get(
        `${process.env.REACT_APP_SMS_API_URL}/sms/messages?did=${encodeURIComponent(query.did)}&participant=${encodeURIComponent(query.participant)}&limit=${20
        }&offset=${query.offset}${query.account ? `&account=${query.account}` : ""}`,
      );
      if (response.data) {
        dispatch(setMessages(response.data.map((msg: BaseMessage, index: number) => {
          return {
            ...msg,
            position:query.offset + index
          }
        })));
      }

      return {
        messages: response.data,
        offset: query.offset
      }
  }
};

const useGetMessages = () => {
  const dispatch: AppDispatch = useDispatch();
  const messages: MessageData[] = useSelector((state: RootState) => state.messages.data);

  return useMutation<{messages: BaseMessage[], offset: number}, Error, messagesQuery>((query) => fetchData(query, messages, dispatch),{mutationKey:["messages"]});

};

export default useGetMessages;
