import { useEffect, useState } from "react";
import { useGetAccountById, useGetAccounts } from "../../hooks/apis/accounts.api";
import { Col, Row, Typography } from "antd";
import DidsContainer from "../../components/Admin/Dids/DidsContainer";
import { Did } from "../../hooks/apis/dids.api";
import UserDidsList from "../../components/Admin/Dids/UserDidsList";
import { useGetAllUsers } from "../../hooks/apis/user.api";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import AddAutoReplyRules from "../../components/Admin/addAutoReplyRules";
import DidInformation from "../../components/Admin/Dids/DidInformation";
import Templates from "../../components/Admin/Templates";
import { usePermissions } from "../../hooks/permissions";
const { Text } = Typography


const rowStyle: React.CSSProperties = {
    height: "100%",
};

const DidsPage = () => {
    const { data } = useSelector((state: RootState) => state.user)
    const { refetch: fetchAccounts, isSuccess: accountFetchSuccess } = useGetAccounts();
    const { refetch: fetchAllUsers } = useGetAllUsers()
    const [selectedDid, setSelectedDid] = useState<Did | null>(null);
    const { selectedView,selectedAccount } = useSelector((state: RootState) => state.admin)
    const { hasPermission } = usePermissions()
    useEffect(() => {
        if (hasPermission("accounts", "read:any")) {
            fetchAccounts()
        }
        fetchAllUsers()
    }, [])
   

    return <Row style={rowStyle}>
        <Col
            span={5}
        >
            <DidsContainer selectedDid={selectedDid} setSelectedDid={setSelectedDid} />
        </Col>
        <Col style={{
            height: "100%",
        }} span={19}>
            {
                selectedView === "templates" && selectedDid === null && <Templates account={selectedAccount} />
            }
            {
                selectedDid && selectedView === "userDids" &&
                <>
                    <UserDidsList selectedAccount={selectedAccount} selectedDid={selectedDid.did} />
                </>
            }
            {
                selectedDid && selectedView === "rules" &&
                <>
                    <AddAutoReplyRules did={selectedDid.did} />
                </>
            }
            {
                selectedDid && (selectedView === "" || selectedView === "info") &&
                <>
                    <DidInformation selectedAccount={selectedAccount} setSelectedDid={setSelectedDid} did={selectedDid} />
                </>
            }

        </Col>

    </Row>
}

export default DidsPage