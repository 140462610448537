export async function convertToBase64Array(
  audioArray: { key: string; audio: string }[],
) {
  const base64Array = [];

  for (const audioObj of audioArray) {
    const audioBlobUrl = audioObj.audio;

    // Extract file extension from the URL
    const fileExtension = audioBlobUrl.split('.').pop();

    try {
      // Fetch the audio blob
      const response = await fetch(audioBlobUrl);
      const blob = await response.blob();

      // Convert the audio blob to base64
      const base64Promise = new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });

      const base64Audio = await base64Promise;

      // Add the base64 audio along with file extension to the array
      base64Array.push(base64Audio);
    } catch (error) {
      console.error(
        `Error converting audio blob for key ${audioObj.key}:`,
        error,
      );
    }
  }

  return base64Array;
}
