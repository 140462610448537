import { useParams } from "react-router-dom";
import { ScheduledMessage, useDeleteScheduledMessage, useGetScheduledMessages } from "../../hooks/apis/scheduled_messages.api";
import Table, { ColumnsType } from "antd/es/table";
import { Modal, Space, Typography } from "antd";
import { formatPhoneNumber } from "../../utils/phoneLib";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useGetUserDids } from "../../hooks/apis/userDids";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useMessageToast } from "../Layout/DefaultLayout";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import ModalWrapper from "../generic/modal";
import { ScheduleForm } from "../Form/ScheduleMessage";
import { clearPastScheduledMessages, deleteScheduledMessageSlice } from "../../store/slices/scheduled_messages.slice";

const { confirm } = Modal;

export const ScheduledMessagesTable = () => {

    const { page } = useParams();
    const { messageApi } = useMessageToast();
    const dispatch:AppDispatch = useDispatch()

    const { data: userDids } = useSelector((state: RootState) => state.userDids)
    const { data: scheduledMessagesData } = useSelector((state: RootState) => state.scheduledMessages)

    const [pageSize] = useState(20);
    const [editData, setEditData] = useState<ScheduledMessage | null>(null)
    const [editScheduledMessageModal, setEditScheduledMessageModal] = useState(false)

    const { isLoading, refetch: refetchScheduledMessages, data: scheduledMessages } = useGetScheduledMessages({
        offset: page ? (parseInt(page) - 1) * pageSize : 0,
        limit: pageSize
    })
    const { mutate: deleteScheduledMessage } = useDeleteScheduledMessage()
    const { refetch: getUserDids } = useGetUserDids(null);

    useEffect(() => {
        refetchScheduledMessages()
    }, [page])

    useEffect(() => {
        if (userDids.length === 0) getUserDids()
    }, [])

    useEffect(()=>{
        const intervalId = setInterval(() => {
            dispatch(clearPastScheduledMessages())
        },30000)

        return () => clearInterval(intervalId)
    },[])

    const showDeleteConfirm = (id: string) => {
        confirm({
            title: 'Are you sure you want to delete this message?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteScheduledMessage(id, {
                    onSuccess() {
                        messageApi.success("Message deleted successfully")
                        dispatch(deleteScheduledMessageSlice(id))
                    },
                })
            },
        });
    };


    const columns: ColumnsType<ScheduledMessage> = [
        {
            title: "From",
            dataIndex: "did",
            align: "center",
            key: "did",
            render: (_, { did }) => <Typography.Text style={{ fontWeight: "500" }}>{formatPhoneNumber(did)}</Typography.Text>,
        },
        {
            title: "To",
            dataIndex: "participant",
            align: "center",
            key: "participant",
            render: (_, { participant }) => <Typography.Text style={{ fontWeight: "500" }}>{formatPhoneNumber(participant)}</Typography.Text>,
        },
        {
            title: "Body",
            dataIndex: "body",
            align: "center",
            key: "body",
            render: (_, { body }) => <Typography.Text style={{ fontWeight: "500" }}>{body}</Typography.Text>,
        },
        {
            title: "Scheduled At",
            dataIndex: "scheduled_at",
            align: "center",
            key: "scheduled_at",
            render: (_, { scheduled_at }) => <Typography.Text style={{ fontWeight: "500" }}>{dayjs(scheduled_at).format("DD MMM YYYY hh:mm A")}</Typography.Text>,
        },
        {
            title: "Action",
            dataIndex: "id",
            align: "center",
            key: "id",
            render: (_, record) => (
                <Space size="middle">
                    {
                        <CiEdit style={{ cursor: "pointer" }} onClick={() => {
                            setEditData(record)
                            setEditScheduledMessageModal(true)
                        }}
                        />
                    }
                    {

                        <MdDeleteOutline onClick={() => {
                            showDeleteConfirm(record.id as string)
                        }} style={{ cursor: "pointer" }} />
                    }
                </Space>
            ),
        }
    ]


    return <div>
        {
            editScheduledMessageModal && editData &&
            <ModalWrapper
                title="Edit Scheduled Message"
                isModalOpen={editScheduledMessageModal}
                setIsModalOpen={setEditScheduledMessageModal}
            >
                <ScheduleForm editData={editData} setIsModalOpen={setEditScheduledMessageModal} />
            </ModalWrapper>
        }
        <Table
            dataSource={scheduledMessagesData}
            columns={columns}
            loading={isLoading}
            pagination={{
                defaultPageSize: 20,
                total: scheduledMessagesData.length + 1,
                pageSize: 20,
                showSizeChanger: true,
                current: page ? parseInt(page) : 1
            }}
        />
    </div>

}