import { Button, Flex, Form, Input, Switch } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { MdOutlineEmail } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import useAddContactFormHandler from "../../handlers/addContact";
import { formatPhoneNumber, phoneNumberValidate } from "../../utils/phoneLib";
import { parsePhoneNumber } from "libphonenumber-js";
import { getFlag } from "../../utils/flag";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { useAddContact } from "../../hooks/apis/contacts.api";
import { useMessageToast } from "../Layout/DefaultLayout";
import { addContact } from "../../store/slices/contact.slice";

const formStyle: React.CSSProperties = {
    padding: "20px",
};
const formInputStyle: React.CSSProperties = {
    marginRight: "10px",
    width: "-webkit-fill-available",
};

const plusButtonStyle: React.CSSProperties = {
    background: "rgb(101, 59, 250)",
};
const plusIconStyle: React.CSSProperties = {
    height: "20px",
    width: "20px",
    marginTop: "2px",
    color: "white",
    cursor: "pointer",
};

const AddContactForm = ({ setIsModalOpen }: { setIsModalOpen: Function }) => {
    const [form] = Form.useForm();
    const { messageApi } = useMessageToast();
    const { mutate: addContactMutate, isLoading: isLoadingAddContact } = useAddContact()
    const dispatch: AppDispatch = useDispatch();
    const {
        emails,
        numbers,
        isPrivateContact,
        setIsPrivateContact,
        handleEmailChange,
        handleAddEmail,
        handleAddNumber,
        handleRemoveEmail,
        handleRemoveNumber,
        handleNumberChange,
    } = useAddContactFormHandler(form);

    const onFinish = (values: any) => {
        let contactData = {
            provider_id: null,
            is_private: isPrivateContact,
            first_name: values.first_name,
            middle_name: values.middle_name,
            last_name: values.last_name,
            phones: numbers.map((num) => {
                return { phone_number: num };
            }),
            emails: emails.map((email) => {
                return { email: email };
            }),
            addresses: [],
        };
        addContactMutate(contactData, {
            onSuccess: (data) => {
                messageApi.success("Contact added successfully")
                dispatch(addContact(data))
                setIsModalOpen(false)
            }
        })

        form.resetFields();
    };

    return (
        <Form style={formStyle} form={form} onFinish={onFinish}>
            <Form.Item
                name="first_name"
                style={formInputStyle}
                rules={[{ required: true, message: "Please input your first name!" }]}
            >
                <Input
                    size="large"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="First Name"
                />
            </Form.Item>
            <Form.Item
                name="middle_name"
                style={formInputStyle}
                rules={[{ required: false }]}
            >
                <Input
                    size="large"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Middle Name"
                />
            </Form.Item>
            <Form.Item
                name="last_name"
                style={formInputStyle}
                rules={[{ required: false}]}
            >
                <Input
                    size="large"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Last Name"
                />
            </Form.Item>
            {emails.map((email, index) => (
                <Flex>
                    <Form.Item
                        key={index}
                        name="email"
                        style={formInputStyle}
                        rules={[{ required: false, message: "Please input your email" }]}
                    >
                        <Input
                            size="large"
                            value={email}
                            onChange={(e) => handleEmailChange(index, e.target.value)}
                            prefix={<MdOutlineEmail className="site-form-item-icon" />}
                            placeholder="Email"
                        />
                    </Form.Item>
                    {index === 0 ? (
                        <Button
                            type="primary"
                            onClick={handleAddEmail}
                            style={plusButtonStyle}
                            size="large"
                            shape="circle"
                            icon={<FaPlus style={plusIconStyle} />}
                        />
                    ) : (
                        <Button
                            type="primary"
                            onClick={() => handleRemoveEmail(index)}
                            style={plusButtonStyle}
                            size="large"
                            shape="circle"
                            icon={<FaMinus style={plusIconStyle} />}
                        />
                    )}
                </Flex>
            ))}

            {numbers.map((number, index) => {
                let countryFlag = getFlag("US");
                let flag = "US";
                try {
                    const phoneNumber = parsePhoneNumber(number, 'US');
                    if (phoneNumber?.country) {
                        countryFlag = getFlag(phoneNumber.country)
                        flag = phoneNumber.country
                    }
                } catch (error) {
                    countryFlag = getFlag("US")
                    flag = "US"
                }
                return (
                    <Flex>
                        <Form.Item
                            key={index}
                            name={`phoneNumber${index}`}
                            style={formInputStyle}
                            initialValue={flag == "US" ? formatPhoneNumber(number.replace(/ /g, '')) : number.replace(/ /g, '')}
                            rules={[{
                                required: false, validator(rule, value) {
                                    return new Promise((resolve, reject) => {
                                        if (!value || value.length == 0) {
                                            reject("Please input your phone number")
                                        }
                                        if (!phoneNumberValidate(value)) {
                                            reject("Invalid phone number")
                                        }
                                        resolve(value)
                                    })
                                }, validateTrigger: "onBlur"
                            }]}
                        >

                            <Input placeholder="Enter Phone Number"
                                defaultValue={flag == "US" ? formatPhoneNumber(number.replace(/ /g, '')) : number.replace(/ /g, '')}
                                value={flag == "US" ? formatPhoneNumber(number.replace(/ /g, '')) : number.replace(/ /g, '')}
                                onChange={(event) => {
                                    try {
                                        const phoneNumber = parsePhoneNumber(event.target.value, 'US');
                                        if (phoneNumber?.isValid()) {
                                            handleNumberChange(index, phoneNumber.number)
                                            form.setFieldValue(`phoneNumber${index}`, flag == "US" ? phoneNumber.formatNational() : phoneNumber.number)
                                        }
                                        else {
                                            handleNumberChange(index, event.target.value)
                                        }
                                    } catch (error) {
                                        handleNumberChange(index, event.target.value)
                                    }
                                }} addonBefore={<img style={{ height: "20px", width: "30px" }} src={countryFlag}></img>} />
                        </Form.Item>
                        {index === 0 ? (
                            <Button
                                type="primary"
                                onClick={handleAddNumber}
                                style={plusButtonStyle}
                                size="large"
                                shape="circle"
                                icon={<FaPlus style={plusIconStyle} />}
                            />
                        ) : (
                            <Button
                                type="primary"
                                onClick={() => handleRemoveNumber(index)}
                                style={plusButtonStyle}
                                size="large"
                                shape="circle"
                                icon={<FaMinus style={plusIconStyle} />}
                            />
                        )}
                    </Flex>
                )
            }
            )}
            <Form.Item>
                <Switch
                    checkedChildren="Private"
                    onChange={() => setIsPrivateContact(!isPrivateContact)}
                    unCheckedChildren="Public"
                    defaultChecked={isPrivateContact}
                />
            </Form.Item>
            <Form.Item >
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    disabled={isLoadingAddContact}
                    loading={isLoadingAddContact}
                >
                    Submit
                </Button>
            </Form.Item>

        </Form>
    );
};

export default AddContactForm;
