import { Button, Form, FormProps, Input, Select, Switch } from "antd"
import { Did, useAddDid, useEditDid } from "../../hooks/apis/dids.api"
import { useMessageToast } from "../Layout/DefaultLayout"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import parsePhoneNumber from "libphonenumber-js"
import { formatPhoneNumber } from "../../utils/phoneLib"
import { usePermissions } from "../../hooks/permissions"

const DidForm = ({
    setIsModal,
    editData,
    setSelectedDid,
    selectedAccount
}: {
    setIsModal: React.Dispatch<React.SetStateAction<boolean>>
    editData: Did | null
    setSelectedDid?: Function
    selectedAccount: string
}) => {
    const [flagIcon, setFlagIcon] = useState<string>("US")
    const [number, setNumber] = useState<string>("")
    const [selectedCarrier, setSelectedCarrier] = useState<string | null>(null)
    const { messageApi } = useMessageToast();
    const { mutate: addDidMutate, isLoading: isLoadingAddDid, isSuccess: isSuccessAddDid } = useAddDid()
    const { mutate: updateDidMutate, isLoading: isLoadingUpdateDid, isSuccess: isSuccessUpdateDid } = useEditDid()
    const { accounts, carriers } = useSelector((state: RootState) => state.admin)
    const { hasPermission } = usePermissions()

    const [form] = Form.useForm();
    useEffect(() => {
        form.resetFields()
    }, [])

    const handleCarrierChange = (value: string) => {
        setSelectedCarrier(value)
        form.setFieldValue("gateway_id", undefined)
    }

    useEffect(() => {
        if (editData) {
            setSelectedCarrier(carriers.find((carrier) => carrier.gateways.some((gateway) => gateway.id == editData?.gateway_id))?.name as string)
            form.setFieldsValue({
                ...editData,
                carrier: carriers.find((carrier) => carrier.gateways.some((gateway) => gateway.id == editData?.gateway_id))?.name,
                gateway_id: editData.gateway_id
            })
            setNumber(editData.did)
        } else {
            form.setFieldValue("account_id", selectedAccount)
        }
    }, [editData])

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const onFinish: FormProps<Did>["onFinish"] = (values) => {
        const did: Did = {
            gateway_id: values.gateway_id,
            account_id: values.account_id,
            did: number,
            description: values.description,
            label: values.label,
        }
        if (hasPermission("sms", ["didSetBulkSMS:any"])) {
            did.allow_bulk = values.allow_bulk
        }
        if (editData) {
            updateDidMutate({ did: editData.did, data: did })
        }
        else {
            addDidMutate(did)
        }
    }

    useEffect(() => {
        if (isSuccessAddDid) {
            messageApi.success("DID added successfully")
            setIsModal(false)
        }
    }, [isSuccessAddDid])

    useEffect(() => {
        if (isSuccessUpdateDid) {
            messageApi.success("DID updated successfully")
            if (setSelectedDid) {
                setSelectedDid(null)
            }
            setIsModal(false)
        }
    }, [isSuccessUpdateDid])

    return <Form
        style={{ padding: "10px" }}
        name="basic"
        form={form}
        labelCol={{ span: 4 }}
        onFinish={onFinish}
    >
        <Form.Item
            label="Label"
            name="label"
        >
            <Input placeholder="Label" />
        </Form.Item>
        <Form.Item
            label="Carrier"
            name="carrier"
            rules={[{ required: true, message: "Please Select Carrier" }]}
        >
            <Select
                placeholder="Select Carrier"
                optionFilterProp="children"
                onChange={handleCarrierChange}
                options={carriers.filter(carrier => carrier.enabled).map(carrier => {
                    return {
                        label: carrier.name,
                        value: carrier.name
                    }
                })}
            />
        </Form.Item>
        <Form.Item<Did>
            label="Gateway"
            name="gateway_id"
            rules={[{ required: true, message: "Please Select Gateway" }]}
        >
            <Select
                placeholder="Select Gateway"
                optionFilterProp="children"
                disabled={!selectedCarrier}
                options={carriers.find(carrier => carrier.name === selectedCarrier)?.gateways.map(gateway => {
                    return {
                        label: gateway.name,
                        value: gateway.id
                    }
                })}
            />
        </Form.Item>

        <Form.Item<Did>
            label="Number"
            name="did"
            initialValue={editData?.did}
            rules={[{ required: true, validateTrigger: "onFinish", validator: () => parsePhoneNumber(number)?.isValid() ? Promise.resolve() : Promise.reject("Invalid phone number") }]}
        >
            <Input placeholder="Enter DID"
                value={formatPhoneNumber(number.replace(/ /g, ''))}
                onChange={(event) => {
                    const phoneNumber = parsePhoneNumber(event.target.value, 'US');
                    if (phoneNumber?.country) {
                        setFlagIcon(phoneNumber.country)
                    }
                    else {
                        setFlagIcon("US")
                    }
                    if (phoneNumber?.isValid()) {
                        setNumber(phoneNumber.number)
                        form.setFieldValue("did", phoneNumber.formatNational())
                    }
                    else {
                        setNumber(event.target.value)
                    }
                }} addonBefore={<img style={{ height: "20px", width: "30px" }} src={`https://catamphetamine.gitlab.io/country-flag-icons/3x2/${flagIcon}.svg`}></img>} />
        </Form.Item>
        <Form.Item<Did>
            label="Account"
            name="account_id"
            rules={[{ required: true, message: "Please Select Account" }]}
        >
            <Select
                showSearch
                placeholder="Select Account"
                optionFilterProp="children"
                filterOption={filterOption}
                options={accounts.map(account => {
                    return {
                        label: account.name,
                        value: account.id
                    }
                })}
            />
        </Form.Item>
        {
            hasPermission("sms", ["didSetBulkSMS:any"]) &&
            <Form.Item
                valuePropName="checked" name="allow_bulk" label="Bulk SMS"
            >
                <Switch />
            </Form.Item>

        }

        <Form.Item<Did>
            label="Description"
            name="description"
        >
            <Input.TextArea placeholder="DID Description" />
        </Form.Item>
        <Form.Item>
            <Button
                style={{ width: "100%" }}
                type="primary"
                disabled={isLoadingAddDid || isLoadingUpdateDid}
                loading={isLoadingAddDid || isLoadingUpdateDid}
                htmlType="submit"
            >
                Save
            </Button>
        </Form.Item>


    </Form>

}

export default DidForm