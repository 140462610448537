import { useState, useEffect } from 'react';
import { deleteAudio } from '../../handlers/recording';

import { Audio } from '../../interfaces/recorder.type';
import generateKey from '../../utils/generate-key';

export default function useRecordingsList(audio: string | null) {
  const [recordings, setRecordings] = useState<Audio[]>([]);

  useEffect(() => {
    if (audio) setRecordings([{ key: generateKey(), audio }]);
  }, [audio]);

  return {
    recordings,
    deleteAudio: (audioKey: string) => deleteAudio(audioKey, setRecordings),
  };
}
