import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ContactData {
  id?: string;
  account_id: string;
  user_id: string;
  first_name: string;
  last_name?: string;
  middle_name?: string;
  addresses: Address[];
  phones: Phone[];
  emails: Email[];
  is_private: boolean;
  provider_id: string;
  contact_key?: Number;
  updated_at?: string;
}


export interface Address {
  address1: string;
  address2?: string;
  city?: string;
  ZIP?: string;
}

export interface Phone {
  phone_number: string;
}

export interface Email {
  email: string;
}


export interface ContactsState {
  data: ContactData[];
}

const initialState: ContactsState = {
  data: [],
};

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setContacts: (state, action: PayloadAction<ContactData[]>) => {
      if(action.payload.length > 0){
        const contacts = state.data.filter((item) => item.id !== action.payload[0].id)
        state.data = [...contacts, ...action.payload]
      }
    },
    addContact: (state, action: PayloadAction<any>) => {
      state.data.push(action.payload)
    },
    updateContact: (state, action: PayloadAction<any>) => {
      state.data = state.data.map((contact) => contact.id == action.payload.id ? action.payload : contact)  
    }
  },
});

export const { setContacts,addContact ,updateContact} = contactsSlice.actions;

export default contactsSlice.reducer;