import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import axios, { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import {
  TemplateType,
  addTemplateSlice,
  setTemplates,
  updateTemplateSlice,
} from "../../store/slices/templates.slice";
import { api } from "@data-phone/react-resource-server-auth";


const fetchTemplatesByAccountApi = async (
  account: string,
  dispatch: AppDispatch
): Promise<TemplateType[]> => {
  const response: AxiosResponse<TemplateType[]> = await api.get(
    `${process.env.REACT_APP_SMS_API_URL}/templates/account/${account}`,
  );
  if (response.data) {
    dispatch(setTemplates(response.data));
  }
  return response.data;
};
const addTemplateApi = async (
  data: TemplateType,
  dispatch: AppDispatch
): Promise<TemplateType> => {
  const response: AxiosResponse<TemplateType> =
    await api.post(`${process.env.REACT_APP_SMS_API_URL}/templates`, data);
  dispatch(addTemplateSlice(response.data));
  return response.data;
};
const updateTemplateById = async (
  id: string,
  data: TemplateType,
  dispatch: AppDispatch
): Promise<TemplateType> => {
  const response = await api.put<{ data: TemplateType }>(
    `${process.env.REACT_APP_SMS_API_URL}/templates/${id}`,
    data
  );
  dispatch(updateTemplateSlice(data));
  return response.data;
};

export const useGetTemplatesByAccount = (account: string) => {
  const dispatch: AppDispatch = useDispatch();
  return useQuery<any[], Error>(
    "templates",
    () => fetchTemplatesByAccountApi(account, dispatch),
    {
      enabled: false,
      onError: (error) => {
        console.log("failed", error);
      },
    }
  );
};

export const useAddTemplate = () => {
  const dispatch: AppDispatch = useDispatch();
  return useMutation((data: any) => addTemplateApi(data, dispatch), {
    onSuccess: () => {
      console.log("success");
    },
    onError: () => {
      console.log("failed");
    },
  });
};

export const useUpdateTemplate = (id: string) => {
  const dispatch: AppDispatch = useDispatch();
  return useMutation((data: any) => updateTemplateById(id, data, dispatch), {
    onSuccess: () => {
      console.log("success");
    },
    onError: () => {
      console.log("failed");
    },
  });
};
