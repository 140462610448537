import { List } from "antd";
import { Did } from "../../../hooks/apis/dids.api";
import DidItem from "./DidItem";

const DidsList = (
    { dids, setCurrentDid ,selectedDid,setSelectedDid ,selectedAccount}:
    {
        dids: Did[],
        setSelectedDid:Function
        setCurrentDid: Function
        selectedDid: Did | null
        selectedAccount: string
    }) => {
    return (
        <List
            itemLayout="horizontal"
            dataSource={dids}
            renderItem={(did: Did, index: number) => (
                <DidItem
                    did={did}
                    selectedAccount={selectedAccount}
                    setSelectedDid={setSelectedDid}
                    selectedDid={selectedDid}
                    onClick={() => setCurrentDid(did)}
                />
            )}
        />
    )

}

export default DidsList