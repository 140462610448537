
import MessagesPage from "../pages/messages";
import DidsPage from "../pages/Admin/Dids";
import PageNotFound from "../pages/404";
import SendBulkMessagePage from "../pages/BulkMessages";
import RingotelOrgPage from "../pages/Admin/RingotelOrg"
import RingotelUsersPage from "../pages/Admin/RingotelUsers"
import BulkMessagesList from "../pages/BulkMessages/bulkMessagesList";
import BulkMessageStatusList from "../pages/BulkMessages/BulkMessageStatusList";
import ScheduledMessagesList from "../pages/ScheduledMessage";


const coreRoutes = [
  {
    path: "/",
    title: "Message",
    component: MessagesPage,
    permissions: null,
  },
  {
    path: "/send-bulk",
    title: "Bulk",
    component: SendBulkMessagePage,
    permissions: { type: "sms", permissions: ["sendBulkSms:any", "sendBulkSms:account", "sendBulkSms:own"] },
  },
  {
    path:"scheduled-messages",
    title: "Scheduled",
    component:ScheduledMessagesList,
    permissions:null
  },
  {
    path:'/bulk/:page',
    title: "Bulk",
    component:BulkMessagesList,
    permissions:null
  },
  {
    path: "/bulk/message/:id",
    title: "Bulk",
    component: BulkMessageStatusList,
    permissions:null
  },
  {
    path: "/dids",
    title: "Dids",
    component: DidsPage,
    permissions: {type:"sms",permissions:["readDids:any", "readDids:account"]},
  },
  {
    path: "/ringotel-orgs",
    title: "Ringotel Organizations",
    component: RingotelOrgPage,
    permissions: {type:"sms",permissions:["readDids:any"]},
  },
  {
    path: "/ringotel-users",
    title: "Ringotel Users",
    component: RingotelUsersPage,
    permissions: {type:"users",permissions:["read:any"]},
  },
  {
    path: "*",
    title: "Page Not Found",
    component: PageNotFound,
  }
];




const routes = [...coreRoutes];
export { routes };
