import React from "react";
import RingotelUserForm from "../Form/RingotelUser";
import { RingotelUser } from "../../hooks/apis/ringotelUsers.api";
import ModalWrapper from "../generic/modal";
const EditRingtelUserModal = ({
  setIsModalOpen,
  isModalOpen,
  editData,
  account_id
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  editData: RingotelUser | null;
  account_id: string
}) => {
  return (
    <ModalWrapper
      title="Edit Ringotel User"
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <RingotelUserForm account_id={account_id} editData={editData} setIsModal={setIsModalOpen} />
    </ModalWrapper>
  );
};

export default EditRingtelUserModal;
