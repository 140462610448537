import { api } from "@data-phone/react-resource-server-auth"
import { useMutation, useQuery } from "react-query"
import { useDispatch } from "react-redux"
import { useMessageToast } from "../../components/Layout/DefaultLayout"
import { AppDispatch } from "../../store/store"
import { addRingtelUser, deleteRingtelUser, setRingotelUsers, updateRingtelUser } from "../../store/slices/ringotelUsers.slice"

export interface RingotelUser {
    orgid: string
    account_id: string
    user_id: string
    user_ext: string
}

export const getRingotelUsersByAccountApi = async (account_id: string) => {
    const response = await api.get(`${process.env.REACT_APP_SMS_API_URL}/ringotel/users/${account_id}`)
    return response
}

export const updateRingotelUserApi = async (data: {
    user_id: string,
    data: RingotelUser
}) => {
    await api.patch(`${process.env.REACT_APP_SMS_API_URL}/ringotel/user/${data.user_id}`, data.data)
    return {
        user_id: data.user_id,
        data: data.data
    }
}

export const addRingotelUserApi = async (data: RingotelUser) => {
    const response = await api.post(`${process.env.REACT_APP_SMS_API_URL}/ringotel/user`, data)
    return response
}

export const deleteRingotelUserApi = async (user_id: string) => {
    await api.delete(`${process.env.REACT_APP_SMS_API_URL}/ringotel/user/${user_id}`)
    return user_id
}

export const useGetRingotelUsersByAccount = (account_id: string) => {
    const dispatch:AppDispatch = useDispatch()
    return useQuery("ringotelUsers", () => getRingotelUsersByAccountApi(account_id), {
        enabled: false,
        onSuccess(data) {
            dispatch(setRingotelUsers(data.data))
        },
    })
}


export const useAddRingotelUser = () => {
    const dispatch: AppDispatch = useDispatch()
    const { messageApi } = useMessageToast()
    return useMutation(addRingotelUserApi, {
        onSuccess: (data) => {
            messageApi.success("User added successfully")
            dispatch(addRingtelUser(data.data))
        }
    })
}


export const useUpdateRingotelUser = () => {
    const dispatch: AppDispatch = useDispatch()
    const { messageApi } = useMessageToast()
    return useMutation(updateRingotelUserApi, {
        onSuccess: (data) => {
            messageApi.success("User updated successfully")
            dispatch(updateRingtelUser(data))
        }
    })
}


export const useDeleteRingotelUser = () => {
    const dispatch: AppDispatch = useDispatch()
    const { messageApi } = useMessageToast()
    return useMutation(deleteRingotelUserApi, {
        onSuccess: (data) => {
            messageApi.success("User deleted successfully")
            dispatch(deleteRingtelUser(data))
        }
    })
}