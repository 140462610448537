import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RingotelOrg } from "../../hooks/apis/ringotel.api";

export interface RingotelOrgState {
    data: RingotelOrg[];
}

const initialState: RingotelOrgState = {
    data: [],
};

export const ringotelOrgSlice = createSlice({
    name: "ringotelOrg",
    initialState,
    reducers: {
        setRingotelOrganizations: (state, action: PayloadAction<RingotelOrg[]>) => {
            state.data = action.payload;
        },
        updateRingtelOrganization: (state, action: PayloadAction<{
            orgid: string
            data: RingotelOrg
        }>) => {
            state.data = state.data.map((org) => {
                if (org.orgid === action.payload.orgid) {
                    return action.payload.data;
                }
                return org;
            });
        },
        addRingtelOrganization: (state, action: PayloadAction<RingotelOrg>) => {
            state.data = [...state.data, action.payload];
        },
        deleteRingtelOrganization: (state, action: PayloadAction<string>) => {
            state.data = state.data.filter((org) => org.orgid !== action.payload);
        },
    },
});


export const { setRingotelOrganizations, updateRingtelOrganization, addRingtelOrganization, deleteRingtelOrganization } = ringotelOrgSlice.actions;

export default ringotelOrgSlice.reducer;