import { Avatar, Flex, Tag, Typography } from "antd"
import { SearchedConversation } from "../../../hooks/apis/conversations.api"
import { generateNameSpaceColor } from "../../../utils/color";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { formatPhoneNumber } from "../../../utils/phoneLib";
import { formatMessageDate } from "../../../utils/messageDateFormat";
import Highlighter from "react-highlight-words";
import { setSelectedConversation } from "../../../store/slices/conversation.slice";



const ItemContentStyle: React.CSSProperties = {
    padding: "5px",
    borderRadius: "10px",
    cursor: "pointer",
};

const avatarStyle: React.CSSProperties = {
    verticalAlign: "middle",
    color: "black",
    fontWeight: "bold",
};

const ConversationContentStyle: React.CSSProperties = { width: "50%" };

const conversationDateStyle: React.CSSProperties = {
    lineHeight: "20px",
    color: "rgb(119, 117, 135)",
    fontSize: "14px",
    minWidth: "85px",
}

const conversationMessageStyle: React.CSSProperties = {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "rgb(119, 117, 135)",
};

export const SearchConversationCard = ({
    conversation,
    searchInput,
    searchType,
    setIsModal
}: {
    conversation: SearchedConversation
    searchInput: string
    searchType: string
    setIsModal: Function
}) => {
    const dispatch: AppDispatch = useDispatch()

    const { data: contacts } = useSelector((state: RootState) => state.contacts);

    const contactData = contacts.find((contact) => contact.phones.some((phone) => {
        return phone.phone_number === conversation.participant
    })) || null

    const color = generateNameSpaceColor(conversation.participant);

    const handleClick = () => {
        dispatch(setSelectedConversation({
            did: conversation.did,
            participant: conversation.participant,
            position: Number(conversation.position),
            search: searchInput
        }))
        setIsModal(false)
    }

    return <Flex
        onClick={() => {
            handleClick()
        }}
        align="flex-start"
        justify="space-evenly"
        style={{
            ...ItemContentStyle,
        }}
        wrap="wrap"
        gap="small"
    >
        <Avatar style={{ ...avatarStyle, background: color }} size="large">
            {conversation.participant[conversation.participant.length - 1]
                ? conversation.participant[conversation.participant.length - 1]
                : 0}
        </Avatar>
        <Flex gap={"small"} style={ConversationContentStyle} vertical={true}>
            {
                searchType === "contact" ?
                    <Highlighter
                        searchWords={[searchInput]}
                        autoEscape={true}
                        textToHighlight={contactData ? `${contactData?.first_name} ${contactData?.middle_name} ${contactData?.last_name}` : formatPhoneNumber(conversation.participant) as string}
                    /> : <Typography.Text style={conversationMessageStyle}>
                        {contactData ? `${contactData.first_name || ""} ${contactData.middle_name || ""} ${contactData.last_name || ""} ` : formatPhoneNumber(conversation.participant) as string}
                    </Typography.Text>
            }

            {
                searchType === "conversation" && <Highlighter
                    searchWords={[searchInput]}
                    autoEscape={true}
                    textToHighlight={conversation.body}
                />
            }

        </Flex>
        <Flex align="center" vertical={true}>
            <Typography.Text style={conversationDateStyle}>{formatMessageDate(new Date(conversation.timestamp))}</Typography.Text>
        </Flex>
    </Flex>
}