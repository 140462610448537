
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../generic/modal";
import { ContactList } from "./contacts.list";
import { AppDispatch, RootState } from "../../store/store";
import { ContactData, updateContact } from "../../store/slices/contact.slice";
import { useUpdateContact } from "../../hooks/apis/contacts.api";
import { useMessageToast } from "../Layout/DefaultLayout";

const ExistingContactModal = ({
  setIsModalOpen,
  isModalOpen,
}: {
  setIsModalOpen: any;
  isModalOpen: boolean;
}) => {
  const { selectedConversation } = useSelector((state: RootState) => state.conversation);
  const { messageApi } = useMessageToast();
  const { mutate: updateContactMutate } = useUpdateContact();
  const dispatch: AppDispatch = useDispatch();
  const handleUpdateExistingContact = (contact: ContactData) => {

    if (selectedConversation) {
      if (contact.phones.some((phone) => phone.phone_number === selectedConversation.participant)) {
        return messageApi.error("Contact already exists")
      }
      messageApi.open({
        type: "loading",
        content: "Updating Contact",
        duration: 1,
      })
      updateContactMutate({
        id: contact.id,
        phones: [{ phone_number: selectedConversation.participant }, ...contact.phones.map((phone) => {
          return { phone_number: phone.phone_number }
        })],
        first_name: contact.first_name,
        last_name: contact.last_name,
        middle_name: contact.middle_name,
        emails: contact.emails.map((email) => {
          return { email: email.email }
        }),
        addresses: contact.addresses.map((address) => {
          return {
            address1: address.address1,
            address2: address.address2,
            city: address.city,
            ZIP: address.ZIP,
          };
        }),
        provider_id: contact.provider_id,
        is_Private: contact.is_private,
      },{onSuccess: (data) => {
          messageApi.destroy()
          messageApi.open({
          type:"success",
          content:"Updated Contact",
          duration:1
          })
          dispatch(updateContact(data))
          setIsModalOpen(false)
      },onError: (error) => {
          messageApi.destroy()
          messageApi.open({
          type:"error",
          content:"Failed to update contact",
          duration:1
          })
        }
      })
    }
  }
  return (
    <ModalWrapper
      title="Add To Existing Contact"
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <ContactList isModal={true} handleSubmit={(value) => {
      }} handleUpdateContact={(value) => {
        handleUpdateExistingContact(value)
      }} onError={() => { }} searchInput="" />
    </ModalWrapper>
  );
};

export default ExistingContactModal;
