import { Avatar, Badge, Flex, List, Tooltip, Typography } from "antd";
import { BaseUserDid } from "../../../interfaces/userDids.type";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { setSelectedDid } from "../../../store/slices/userDids.slice";
import { formatPhoneNumber } from "../../../utils/phoneLib";
import phoneImg from "../../../assets/images/icon/Phone-call.svg";
import { HeadingText } from "../../generic/Typography";
import {
  setSelectedConversation,
} from "../../../store/slices/conversation.slice";

const avatarStyle: React.CSSProperties = {
  height: "1.2rem",
  width: "1.2rem",
  marginTop: "2px",
};

const didCardStyle: React.CSSProperties = {
  width: "100%",
  borderRadius: 6,
};

const cardStyle = {
  border: "none",
  padding: "10px",
  borderRadius: "6px",
  cursor: "pointer",
};


const didlabelStyle: React.CSSProperties = {
  color: "rgb(119, 117, 135)",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100px"
}

const UserDidItem = ({
  userDid,
  isSelected,
  setIsNewConversation,
  showCounter
}: {
  userDid: BaseUserDid;
  isSelected: boolean;
  setIsNewConversation?: Function;
  showCounter: boolean
}) => {
  const dispatch: AppDispatch = useDispatch();

  const onClickHandler = (userDid: string) => {
    dispatch(setSelectedDid(userDid));
    dispatch(setSelectedConversation(null));
    if (setIsNewConversation) {
      setIsNewConversation(false);
    }
  };


  return (
    <List.Item
      style={{
        ...cardStyle,
        background: isSelected ? "#eff4fb" : "#fff",
      }}
    >
      <Flex justify="space-between" style={didCardStyle}  >
        <Flex justify="space-between"
          style={didCardStyle}
          onClick={() => {
            onClickHandler(userDid.did);
          }}
        >
          <Flex style={{
              minHeight: "40px",
              alignItems: "center"
          }} gap={10}>
            <Avatar style={avatarStyle} src={phoneImg} />
            <Flex vertical={true}>
                <HeadingText
                  text={formatPhoneNumber(userDid.did) as string}
                ></HeadingText>
                <Tooltip title={userDid.label}><Typography.Text style={didlabelStyle}>{userDid.label}</Typography.Text></Tooltip>
              </Flex>
          </Flex>
          <div style={{ width: "50px" }}>
            {userDid.unread_messages > 0 && showCounter ? (
              <Badge
                count={
                  `${userDid.unread_messages}`.padStart(2, "0")
                }
                showZero
                color="#653BFA"
              />
            ) : userDid.unread_conversations > 0 && userDid.unread_messages === 0 && (
              <Badge
                text=" "
                showZero={false}
                color="#653BFA"
              />
            )}
          </div>
        </Flex>
      </Flex>
    </List.Item>
  );
};

export default UserDidItem;
