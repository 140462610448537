import { useSelector } from "react-redux"
import { RootState } from "../../store/store"

export const usePermissions = () => {
    const userPermissions = useSelector((state: RootState) => state.user.data?.permissions)
    function hasPermission(moduleName: string,permission: string | string[]) {
        const permissions = Array.isArray(permission) ? permission : [permission];
        if (!userPermissions[moduleName] || !Array.isArray(userPermissions[moduleName]) || userPermissions[moduleName].length === 0) return false;
        return permissions.some(permission => userPermissions[moduleName].includes(permission));
    }
    return { hasPermission }
}

