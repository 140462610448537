import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import UserDids from "../components/Messages/userDids/Container.did";
import ConversationContainer from "../components/Messages/conversations/Conversations.container";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import ChatContainer from "../components/Messages/chat/chat.container";
import { useMessageSocket } from "../hooks/socket/message";
import { useFfmpegLoader } from "../hooks/ffmpeg/useLoadffmpeg";
import { useGetUserDids } from "../hooks/apis/userDids";
import { useGetUsersByDid } from "../hooks/apis/user.api";
import { usePermissions } from "../hooks/permissions";
import { useGetAccounts } from "../hooks/apis/accounts.api";
import { setSelectedAccount } from "../store/slices/admin.slice";
import { setSelectedConversation } from "../store/slices/conversation.slice";
import { setSelectedDid } from "../store/slices/userDids.slice";
import { useGetContacts } from "../hooks/apis/contacts.api";
import useNotification from "../hooks/firebase/notifications";
import { clearPast24HoursOfflineMessages } from "../store/slices/offline_messages.slice";


const MessagesPage = React.memo(() => {
  const dispatch: AppDispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const [isNewConversation, setIsNewConversation] = useState<boolean>(false);

  const { hasPermission } = usePermissions()

  const { data: userData } = useSelector((state: RootState) => state.user);
  const { selectedAccount, isAdminView } = useSelector((state: RootState) => state.admin);
  const { selectedDid } = useSelector((state: RootState) => state.userDids);

  const { sendOutgoingMessage, updateConversation, deleteMessage, isSocketConnected } = useMessageSocket();
  const {
    load: loadffmpeg,
    loaded: ffmpegLoaded,
    ffmpegRef,
  } = useFfmpegLoader();


  const { refetch, isFetching: isLoading } = useGetUserDids(hasPermission("sms", ["readUserDids:any", "readUserDids:account"]) && isAdminView && selectedAccount ? selectedAccount : null);
  const { refetch: fetchUsersByDid } = useGetUsersByDid(selectedDid);
  const { refetch: fetchAccounts } = useGetAccounts();
  const { refetch: fetchContacts } = useGetContacts();

  useNotification()


  useEffect(() => {
    if (selectedDid) {
      fetchUsersByDid()
    }
  }, [selectedDid])


  useEffect(() => {
    fetchContacts()
    if (hasPermission("accounts", "read:any")) {
      fetchAccounts()
    }
  }, []);

  useEffect(() => {
    if ((isAdminView && selectedAccount == "") || !isAdminView) {
      dispatch(setSelectedAccount(userData?.accountID as string))
    }
    refetch();
    dispatch(setSelectedConversation(null));
    dispatch(setSelectedDid(null));
  }, [isAdminView, selectedAccount]);

  useEffect(()=>{
    dispatch(clearPast24HoursOfflineMessages())
  },[])

  useEffect(() => {
    loadffmpeg();
  }, []);

  const rowStyle: React.CSSProperties = {
    height: "100%",
  };

  const ConversationContainerStyle: React.CSSProperties = {
    height: "100%",
    background: "white",
  };

  const ChatContainerStyle: React.CSSProperties = {
    height: "100%",
    maxWidth: "100%",
  };

  return (
    <Row style={rowStyle}>
      <Col
        style={{
          transition: "width 0.3s ease-in-out 0.1s",
          width: open ? "20.833333333333336%" : "8%",
        }}
      >
        <UserDids isLoading={isLoading} showCounter={true} setSidebarOpen={setOpen} sidebarOpen={open} setIsNewConversation={setIsNewConversation} />
      </Col>
      <Col style={ConversationContainerStyle} span={6}>
        <ConversationContainer
          updateConversation={updateConversation}
          setIsNewConversation={setIsNewConversation}
        />
      </Col>
      <Col style={ChatContainerStyle} flex={"auto"}>
        <ChatContainer
          deleteMessage={deleteMessage}
          updateConversation={updateConversation}
          ffmpegLoaded={ffmpegLoaded}
          ffmpegRef={ffmpegRef}
          sendMessage={sendOutgoingMessage}
          isNewConversation={isNewConversation}
          setIsNewConversation={setIsNewConversation}
          isSocketConnected={isSocketConnected}
        />
      </Col>
    </Row>
  );
});

export default MessagesPage;
