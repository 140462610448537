import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Account } from "../../hooks/apis/accounts.api";
import { Did } from "../../hooks/apis/dids.api";
import { AccountUserDid } from "../../hooks/apis/userDids";
import { AccountUser } from "../../hooks/apis/user.api";
import { Carrier } from "../../hooks/apis/carriers.api";

export interface adminState {
    accounts: Account[];
    dids: Did[]
    userDids: AccountUserDid[]
    users: AccountUser[]
    selectedView: string
    carriers: Carrier[]
    selectedAccount: string 
    isAdminView: boolean
}



export const initialState: adminState = {
    accounts: [],
    dids: [],
    userDids: [],
    users: [],
    selectedView: "info",
    carriers: [],
    selectedAccount: "",
    isAdminView: false
}

export const admin = createSlice({
    name: "admin",
    initialState,
    reducers: {
        setAccounts: (state, action: PayloadAction<Account[]>) => {
            state.accounts = action.payload
        }
        ,
        setDids: (state, action: PayloadAction<Did[]>) => {
            state.dids = [...state.dids, ...action.payload.filter((did) => state.dids.some((did2) => did.did == did2.did) == false)]
        },
        addDid: (state, action: PayloadAction<Did>) => {
            state.dids = [...state.dids, action.payload]
        },
        deleteDid: (state, action: PayloadAction<string>) => {
            state.dids = state.dids.filter((did) => did.did != action.payload)
        },
        updateDid: (state, action: PayloadAction<{
            did: string,
            data: Did
        }>) => {
            state.dids = state.dids.map((did) => did.did == action.payload.did ? action.payload.data : did)
        }
        ,
        setUserDidsByAccount: (state, action: PayloadAction<AccountUserDid[]>) => {
            state.userDids = [...state.userDids, ...action.payload.filter((userDid) => state.userDids.some((stateUserDid) => userDid.did == stateUserDid.did && userDid.user_id == stateUserDid.user_id) == false)]
        },
        addUserDid: (state, action: PayloadAction<AccountUserDid>) => {
            state.userDids = [...state.userDids, action.payload]
        },
        deleteUserDid: (state, action: PayloadAction<{
            did: string,
            user_id: string
        }>) => {
            state.userDids = state.userDids.filter((userDid) => userDid.did != action.payload.did || userDid.user_id != action.payload.user_id)
        },
        updateUserDid: (state, action: PayloadAction<AccountUserDid>) => {
            state.userDids = state.userDids.map((userDid) => userDid.did == action.payload.did && userDid.user_id == action.payload.user_id ? action.payload : userDid)
        },
        setAccountUsers: (state, action: PayloadAction<AccountUser[]>) => {
            state.users = action.payload
        },

        setSelectedView: (state, action: PayloadAction<string>) => {
            state.selectedView = action.payload
        },

        // Carriers
        setCarriers: (state, action: PayloadAction<Carrier[]>) => {
            state.carriers = action.payload
        },
        setSelectedAccount: (state, action: PayloadAction<string>) => {
            state.selectedAccount = action.payload
        },
        setAdminView: (state, action: PayloadAction<boolean>) => {
            state.isAdminView = action.payload
        }


    }
})

export const { setAccounts, setDids, setUserDidsByAccount, setAccountUsers, addUserDid, updateUserDid, deleteUserDid, addDid, deleteDid, updateDid, setSelectedView, setCarriers,setSelectedAccount, setAdminView  } = admin.actions

export default admin.reducer