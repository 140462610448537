import { useQuery } from "react-query";
import  { AxiosResponse } from "axios";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import { setUsers, user } from "../../store/slices/didUsers.slice";
import { setAccountUsers } from "../../store/slices/admin.slice";
import { api } from "@data-phone/react-resource-server-auth";

const API_ENDPOINT = `${process.env.REACT_APP_AUTH_API}/auth/session-data`;

interface responseType {
  data: {
    id: string;
    accountID: string;
    email: string;
    firstName: string;
    lastName: string;
  };
  code: string | null;
  message: string;
}


export interface AccountUser {
  id: string;
  account_id: string;
  email: string;
  first_name: string;
  last_name: string;
  created_at: string;
  updated_at: string;
  disabled_at: string | null;
  confirmed_at: string | null;
}

const fetchUsers = async (
  did: string,
  dispatch: AppDispatch
): Promise<user[]> => {
  const response: AxiosResponse<user[]> = await 
  api.get(
    `${process.env.REACT_APP_SMS_API_URL}/users/did/${did}`,
  );
  if (response.data) {
    dispatch(setUsers(response.data));
  }
  return response.data;
};


const fetchAllUsers = async (
) => {
  const response: AxiosResponse<AccountUser[]> = await api.get(
    `${process.env.REACT_APP_AUTH_API}/api/users`,
  );
  return response.data;
}


export const useGetAllUsers = () => {
  const dispatch: AppDispatch = useDispatch();
  return useQuery("usersByAccount", () => fetchAllUsers(), {
    onError: (error) => {
      console.log(error);
    },
    onSuccess(data) {
      dispatch(setAccountUsers(data))
    },
  });
}




export const useGetUsersByDid = (did: string | null) => {
  const dispatch: AppDispatch = useDispatch();
  return useQuery<any, Error>(
    "usersByDid",
    () => fetchUsers(did as string, dispatch),
    {
      enabled: false,
      onError: (error) => {
        console.log(error);
      },
    }
  );
};
