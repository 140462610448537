import { Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import DefaultLayout from "./components/Layout/DefaultLayout";
import { routes } from "./routes";
import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Spin } from "antd";
import "./App.css";
import Loader from "./components/generic/Loader";
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { useAuthModule } from "@data-phone/react-resource-server-auth"
import { setUser } from "./store/slices/user.slice";
import { usePermissions } from "./hooks/permissions";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function App() {
  const dispatch: Dispatch = useDispatch()
  const { logout, data } = useAuthModule()
  const [isLoading, setIsLoading] = useState(true);
  const { hasPermission } = usePermissions();

  useEffect(() => {
    Notification.requestPermission()
  }, [])

  useEffect(() => {
    if (data.sessionData) {
      dispatch(setUser(data.sessionData))
      setIsLoading(false);
    }
  }, [data])


  if (isLoading) {
    return (
      <Flex style={{ height: "100vh" }}>
        {" "}
        <Loader />
      </Flex>
    );
  }

  return (
    <Routes>
      <Route element={<DefaultLayout logout={logout} />}>
        {routes.filter((route) => !route.permissions || hasPermission(route.permissions.type,route.permissions.permissions)).map((routes, index) => {
          const { path, component: Component } = routes;
          return (
            <Route
              key={index}
              path={path}
              element={
                <Suspense fallback={<Spin indicator={antIcon} />}>
                  <Component />
                </Suspense>
              }
            />
          );
        })}
      </Route>
    </Routes>
  );
}

export default App;
